import axios from "axios";
import { decode } from "base-64";
import ptbr from 'date-fns/locale/pt-BR';
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import 'react-data-table-component-extensions/dist/index.css';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';

registerLocale('pt-BR', ptbr)

function ArquivosEnvioExterno() {

  const Navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const objetoUrl = JSON.parse(decode(urlParams.get('link')));

  const [loading, setLoading] = useState(true)
  const [msgLoading, setMsgLoading] = useState('Aguarde...')

  const { conexao } = useContext(GlobalContext)

  const [dados, setDados] = useState({})
  const [categorias, setCategorias] = useState([])
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [categoriasDispensadas, setCategoriasDispensadas] = useState([])

  const fileInputRef = React.createRef();

  const handleCategoriaSelection = (categoria, valor) => {
    setSelectedCategoria({
      valor: valor,
      categoria: categoria
    });
  };

  const handleFileSelection = (event) => {

    const files = Array.from(event.target.files);

    const filesWithDetails = files.map((file) => ({
      categoria: selectedCategoria.categoria,
      valor_categoria: selectedCategoria.valor,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      data: file,
      description: file.name.split('.').slice(0, -1).join('.')
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...filesWithDetails]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  async function GetDados(mes, ano) {


    axios.post(conexao.endereco + '/drive/sem_arquivos/link/detalhar',
      objetoUrl, { headers: { token: localStorage.getItem('Token') } })
      .then(function (response) {
        setDados(response.data.dados)

        let categoriasCobranca = response.data.dados.arqc_categorias ? JSON.parse(response.data.dados.arqc_categorias) : []
        let categoriasJaEnviadas = response.data.enviados
        let categoriasDispensadas = response.data.dispensados
        let categoriasParaSetar = categoriasCobranca.filter(categoria => !categoriasJaEnviadas.includes(categoria.categ_valor) && !categoriasDispensadas.includes(categoria.categ_valor))



        setCategorias(categoriasParaSetar)
        setLoading(false)
      })
      .catch(function (error) {
        console.error(error)
        setLoading(false)
      })
  }

  // async function EnviarArquivo(values, url) {

  //   const objeto = {
  //     empresa: dados.empr_questor_empresa,
  //     estab: dados.empr_questor_estabelecimento,
  //     usuario: objetoUrl.usuario_id,
  //     mes: dados.arqc_competencia.split('/')[0],
  //     ano: dados.arqc_competencia.split('/')[1],
  //     categoria: values.valor_categoria,
  //     url: url,
  //     descricao: values.description,
  //     obs: 'Enviado via Link no E-mail',
  //     cobranca: objetoUrl.id
  //   }

  //   axios.post(conexao.endereco + '/drive/incluir',
  //     objeto, { headers: { token: localStorage.getItem('Token') } })

  //     .then(function (response) {
  //       GetDados()
  //       setLoading(false)
  //     })
  //     .catch(function (error) {
  //       console.error(error)
  //       setLoading(false)
  //     })
  // }
  // async function DispensarEnvio(categoria) {

  //   const objeto = {
  //     empresa: dados.empr_questor_empresa,
  //     estab: dados.empr_questor_estabelecimento,
  //     usuario: objetoUrl.usuario_id,
  //     categoria: categoria,
  //     competencia: dados.arqc_competencia
  //   }

  //   axios.post(conexao.endereco + '/drive/sem_arquivos/notificar',
  //     objeto, { headers: { token: localStorage.getItem('Token') } })

  //     .then(function (response) {
  //       setReciboDispensas((prevFiles) => prevFiles.filter((categ) => categ !== categoria));
  //       setReciboDispensas([...reciboDispensas, categoria])
  //       GetDados()
  //       setLoading(false)
  //     })
  //     .catch(function (error) {
  //       console.error(error)
  //       setLoading(false)
  //     })

  // }

  // async function UploadArquivo(values) {

  //   setLoading(true)

  //   const formData = new FormData()
  //   formData.append('arquivo', values.data)

  //   axios.post(conexao.endereco + '/arquivos/enviar',
  //     formData, { headers: { token: localStorage.getItem('Token') } })

  //     .then(async function (response) {
  //       await EnviarArquivo(values, response.data.url)
  //       setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== values));
  //       setReciboArquivos([...reciboArquivos, values.valor_categoria])
  //       setLoading(false)
  //     })
  //     .catch(function (error) {
  //       console.error(error)
  //       setLoading(false)
  //     })

  // }


  async function processarEnvios(selectedFiles, categoriasDispensadas) {

    setLoading(true);

    const reciboArquivos = [];
    const reciboDispensas = [];

    const uploadArquivo = async (values) => {
      setLoading(true);

      const formData = new FormData();
      formData.append('arquivo', values.data);

      try {
        const response = await axios.post(
          `${conexao.endereco}/arquivos/enviar`,
          formData,
          { headers: { token: localStorage.getItem('Token') } }
        );

        const objeto = {
          empresa: dados.empr_questor_empresa,
          estab: dados.empr_questor_estabelecimento,
          usuario: objetoUrl.usuario_id,
          mes: dados.arqc_competencia.split('/')[0],
          ano: dados.arqc_competencia.split('/')[1],
          categoria: values.valor_categoria,
          url: response.data.url,
          descricao: values.description,
          obs: 'Enviado via Link no E-mail',
          cobranca: objetoUrl.id,
        };

        await axios.post(`${conexao.endereco}/drive/incluir`, objeto, {
          headers: { token: localStorage.getItem('Token') },
        });

        reciboArquivos.push(objeto);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const dispensarEnvio = async (categoria) => {

      setLoading(true);

      const objeto = {
        empresa: dados.empr_questor_empresa,
        estab: dados.empr_questor_estabelecimento,
        usuario: objetoUrl.usuario_id,
        categoria: categoria,
        competencia: dados.arqc_competencia,
      };

      try {
        await axios.post(`${conexao.endereco}/drive/sem_arquivos/notificar`, objeto, {
          headers: { token: localStorage.getItem('Token') },
        });

        reciboDispensas.push(categoria);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const gerarRecibo = async () => {

      setLoading(true);

      const objeto = {
        empresa: dados.empr_questor_empresa,
        estab: dados.empr_questor_estabelecimento,
        competencias: dados.arqc_competencia,
        usuario: objetoUrl.usuario_id,
        cobranca: objetoUrl.id,
        arquivos: reciboArquivos,
        dispensas: reciboDispensas,
      };

      try {
        await axios.post(`${conexao.endereco}/drive/sem_arquivos/recibos/incluir`, objeto, {
          headers: { token: localStorage.getItem('Token') },
        });

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        GetDados()
      }
    };

    for (let i = 0; i < selectedFiles.length; i++) {
      await uploadArquivo(selectedFiles[i]);
    }

    for (let j = 0; j < categoriasDispensadas.length; j++) {
      await dispensarEnvio(categoriasDispensadas[j]);
    }

    await gerarRecibo();

    setLoading(false);
  }



  useEffect(() => {
    GetDados()
  }, [])

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '110vh',
      paddingTop: 20,
      alignItems: 'center',
    }}>

      <div style={{
        width: '700px',
        backgroundColor: 'white',
        padding: 30,
        borderStartStartRadius: 10,
        borderStartEndRadius: 10,
        boxShadow: '0px 10px 40px -12px #00000056',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 200
      }}>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img
            src={LogoArquivo}
            width={'40%'}
            alt={'Logomarca Arquivo Contabilidade'}
            loading={"lazy"}
          />
        </div>
        {categorias.length > 0 &&
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 40
          }}>
            <h5 style={{ textAlign: 'center' }}>{dados.empr_nome}</h5>
            <p style={{ textAlign: 'center', marginTop: 10 }}>Olá <strong>{objetoUrl.usuario_nome.split(' ')[0]}</strong>, Clique na categoria abaixo para selecionar os arquivos:</p>
            <div style={{
              marginTop: 20,
            }}>
              {categorias.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      // height: '100px',
                      borderRadius: 5,
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderColor: 'gray',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'start',
                      flexDirection: 'column',
                      marginBottom: 10,
                      transition: '0.3s',
                    }}
                    onMouseEnter={(e) => {
                      // e.currentTarget.style.backgroundColor = '#f4f4f4';
                      e.currentTarget.style.boxShadow = '0px 10px 40px -12px #00000056'
                    }}
                    onMouseLeave={(e) => {
                      // e.currentTarget.style.backgroundColor = '';
                      e.currentTarget.style.boxShadow = 'none'
                    }}
                  >
                    <div style={{
                      width: '100%',
                      // height: '100px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <div style={{
                        padding: 23,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'start',
                        paddingLeft: 20,
                        width: '60%',
                        height: '100%',
                        backgroundColor: categoriasDispensadas.includes(item.categ_valor) ? 'pink' : 'white',
                      }}>
                        <span style={{ fontWeight: 'bold', textDecoration: categoriasDispensadas.includes(item.categ_valor) ? 'line-through' : 'none' }}>
                          {item.categoria}
                        </span>
                        <span style={{ textDecoration: categoriasDispensadas.includes(item.categ_valor) ? 'line-through' : 'none' }}>
                          {dados.arqc_competencia}
                        </span>
                      </div>
                      <div style={{
                        padding: 10,
                        gap: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40%',
                        height: '100%',
                        backgroundColor: categoriasDispensadas.includes(item.categ_valor) ? 'pink' : 'white',
                      }}>
                        <Button
                          disabled={categoriasDispensadas.includes(item.categ_valor)}
                          style={{
                            width: '90%',
                            backgroundColor: '#131d51',
                            border: 'none',
                            borderRadius: 5,
                            color: 'white',
                            padding: 4,
                            transition: '0.3s',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.cursor = 'pointer';
                            e.currentTarget.style.fontWeight = 'bold';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.cursor = 'default';
                            e.currentTarget.style.fontWeight = 'normal';
                          }}
                          onClick={() => {
                            handleCategoriaSelection(item.categoria, item.categ_valor);
                            fileInputRef.current.click();
                          }}
                        >
                          Selecionar Arquivos
                        </Button>
                        <Button
                          disabled={selectedFiles.filter((file) => file.categoria === item.categoria).length > 0}
                          style={{
                            width: '90%',
                            backgroundColor: '#d12f26',
                            border: 'none',
                            borderRadius: 5,
                            color: 'white',
                            padding: 4,
                            transition: '0.3s',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.cursor = 'pointer';
                            e.currentTarget.style.fontWeight = 'bold';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.cursor = 'default';
                            e.currentTarget.style.fontWeight = 'normal';
                          }}
                          onClick={() => {
                            if (categoriasDispensadas.includes(item.categ_valor)) {
                              setCategoriasDispensadas((prevCategorias) => prevCategorias.filter((categoria) => categoria !== item.categ_valor));
                            } else {
                              setCategoriasDispensadas((prevCategorias) => [...prevCategorias, item.categ_valor]);
                            }
                          }}

                        >
                          {categoriasDispensadas.includes(item.categ_valor) ? 'Desfazer' : 'Não Possuo Arquivos'}
                        </Button>
                      </div>

                    </div>

                    {/* Exibição dos arquivos adicionados para a categoria selecionada */}
                    {selectedFiles.filter((file) => file.categoria === item.categoria).length > 0 && (
                      <div style={{ marginTop: 10, width: '100%', paddingLeft: 40, paddingBottom: 20 }}>
                        <ul>
                          {selectedFiles
                            .filter((file) => file.categoria === item.categoria)
                            .map((file, index) => (
                              <li key={index}>
                                {file.name}{' '}
                                <span
                                  style={{ cursor: 'pointer', marginLeft: 5, color: 'red' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFile(file)
                                  }}
                                >
                                  Remover
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
              <input
                ref={fileInputRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileSelection}
              />

              <Button
                style={{
                  width: '100%',
                  marginTop: 20,
                  backgroundColor: '#131d51',
                  border: 'none',
                  borderRadius: 5,
                  color: 'white',
                  padding: 10,
                  fontWeight: 'bold',
                  transition: '0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.cursor = 'pointer';
                  e.currentTarget.style.padding = '15px';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.cursor = 'default';
                  e.currentTarget.style.padding = '10px'
                }}
                onClick={() => processarEnvios(selectedFiles, categoriasDispensadas)}
              >
                Enviar
              </Button>
            </div>
          </div>
        }
        {categorias.length == 0 &&
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 40
          }}>
            <h5 style={{ textAlign: 'left' }}>{dados.empr_nome}</h5>
            <p style={{ textAlign: 'left', marginTop: 40 }}>Olá <strong>{objetoUrl.usuario_nome.split(' ')[0]}</strong><br /> Os arquivos solicitados foram enviados com sucesso!</p>
          </div>
        }
      </div>

      <Modal show={loading} centered>
        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
          <img
            src={LogoArquivo}
            width={'20%'}
            alt={'Logomarca Arquivo Contabilidade'}
            loading={"lazy"}
          ></img>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {msgLoading}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ArquivosEnvioExterno;
