import axios from 'axios';
import dayjs from "dayjs";
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form as FormReact, Modal, Row, Stack } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import {
    ErroField,
    InputFieldMoeda,
    InputFieldPadrao,
    InputFieldPercentual,
    InputFieldTextArea,
    InputHora,
    SelectFieldPadrao
} from '../../components/field';
import Footer from '../../components/footer';
import { ModalFuncionarios } from '../../components/modal';
import Navegacao from '../../components/navbar';
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';

var customParseFormat = require('dayjs/plugin/customParseFormat')

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Rescisao() {

    const formRef = useRef()
    const Navigate = useNavigate()

    const [modalConcluidoAberto, setModalConcluidoAberto] = useState(false)
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const [dataAtual, setDataAtual] = useState()
    const [modalFuncionarios, setModalFuncionarios] = useState(false)

    const [listaAnexos, setListaAnexos] = useState([])

    const urlParams = new URLSearchParams(window.location.search)

    const empresa = urlParams.get("empresa")
    const estab = urlParams.get("estab")
    const func = urlParams.get("func")
    const id_rescisao = urlParams.get("id")

    const [edicao, setEdicao] = useState(false)

    const [progresso] = useState(0)

    var customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(utc)


    function transformarNomeArquivo(nomeArquivo) {
        return nomeArquivo.replace(/[^\w\s\.]/gi, '').replace(/\s+/g, '_').toLowerCase();
    }


    const { conexao, tipoAcesso } = useContext(GlobalContext)

    useEffect(() => {

        if ((empresa && estab && func) || id_rescisao) {
            setEdicao(true)
            getRescisao()
        }

        GetDataAtual()
    }, [])


    const GetDataAtual = () => {
        const now = dayjs.utc().format("YYYY-MM-DD")
        setDataAtual(now)
    }


    async function UploadArquivo(values) {

        setMsgLoading(`Enviando Arquivo ${values.name}`)
        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values)

        await axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)

                let obj = {
                    nome: transformarNomeArquivo(values.name),
                    url: response.data.url
                }

                listaAnexos.push(obj)
                console.log(response.data.url)
                return response.data.url

            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
                return null
            })
    }

    const enviarSolicitacao = async (values) => {

        setLoading(true)

        const id_empresa = tipoAcesso === "USUARIO" ? localStorage.getItem('id_empresa') : "0"
        const id_operador = tipoAcesso !== "USUARIO" ? localStorage.getItem('id_usuario') : "0"
        const id_usuario = localStorage.getItem('id_usuario')

        try {
            const response = await axios.post(`${conexao.endereco}/solicitacoes/incluir`, {
                empresa: localStorage.getItem("id_empresa"),
                estabelecimento: localStorage.getItem("estabelecimento"),
                usuario: id_usuario,
                operador: id_operador,
                categoria: "16",
                prioridade: "7",
                funcionario: values.empregado,
                tipo: "RESCISAO",
                assunto: `Solicitação de rescisão ${values.empregado}`,
                conteudo: 'Solicitação de Rescisão do Colaborador ' + values.empregado,
            }, { headers: { token: localStorage.getItem('Token') } });

            setMsgLoading("Enviando solicitação");
            setLoading(false);
            if (response.status === 201) {
                setModalConcluidoAberto(true);
                return response.data.ticket;
            } else {
                return null
            }
        } catch (error) {
            console.log(error);
            return null
        }
    }
    const enviarRescisao = async (values) => {

        setLoading(true)
        setMsgLoading("Enviando solicitação")

        const idTicket = await enviarSolicitacao(values)

        if (!idTicket) {
            setLoading(false)
            alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
            return;
        }

        await axios.post(`${conexao.endereco}/rescisoes/incluir`, {
            empresa: localStorage.getItem("id_empresa"),
            estab: localStorage.getItem("estabelecimento"),
            func: values.id_empregado,
            data_aviso: values.data_aviso,
            data_rescisao: values.data_rescisao,
            tipo: values.tipo_rescisao,
            obs: values.resc_obs,
            tipo_aviso: values.tipo_aviso,
            motivo: values.motivo_rescisao,
            hrextra1_horas: values.horas_extras_1_horas + ':' + values.horas_extras_1_minutos,
            hrextra1_perc: values.horas_extras_1_perc ? values.horas_extras_1_perc.replace('%', '') : null,
            hrextra2_horas: values.horas_extras_2_horas + ':' + values.horas_extras_2_minutos,
            hrextra2_perc: values.horas_extras_2_perc ? values.horas_extras_2_perc.replace('%', '') : null,
            hrextra3_horas: values.horas_extras_3_horas + ':' + values.horas_extras_3_minutos,
            hrextra3_perc: values.horas_extras_3_perc ? values.horas_extras_3_perc.replace('%', '') : null,
            atrasos: values.atrasos,
            dsr: values.dsr,
            hrnoturnas: values.horas_noturnas,
            vales: values.resc_vales ? values.resc_vales.replace('R$ ', '').replace(',', '.').replace(/\.(?=\d{3})/g, '') : null,
            consumo: values.resc_consumo ? values.resc_consumo.replace('R$ ', '').replace(',', '.').replace(/\.(?=\d{3})/g, '') : null,
            comissoes: values.resc_comissoes ? values.resc_comissoes.replace('R$ ', '').replace(',', '.').replace(/\.(?=\d{3})/g, '') : null,
            outros: values.resc_outros ? values.resc_outros : null,
            plsaude_titular: values.resc_plano_saude_titular ? values.resc_plano_saude_titular.replace('R$ ', '').replace(',', '.').replace(/\.(?=\d{3})/g, '') : null,
            plsaude_dependente: values.resc_plano_saude_dependente ? values.resc_plano_saude_dependente.replace('R$ ', '').replace(',', '.').replace(/\.(?=\d{3})/g, '') : null,
            anexos: listaAnexos,
            ticket: idTicket

        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {

                setLoading(false)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getRescisao = async (values) => {

        setLoading(true)
        setMsgLoading("buscando dados")

        axios.post(`${conexao.endereco}/rescisoes/listar`, {
            ticket: id_rescisao
        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {

                setLoading(false)
                if (response.status === 200) {

                    if (response.data[0].resc_anexos) {
                        setListaAnexos(JSON.parse(response.data[0].resc_anexos))
                    }

                    formRef.current.values.tipo_rescisao = response.data[0].resc_tipo

                    setValoresPadraoForm({
                        empresa: response.data[0].empr_razao,
                        numero_empresa: response.data[0].empr_id,
                        data_solicitacao: new Date(response.data[0].resc_created).toISOString().substr(0, 10),
                        id_empregado: response.data[0].func_id,
                        empregado: response.data[0].func_nome,
                        data_rescisao: new Date(response.data[0].resc_data_rescisao).toISOString().substr(0, 10),
                        data_aviso: new Date(response.data[0].resc_data_aviso).toISOString().substr(0, 10),
                        motivo_rescisao: response.data[0].resc_motivo,
                        tipo_rescisao: response.data[0].resc_tipo,
                        horas_extras_1_horas: response.data[0].resc_hrextra1_horas.split(':')[0],
                        horas_extras_1_minutos: response.data[0].resc_hrextra1_horas.split(':')[1],
                        horas_extras_1_perc: response.data[0].resc_hrextra1_perc,
                        horas_extras_2_horas: response.data[0].resc_hrextra2_horas.split(':')[0],
                        horas_extras_2_minutos: response.data[0].resc_hrextra2_horas.split(':')[1],
                        horas_extras_2_perc: response.data[0].resc_hrextra2_perc,
                        horas_extras_3_horas: response.data[0].resc_hrextra3_horas.split(':')[0],
                        horas_extras_3_minutos: response.data[0].resc_hrextra3_horas.split(':')[1],
                        horas_extras_3_perc: response.data[0].resc_hrextra3_perc,
                        atrasos: response.data[0].resc_atrasos,
                        dsr: response.data[0].resc_dsr_sfalta,
                        horas_noturnas: response.data[0].resc_hrnoturnas,
                        resc_vales: response.data[0].resc_vales,
                        resc_consumo: response.data[0].resc_consumo,
                        resc_plano_saude_titular: response.data[0].resc_plsaude_titular,
                        resc_plano_saude_dependente: response.data[0].resc_plsaude_dependente,
                        resc_comissoes: response.data[0].resc_comissoes,
                        resc_outros: response.data[0].resc_outros,
                        resc_obs: response.data[0].resc_obs

                    })

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const schemaRescisao = Yup.object().shape({
        empresa: Yup.string().required('Campo Obrigatório'),
        data_solicitacao: Yup.date().required('Campo Obrigatório'),
        data_aviso: Yup.date().required('Campo Obrigatório'),
        empregado: Yup.string().required('Campo Obrigatório'),
        data_rescisao: Yup.date().required('Campo Obrigatório'),
        motivo_rescisao: Yup.string().required('Campo Obrigatório'),
        tipo_rescisao: Yup.string().required('Campo Obrigatório')
    })


    const [valoresPadraoForm, setValoresPadraoForm] = useState({
        empresa: localStorage.getItem("razao"),
        empr: localStorage.getItem("empresa"),
        estab: localStorage.getItem("estabelecimento"),
        data_solicitacao: new Date().toISOString().substr(0, 10),
        data_aviso: new Date().toISOString().substr(0, 10),
        empregado: '',
        data_rescisao: new Date().toISOString().substr(0, 10),
        motivo_rescisao: '',
        tipo_rescisao: '',
    })


    return (
        <>
            <Navegacao />


            <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', paddingBottom: "4rem" }}>
                <Col sm={12} lg={12} style={{ paddingTop: 20 }}>
                    <Row>
                        <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                            <h3>Solicitação de Rescisão</h3>{ }
                        </Col>
                        <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>

                            {!edicao &&
                                <>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                        size='sm'
                                        type="button"
                                        onClick={() => {
                                            Navigate('/departamento-pessoal')
                                        }}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                        size='sm'
                                        type="submit"
                                        form="formRescisao"
                                    >
                                        Salvar
                                    </Button>
                                </>
                            }

                        </Col>
                        <Row >
                            <hr />
                        </Row>
                    </Row>
                </Col>
                <Row>
                    <Col>

                        <Formik
                            enableReinitialize={true}
                            onSubmit={async (values, actions) => {
                                console.log('Dados para Atualizacao', values)

                                const anexos = values.anexos

                                console.log('CONSOLE DA VARIAVEL ANEXOS', anexos)

                                if (anexos) {

                                    for (let i = 0; i < anexos.length; i++) {

                                        let urlAnexo = await UploadArquivo(anexos[i])

                                    }

                                    console.log('Lista de Anexos', listaAnexos)
                                }

                                await enviarRescisao(values)
                            }}
                            initialValues={{
                                ...valoresPadraoForm
                            }}
                            validationSchema={schemaRescisao}
                            innerRef={formRef}
                            render={({ values, errors, touched, isValid, setFieldValue }) => (
                                <Form id="formRescisao" autoComplete='off'>
                                    <Stack gap={3}>
                                        <Row>
                                            <Col sm={12} lg={6} >
                                                <label htmlFor="empresa" className="form-label">Empresa</label>
                                                <Field id="empresa" name="empresa" type="text" className="form-control" disabled style={{ textTransform: 'capitalize' }} />
                                                <ErroField campo="empresa" />
                                            </Col>


                                            <Col sm={12} lg={2} >
                                                <InputFieldPadrao
                                                    campo="data_solicitacao"
                                                    tipo="date"
                                                    texto="Data da Solicitação"
                                                    disabled
                                                />
                                                <ErroField campo="data_solicitacao" />
                                            </Col>

                                            <Col sm={12} lg={2}>
                                                <InputFieldPadrao campo="data_rescisao" tipo="date" texto="Data da rescisão" disabled={edicao} />
                                                <ErroField campo="data_rescisao" />
                                            </Col>

                                            <Col sm={12} lg={2}>
                                                <InputFieldPadrao campo="data_aviso" tipo="date" texto="Data do aviso" disabled={edicao} />
                                                <ErroField campo="data_aviso" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12} lg={5}>

                                                <InputFieldPadrao campo="empregado" tipo="text" disabled texto={
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        Empregado

                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                if (!edicao) {
                                                                    setModalFuncionarios(true)
                                                                } else {
                                                                    alert('Modo Visualização')
                                                                }
                                                            }
                                                            }
                                                        >
                                                            < BsSearch style={{ marginLeft: 10, fontSize: 16, color: "#E30613" }} />

                                                        </div>

                                                    </div>
                                                } />

                                            </Col>

                                            <Col lg={3}>
                                                <SelectFieldPadrao texto="Motivo da rescisão" campo="motivo_rescisao" disabled={edicao}>
                                                    <option value="" selected disabled hidden> -- Selecione o motivo</option>
                                                    <option>Iniciativa da empresa</option>
                                                    <option>Iniciativa do empregado</option>
                                                    <option>Acordo</option>
                                                    <option>Justa causa</option>
                                                    <option>Termino da experiencia</option>
                                                    <option>Termino ant iniciativa da empresa</option>
                                                    <option>Termino ant iniciativa do empregado</option>
                                                </SelectFieldPadrao>
                                            </Col>

                                            <Col lg={2}>
                                                <SelectFieldPadrao texto="Tipo de aviso" campo="tipo_aviso" disabled={edicao}>
                                                    <option value="" selected disabled hidden> -- Selecione o tipo</option>
                                                    <option>Trabalhado</option>
                                                    <option>Indenizado</option>
                                                </SelectFieldPadrao>
                                            </Col>
                                            <Col lg={2}>
                                                <SelectFieldPadrao texto="Tipo de rescisão" campo="tipo_rescisao" disabled={edicao}>
                                                    <option value="" selected disabled hidden> -- Selecione o tipo</option>
                                                    <option>Rescisão Efetiva</option>
                                                    <option>Apenas Aviso Prévio</option>
                                                </SelectFieldPadrao>
                                            </Col>





                                        </Row>


                                        {formRef?.current?.values?.tipo_rescisao === 'Rescisão Efetiva' &&
                                            <>
                                                <Row style={{ marginTop: 5 }}>
                                                    <Col lg={4} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Col lg={9} style={{ marginRight: 5 }}>
                                                            <InputHora
                                                                campoHora="horas_extras_1_horas"
                                                                campoMinuto="horas_extras_1_minutos"
                                                                texto="Horas Extras"
                                                                disabled={edicao}
                                                            />
                                                            <ErroField campo="horas_extras_1_horas" />
                                                        </Col>
                                                        <Col lg={3} >
                                                            <InputFieldPercentual campo="horas_extras_1_perc" tipo="number" texto="% Adicional" disabled={edicao} />
                                                            <ErroField campo="horas_extras_1_perc" />
                                                        </Col>

                                                    </Col>

                                                    <Col lg={4} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Col lg={9} style={{ marginRight: 5 }}>
                                                            <InputHora
                                                                campoHora="horas_extras_2_horas"
                                                                campoMinuto="horas_extras_2_minutos"
                                                                texto="Horas Extras"
                                                                disabled={edicao}
                                                            />
                                                            <ErroField campo="horas_extras_2_horas" />
                                                        </Col>
                                                        <Col lg={3}>
                                                            <InputFieldPercentual campo="horas_extras_2_perc" tipo="number" texto="% Adicional" disabled={edicao} />
                                                            <ErroField campo="horas_extras_2_perc" />
                                                        </Col>

                                                    </Col>

                                                    <Col lg={4} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Col lg={9} style={{ marginRight: 5 }}>
                                                            <InputHora
                                                                campoHora="horas_extras_3_horas"
                                                                campoMinuto="horas_extras_3_minutos"
                                                                texto="Horas Extras"
                                                                disabled={edicao}
                                                            />
                                                            <ErroField campo="horas_extras_3_horas" />
                                                        </Col>
                                                        <Col lg={3}>
                                                            <InputFieldPercentual campo="horas_extras_3_perc" tipo="number" texto="% Adicional" disabled={edicao} />
                                                            <ErroField campo="horas_extras_3_perc" />
                                                        </Col>

                                                    </Col>


                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <InputFieldPadrao campo="atrasos" tipo="text" texto="Atrasos" disabled={edicao} />
                                                        <ErroField campo="atrasos" />
                                                    </Col>

                                                    <Col>
                                                        <InputFieldPadrao campo="dsr" tipo="text" texto="DSR s/ faltas" disabled={edicao} />
                                                        <ErroField campo="dsr" />
                                                    </Col>
                                                    <Col>
                                                        <InputFieldPadrao campo="horas_noturnas" tipo="text" texto="Horas Noturnas" disabled={edicao} />
                                                        <ErroField campo="horas_noturnas" />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {formRef?.current?.values?.tipo_rescisao === 'Rescisão Efetiva' &&
                                            <Row>
                                                <Col>
                                                    <InputFieldMoeda campo="resc_vales" tipo="text" texto="Desc. Vales" disabled={edicao} />
                                                    <ErroField campo="resc_vales" />
                                                </Col>

                                                <Col>
                                                    <InputFieldMoeda campo="resc_consumo" tipo="text" texto="Desc. Consumo" disabled={edicao} />
                                                    <ErroField campo="resc_consumo" />
                                                </Col>

                                                <Col >
                                                    <InputFieldMoeda campo="resc_plano_saude_titular" texto="Plano Saúde Titular" disabled={edicao} />
                                                    <ErroField campo="resc_plano_saude_titular" />
                                                </Col>
                                                <Col>
                                                    <InputFieldMoeda campo="resc_plano_saude_dependente" texto="Plano Saúde Dependente" disabled={edicao} />
                                                    <ErroField campo="resc_plano_saude_dependente" />
                                                </Col>
                                                <Col>
                                                    <InputFieldMoeda campo="resc_comissoes" texto="Comissões" disabled={edicao} />
                                                    <ErroField campo="resc_comissoes" />
                                                </Col>

                                            </Row>
                                        }

                                        {formRef?.current?.values?.tipo_rescisao === 'Rescisão Efetiva' &&
                                            <Row>

                                                <Col>
                                                    <InputFieldTextArea campo="resc_outros" texto="Outros" disabled={edicao} />
                                                    <ErroField campo="resc_outros" />
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Row>

                                                <Col>
                                                    <InputFieldTextArea campo="resc_obs" texto="Observações" disabled={edicao} />
                                                    <ErroField campo="resc_obs" />
                                                </Col>
                                            </Row>
                                            <Col>
                                                {!edicao &&
                                                    <FormReact.Group controlId="formFileSm" className="mb-3">
                                                        <FormReact.Label>Anexos</FormReact.Label>
                                                        <FormReact.Control type="file" multiple value={formRef?.values?.anexos} onChange={(e) => setFieldValue('anexos', e.target.files)} />
                                                        <ErroField campo="anexos" />
                                                    </FormReact.Group>
                                                }
                                            </Col>
                                        </Row>
                                        {edicao &&
                                            <div>
                                                <h6>Anexos</h6>
                                                {listaAnexos.map((item) => {
                                                    return (
                                                        <div>
                                                            <sub>{item?.nome}</sub><br />
                                                            <a style={{ textDecoration: 'none' }} href={item?.url} target="_blank"><sup>{item?.url}</sup></a><br />
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        }
                                    </Stack>

                                </Form>

                            )}
                        >
                        </Formik>
                    </Col>
                </Row >
            </Container >

            <Footer />



            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={'lazy'}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                            {msgLoading}
                        </div>


                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={modalConcluidoAberto} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Concluido
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    A Solicitação foi enviada com sucesso
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        window.location.reload()
                    }}>OK</Button>
                </Modal.Footer>
            </Modal>

            <ModalFuncionarios
                isOpen={modalFuncionarios}
                setIsOpen={setModalFuncionarios}
                rowClick={(row) => {
                    formRef.current.setFieldValue('empregado', row.func_nome);
                    formRef.current.setFieldValue('id_empregado', row.func_id_contrato);
                    // alert(JSON.stringify(row))
                }}
            />

        </>
    )
}