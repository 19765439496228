import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useNavigate, useParams } from "react-router-dom"
import { Col, Row, Container, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import LogoArquivo from '../../../images/logo.svg';
import { GlobalContext } from "../../../contexts/global";
import DataTable from 'react-data-table-component';
import { MdEdit, MdDelete, MdViewList, MdCheck, MdError, MdDownload, MdBlock } from 'react-icons/md'
import { HiOutlineReceiptTax } from 'react-icons/hi'
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs'
import axios from "axios";
import { formatToCNPJ, isCNPJ, formatToBRL } from "brazilian-values";
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { InputFieldPadrao, ErroField, SelectFieldPadrao } from "../../../components/field"
import Header from "../../../components/header";
import DataTableExtensions from 'react-data-table-component-extensions';
import { Hint } from "../../../components/hint";
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ptbr from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptbr)

function ImpostosGuiasFiscais() {

    const Navigate = useNavigate()

    const { mes, ano } = useParams()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [cnpj, setCnpj] = useState('')
    const [url, setUrl] = useState('')
    const [token, setToken] = useState('04e4134a5ce5a71befb4f64f06b91638')

    const { usuario, tipoAcesso, conexao, licenca, permissao } = useContext(GlobalContext)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [calOpen, setCalOpen] = useState(false)
    const [mesAtual, setMesAtual] = useState(new Date())

    const [guias, setGuias] = useState([])

    const handleChange = (e) => {
        setCalOpen(!calOpen)
        setMesAtual(e)
    }
    const handleClick = (e) => {
        e.preventDefault()
        setCalOpen(!calOpen)
    }


    function primeiroDiaMesDB() {

        const currentDate = new Date(mesAtual);

        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        const formattedFirstDayOfMonth = `${firstDayOfMonth.getFullYear()}-${('0' + (firstDayOfMonth.getMonth() + 1)).slice(-2)}-${('0' + firstDayOfMonth.getDate()).slice(-2)}`;

        return formattedFirstDayOfMonth
    }

    function ultimoDiaMesDB() {

        const currentDate = new Date(mesAtual);

        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const formattedLastDayOfMonth = `${lastDayOfMonth.getFullYear()}-${('0' + (lastDayOfMonth.getMonth() + 1)).slice(-2)}-${('0' + lastDayOfMonth.getDate()).slice(-2)}`;

        return formattedLastDayOfMonth
    }

    function ExibeDados() {

        const colunas = [
            {
                name: 'Data Postagem',
                selector: row => row.DateCreation,
                cellExport: row => row.DateCreation,
                sortable: true,
                wrap: true,
                maxWidth: '180px'
            },
            {
                name: 'Competência',
                selector: row => row.Competence,
                cellExport: row => row.Competence,
                sortable: true,
                wrap: true,
                maxWidth: '180px'
            },
            {
                name: 'Documento',
                selector: row => row.Title,
                cellExport: row => row.Title,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Analista',
                selector: row => row.UserCreateName,
                cellExport: row => row.UserCreateName,
                sortable: true,
                wrap: true,
                maxWidth: '280px'
            },
            {
                name: 'Ações',
                button: true,
                center: true,
                cell: row => (
                    <>
                        {/* < a onClick={() => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao, idEmpresa: row.id } }) }} style={{ cursor: 'pointer' }}>
                            <TbListDetails size="24" color="#E30613" title="Usuários" />
                        </a> */}
                        <OverlayTrigger trigger="hover" placement="top" overlay={<Tooltip id="tooltip_baixar_documento">{token !== 'Nenhum' ? 'Baixar Documento' : 'Usuário Sem Permissão no Zen'}</Tooltip>}>
                            < a onClick={() => {
                                if (token !== 'Nenhum') {
                                    window.window.open(url + token + '/downloaddocumento/' + row.Id + '/' + cnpj + '/false/true', '_blank')
                                } else {
                                    alert('Download Não Permitido')
                                }

                            }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                                {token !== 'Nenhum' ? <MdDownload size="24" color="#E30613" /> : <MdBlock size="24" color="#E30613" />}
                                {/* <MdDownload size="24" color="#E30613" /> */}
                            </a>
                        </OverlayTrigger>
                    </>

                ),
            },

        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        const tableDataConfig = {
            columns: colunas,
            data: guias.filter(item => item.token !== 'Nenhum'),
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: true,
            export: true,
            dropdown: true
        };

        return (
            <DataTableExtensions {...tableDataConfig}>
                <DataTable
                    columns={colunas}
                    // onRowClicked={(row, index) => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao } }) }}
                    // expandOnRowClicked={true}
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    // pointerOnHover={true}
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }

    async function GetGuias() {

        setMsgLoading('Listando Guias Fiscais')
        setLoading(true)
        setGuias([])

        axios.post(conexao.endereco + '/questor/zen/documentos/listar',
            {
                "empresa": localStorage.getItem('empresa'),
                "estab": localStorage.getItem('estabelecimento'),
                "DataInicio": primeiroDiaMesDB(),
                "DataFinal": ultimoDiaMesDB(),
                "categoria": ["5408ce9ab0bbae13ac4fc4ee","5d8b567f588fe7073ce32275","54133c11b0bbae1cd06da3bd","53f3b0ac23c22a20e4452f75","533eff0e5b0ff30818cf35ad","533eff0e5b0ff30818cf359e","5408ce6bb0bbae13ac4fc4e1"],
                "usuario": localStorage.getItem('email'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setUrl(response.data.url)
                setCnpj(response.data.cnpj)
                setToken(response.data.token)
                setGuias(response.data.documentos)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })


    }

    useEffect(() => {
        Navigate(`/impostos/guias-fiscais/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`)
        GetGuias()
    }, [mesAtual])

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Header
                        titulo={
                            <>
                                <HiOutlineReceiptTax color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Guias Fiscais</strong>
                                <Hint posicao={"bottom"} id={"mes_anterior"} texto={"Mês Anterior"}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <BsFillArrowLeftSquareFill
                                            size={20}
                                            color="#E30613"
                                            style={{ cursor: 'pointer', border: 'none', marginLeft: 40 }}
                                            onClick={() => {
                                                let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() - 1))
                                                setMesAtual(novaData)
                                            }} />
                                    </div>
                                </Hint>
                                <Hint posicao={"top"} id={"selecionar_mes"} texto={"Selecionar Mês Especifico"}>
                                    <div onClick={handleClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase', color: '#E30614', cursor: 'pointer', width: 200, textAlign: 'center' }}>
                                        <span>{mes} de {ano}</span>
                                    </div>
                                </Hint>
                                <Hint posicao={"bottom"} id={"mes_posterior"} texto={"Mês Posterior"}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <BsFillArrowRightSquareFill
                                            size={20}
                                            color="#E30613"
                                            style={{ cursor: 'pointer', border: 'none' }}
                                            onClick={() => {
                                                let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() + 1))
                                                setMesAtual(novaData)
                                            }} />

                                    </div>
                                </Hint>

                                {/* <Row style={{ display: 'flex', flex: 1 }}>
                                    <Col lg={3} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <BsFillArrowLeftSquareFill
                                            size={36}
                                            color="#E30613"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() - 1))
                                                setMesAtual(novaData)
                                            }} />
                                    </Col>
                                    <Col lg={6} sm={8} className="d-grid gap-2">
                                        <Button
                                            style={{ backgroundColor: '#E30613', border: 0 }}
                                            full-width
                                            onClick={handleClick}

                                        >
                                            {mesAtual.toLocaleDateString('pt-BR', {
                                                month: 'long',
                                                year: 'numeric',
                                            }).toUpperCase()}
                                        </Button>
                                        {calOpen &&
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <DatePicker
                                                    selected={mesAtual}
                                                    onChange={handleChange}
                                                    inline
                                                    locale={ptbr}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    todayButton="Mês Atual"
                                                />
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={3} sm={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <BsFillArrowRightSquareFill
                                            size={36}
                                            color="#E30613"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() + 1))
                                                setMesAtual(novaData)
                                            }} />
                                    </Col>
                                </Row> */}
                            </>}
                        botaoAjuda={() => {
                            // Navigate('/arquivos')
                        }}
                        exibirBotao
                        textoBotao="Voltar"
                        acaoBotao={() => {
                            Navigate('/arquivos')
                        }}
                    >


                        <Button
                            style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                            size='sm'
                            onClick={() => { GetGuias() }}
                        >
                            Atualizar
                        </Button>


                    </Header>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <ExibeDados />
                        </Row>
                    </div>
                </Container>
                <Footer />

                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={calOpen}
                    onHide={() => { setCalOpen(false) }}
                    dialogClassName='modal-calendario'

                >
                    <Modal.Header closeButton style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>
                        Selecione o Mês
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DatePicker
                            selected={mesAtual}
                            onChange={handleChange}
                            inline
                            locale={ptbr}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            todayButton="Mês Atual"
                        />
                    </Modal.Body>
                </Modal>

            </div>
        </>
    )
}

export default ImpostosGuiasFiscais