import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global'
import Footer from "../../components/footer";
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Button, Modal, Form } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import axios from "axios";
import { TextoEditor } from "../../components/textoEditor";

function NovaConsulta() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    // eslint-disable-next-line
    const { usuario, tipoAcesso, conexao, licenca, statusSolicitacoes } = useContext(GlobalContext)

    const [solicitacaoAtual, setSolicitacaoAtual] = useState([])
    const [categorias, setCategorias] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [dataHora, setDataHora] = useState()

    function ExibeTelaAviso(titulo, msg) {
        setTelaTitulo(titulo)
        setTelaMsg(msg)
        setTelaAlerta(true)
    }

    function GetDataHoraAtual() {
        const agora = new Date();
        const datahora = agora.toLocaleDateString("pt-BR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        setDataHora(datahora)
    }

    useEffect(() => {
        GetDataHoraAtual()
        ObterDepartamentos()
        setSolicitacaoAtual({
            ...solicitacaoAtual,
            id_empresa: localStorage.getItem('id_empresa'),
            id_estab: localStorage.getItem('estabelecimento'),
            id_operador: '0',
            id_usuario: localStorage.getItem('id_usuario'),
            tipo_requisicao: 'SOLICITACAO',
            status: 'PENDENTE OPERADOR'
        })
    }, [])

    useEffect(() => {
     ObterCategorias(solicitacaoAtual.id_departamento)
    }, [solicitacaoAtual.id_departamento])

    async function ObterDepartamentos() {

        setMsgLoading('Listando Departamentos')
        setLoading(true)

        axios.post(conexao.endereco + '/solicitacoes/categorias/listar',
            {}, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setDepartamentos(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setDepartamentos([])
                setLoading(false)
            })
    }
    async function ObterCategorias(departamento) {

        setMsgLoading('Listando Categorias')
        setLoading(true)

        axios.post(conexao.endereco + '/consultas/categorias/listar',
            {
                departamento: departamento
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setCategorias(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setCategorias([])
                setLoading(false)
            })
    }

    async function EnviarSolicitacao(conteudo, anexo) {

        setMsgLoading('Enviando Solicitação')
        setLoading(true)

        axios.post(conexao.endereco + '/consultas/incluir',
            {
                empresa: solicitacaoAtual.id_empresa,
                estabelecimento: solicitacaoAtual.id_estab,
                usuario: solicitacaoAtual.id_usuario,
                operador: solicitacaoAtual.id_operador,
                departamento: solicitacaoAtual.departamento,
                categoria: solicitacaoAtual.categoria,
                empresa: solicitacaoAtual.id_empresa,
                assunto: solicitacaoAtual.assunto,
                conteudo: conteudo,
                anexo: anexo

            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                Navigate('/mais-opcoes/consultas')
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function EnviarArquivo() {

        setMsgLoading('Enviando Arquivo')
        setLoading(true)

        const formData = new FormData()

        formData.append('arquivo', solicitacaoAtual.anexo)

        axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                EnviarSolicitacao(solicitacaoAtual.conteudo, response.data.url)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>

                    <Row sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25, }}>
                        <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                            <h3>Nova Consulta</h3>
                        </Col>
                        <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0 }}
                                size='sm'
                                onClick={() => {
                                    console.log(JSON.stringify(solicitacaoAtual))
                                }}
                            >
                                Obter Ajuda
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <Row style={{ paddingLeft: 25, paddingRight: 25 }} >

                        <Col sm={12} lg={12}>
                            <Row sm={12} lg={12}>
                                <Col sm={12} lg={9} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    {/* <Button

                                        style={{ backgroundColor: '#E30613', border: 0 }}
                                        size='sm'
                                        id="dropdown-basic"
                                    >
                                        NOVA SOLICITAÇÃO
                                    </Button> */}
                                </Col>
                                <Col sm={12} lg={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {/* <Dropdown>
                                        <Dropdown.Toggle
                                            style={{ backgroundColor: '#E30613', border: 0 }}
                                            size='sm'
                                            id="dropdown-basic"
                                        >
                                            {filtroAtual}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {statusSolicitacoes.map((item) => {
                                                return (
                                                    <Dropdown.Item onClick={() => {
                                                        setFiltroAtual(item.STATUS)
                                                    }}>{item.STATUS}</Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </Col>
                            </Row>
                            <Row sm={12} lg={12}>
                                <Col sm={12} lg={12}>
                                    <Row style={{ marginTop: 20, paddingBottom: 80 }}>
                                        <Col>
                                            <Form>
                                                <Row>
                                                    <Col sm={12} lg={8}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.empresa">
                                                            <Form.Label>Empresa</Form.Label>
                                                            <Form.Control type="text" disabled value={localStorage.getItem('razao')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.datahora">
                                                            <Form.Label>Data / Hora Consulta</Form.Label>
                                                            <Form.Control type="text" disabled value={dataHora} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    
                                                    <Col sm={12} lg={3}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.categoria">
                                                            <Form.Label>Departamento</Form.Label>
                                                            <Form.Select
                                                                value={solicitacaoAtual.departamento}
                                                                onChange={(e) => {
                                                                    setSolicitacaoAtual({ ...solicitacaoAtual, departamento: e.target.value })
                                                                }}
                                                            >
                                                                <option>Selecione o Departamento</option>
                                                                {departamentos.map((item) => {
                                                                    return (
                                                                        <option value={item.scat_descricao}>{item.scat_descricao}</option>
                                                                    )
                                                                })}

                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={3}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.prioridade">
                                                            <Form.Label>Categoria</Form.Label>
                                                            <Form.Select
                                                                value={solicitacaoAtual.categoria}
                                                                onChange={(e) => {
                                                                    setSolicitacaoAtual({ ...solicitacaoAtual, categoria: e.target.value })
                                                                }}
                                                            >
                                                                <option>Selecione a Categoria</option>
                                                                {categorias.map((item) => {
                                                                    return (
                                                                        <option value={item.ccat_categoria}>{item.ccat_categoria}</option>
                                                                    )
                                                                })}

                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.assunto">
                                                            <Form.Label>Assunto</Form.Label>
                                                            <Form.Control type="text" value={solicitacaoAtual.assunto} onChange={(e) => { setSolicitacaoAtual({ ...solicitacaoAtual, assunto: e.target.value }) }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} lg={12}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.conteudo">
                                                            <Form.Label><strong>Solicitação</strong></Form.Label>
                                                            {/* <Form.Control
                                                                as="textarea"
                                                                rows={12}
                                                                placeholder="Descreva o Máximo de Detalhes"
                                                                value={solicitacaoAtual.conteudo}
                                                                onChange={(e) => { setSolicitacaoAtual({ ...solicitacaoAtual, conteudo: e.target.value }) }}
                                                            /> */}

                                                            <TextoEditor
                                                                content={solicitacaoAtual}
                                                                setContent={setSolicitacaoAtual}
                                                            />

                                                        </Form.Group>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} lg={4}>
                                                        <Form.Group className="mb-3" controlId="solicitacao.anexo">
                                                            <Form.Control type="file" onChange={(e) => { setSolicitacaoAtual({ ...solicitacaoAtual, anexo: e.target.files[0] }) }} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            style={{ backgroundColor: '#E30613', border: 0, height: 30 }}
                                                            size='sm'
                                                            onClick={() => {

                                                                if (solicitacaoAtual.conteudo) {
                                                                    if (solicitacaoAtual.anexo) {
                                                                        EnviarArquivo()
                                                                    } else {
                                                                        EnviarSolicitacao(solicitacaoAtual.conteudo, '')
                                                                    }

                                                                } else {
                                                                    ExibeTelaAviso('Atenção', 'Informe o Conteúdo')
                                                                }
                                                            }}
                                                        >
                                                            Enviar Consulta
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default NovaConsulta;