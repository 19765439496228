import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"


function AdmissaoPDF(dados, jornadasFormatadas) {

    let dependentes

    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const adicionais = dados.adicionaisFormatados.map((adicional) => {
        return [
            { text: adicional, fontSize: 11 }
        ]
    })

    const autorizacoes = dados.autorizacoesFormatadas.map((autorizacao) => {
        return [
            { text: autorizacao, fontSize: 11 }
        ]
    })

    const colunaJornadasFormatadas = jornadasFormatadas.map((jornada) => {
        return [
            [{ text: `• ${jornada.adjo_dias_semana}`, fontSize: 11 }],
            [{ text: jornada.adjo_horarios, fontSize: 11 }],
            [{ text: jornada.adjo_observacoes, fontSize: 11 }]
        ]
    })


    if (dados.filhos) {
        dependentes = dados.filhos.map((filho) => {
            return [
                { text: filho.adfi_nome, fontSize: 11 }
            ]
        })
    }

    if (dados.filhos.length < 1) {
        dependentes = [{ text: "Não informado", fontSize: 11 }]
    }




    const relatoriotitulo = [

        {
            text: "Detalhamento de Admissão",
            fontSize: 18.72,
            alignment: 'center',
            bold: true,
            margin: [0, 8, 0, 0]
        },
        {
            text: `Data de admissão: ${dados.dadosOriginais.admi_data_admissao}`,
            fontSize: 12,
            alignment: 'center',
            margin: [0, 4, 0, 25]
        }

    ]
    const detalhes = [
        {
            text: "Detalhamento de Admissão",
            fontSize: 18.72,
            alignment: 'center',
            bold: true,
            margin: [0, 8, 0, 0]
        },
        {
            text: `Data de admissão: ${dados.dadosOriginais.admi_data_admissao}`,
            fontSize: 12,
            alignment: 'center',
            margin: [0, 4, 0, 25]
        },
        {
            text: "NOME COMPLETO",
            fontSize: 11,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        {
            text: dados.dadosOriginais.admi_nome,
            fontSize: 11,
            margin: [0, 0, 0, 0]
        },

        {
            style: 'tableExample',
            margin: [0, 12, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*"],
                body: [
                    [
                        { text: 'CPF', style: 'tableHeader', margin: [0, 0, 190, 0], bold: true, fontSize: 11 },
                        { text: 'DATA NASCIMENTO', style: 'tableHeader', bold: true, fontSize: 11 }
                    ],
                    [dados.dadosOriginais.admi_cpf, dados.dadosOriginais.admi_data_nascimento],
                ]
            },
            layout: 'noBorders'

        },
        {
            style: 'tableExample',
            margin: [0, 12, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*"],
                body: [
                    [
                        { text: 'NATURALIDADE', style: 'tableHeader', margin: [0, 0, 130, 0], bold: true, fontSize: 11 },
                        { text: 'Nº PIS', style: 'tableHeader', bold: true, fontSize: 11 }
                    ],
                    [dados.dadosOriginais.admi_naturalidade, dados.dadosOriginais.admi_pis],
                ]
            },
            layout: 'noBorders'

        },
        {
            text: "FILIAÇÃO",
            fontSize: 11,
            bold: true,
            margin: [0, 12, 0, 6]
        },
        {
            text: dados.dadosOriginais.admi_nome_mae,
            fontSize: 11,
        },
        {
            text: dados.dadosOriginais.admi_nome_pai,
            fontSize: 11,
            margin: [0, 4, 0, 0]
        },
        {
            style: 'tableExample',
            margin: [0, 13, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*"],
                body: [
                    [
                        { text: 'CIDADE', style: 'tableHeader', margin: [0, 0, 180, 0], bold: true, fontSize: 11 },
                        { text: 'UF', style: 'tableHeader', bold: true, fontSize: 11 }
                    ],
                    [dados.dadosOriginais.admi_end_cidade, dados.dadosOriginais.admi_end_uf],
                ]
            },
            layout: 'noBorders'

        },
        {
            style: 'tableExample',
            margin: [0, 13, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*"],
                body: [
                    [
                        { text: 'BAIRRO', style: 'tableHeader', margin: [0, 0, 180, 0], bold: true, fontSize: 11 },
                        { text: 'CEP', style: 'tableHeader', bold: true, fontSize: 11 }
                    ],
                    [dados.dadosOriginais.admi_end_bairro, dados.dadosOriginais.admi_end_cep],
                ]
            },
            layout: 'noBorders'

        },
        {
            text: "ENDEREÇO",
            fontSize: 11,
            bold: true,
            margin: [0, 13, 0, 0]
        },
        {
            text: `${dados.dadosOriginais.admi_end_endereco}, ${dados.dadosOriginais.admi_end_numero}`,
            fontSize: 11,
            margin: [0, 8, 0, 0]
        },
        {
            style: 'tableExample',
            margin: [0, 13, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*"],
                body: [
                    [
                        { text: 'CARGO', style: 'tableHeader', margin: [0, 0, 180, 0], bold: true, fontSize: 11 },
                        { text: 'SALARIO', style: 'tableHeader', bold: true, fontSize: 11 }
                    ],
                    [dados.dadosOriginais.admi_cargo, `R$${dados.dadosOriginais.admi_salario}`],
                ]
            },
            layout: 'noBorders'

        },
        {
            text: 'ADICIONAIS',
            margin: [0, 12, 0, 6],
            fontSize: 11,
            bold: true,

        },
        {

            margin: [15, 0, 0, 0],

            columns: [
                {
                    ul: [
                        ...adicionais
                    ]
                }
            ]
        },
        {
            style: 'tableExample',
            margin: [0, 12, 0, 0],
            fontSize: 11,
            table: {
                headerRows: 1,
                widdths: ["*", "*", "*"],
                body: [
                    [
                        { text: 'DIA DE TRABALHO', style: 'tableHeader', margin: [0, 0, 80, 0], bold: true, fontSize: 11 },
                        { text: 'HORÁRIOS', style: 'tableHeader', margin: [0, 0, 140, 0], bold: true, fontSize: 11 },
                        { text: 'OBSERVAÇÃO', style: 'tableHeader', bold: true, fontSize: 11 }

                    ],
                    ...colunaJornadasFormatadas
                ]
            },
            layout: 'noBorders'
        },
        {
            text: 'AUTORIZAÇÕES',
            margin: [0, 12, 0, 6],
            fontSize: 11,
            bold: true,
        },
        {

            margin: [15, 0, 0, 0],

            columns: [
                {
                    ul: [...autorizacoes]

                }
            ]
        },
        {
            text: 'CONTRATO DE EXPERIENCIA',
            margin: [0, 12, 0, 6],
            fontSize: 11,
            bold: true,

        },
        {

            margin: [15, 0, 0, 0],

            columns: [
                {
                    ul: [
                        `Primeiro período: ${dados.dadosOriginais.admi_experiencia_p1}`,
                        `Segundo período: ${dados.dadosOriginais.admi_experiencia_p2}`,
                    ]
                }
            ]
        },
        {
            text: 'DEPENDENTES',
            margin: [0, 12, 0, 6],
            fontSize: 11,
            bold: true,

        },
        {

            margin: [15, 0, 0, 0],

            columns: [
                {
                    ul: [...dependentes]
                }
            ]
        },
    ];

    function Rodape(paginaAtual, quantidadePaginas) {
        return [
            {
                text: `${paginaAtual} / ${quantidadePaginas}`,
                fontSize: 9,
                alignment: 'right',
                bold: true,
                margin: [0, 10, 20, 0]
            }
        ]
    }

    const docDefinicoes = {
        pageSize: "A4",
        pageMargins: [15, 15, 15, 40],

        ///  header: [relatoriotitulo],

        content: [detalhes],

        footer: Rodape
    }

    pdfMake.createPdf(docDefinicoes).download(`Admissao ${dados.dadosOriginais.admi_nome}.pdf`);
}

export { AdmissaoPDF };