import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global'
import Footer from "../../components/footer";
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Col, Row, Container, Button, Modal, Form, Dropdown } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import DataTable from "react-data-table-component";
import { MdAttachFile } from "react-icons/md"
import axios from "axios";
import { CaixaMensagemFrom } from "../../components/caixaMensagem/from";
import { CaixaMensagemMe } from "../../components/caixaMensagem/me";
import { NovaCaixaMensagemMe } from "../../components/caixaMensagem/nova/NovaCaixaMensagemMe"
import { NovaCaixaMensagemFrom } from "../../components/caixaMensagem/nova/NovaCaixaMensagemFrom";
import { decode as base64_decode } from 'base-64';
import { TextoEditor } from "../../components/textoEditor";

function DetalheConsulta() {

    const Navigate = useNavigate()


    const { id } = useParams()

    // const Location = useLocation();
    // const dadosRecebidos = Location.state.dados
    // console.log("Dados recebidos: ", dadosRecebidos)
    /// const [solicitacoes, setSolicitacoes] = useState([])


    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAvaliacao, setTelaAvaliacao] = useState(false)
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaEstalonamento, setTelaEstalonamento] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [notaAvaliacao, setNotaAvaliacao] = useState(3)
    const [textoAvaliacao, setTextoAvaliacao] = useState('')

    const [progress, setProgresso] = useState(0)

    const { usuario, tipoAcesso, conexao } = useContext(GlobalContext)


    const [mensagens, setMensagens] = useState([])
    const [operadores, setOperadores] = useState([])
    const [detalhesSolicitacao, setDetalhesSolicitacao] = useState({})

    const [mensagemAtual, setMensagemAtual] = useState([])

    function FinalizaSolicitacao(id) {
        setTelaTitulo('Pesquisa de Satisfação')
        setTelaMsg('Seu feedback é muito importante. Conte-nos como foi a sua experiência neste atendimento.')
        setTelaAvaliacao(true)
    }
    function ExibeTelaAviso(titulo, msg) {
        setTelaTitulo(titulo)
        setTelaMsg(msg)
        setTelaAlerta(true)
    }

    useEffect(() => {
        ListaSolicitacao(base64_decode(id))
    }, [])


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    async function ListaSolicitacao(IdInformado) {

        setMsgLoading('Listando Mensagens')
        setLoading(true)

        await axios.post(conexao.endereco + '/consultas/detalhe',
            {
                id: IdInformado
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setMensagens(response.data.mensagens.filter(m => !m.mens_tipo ))
                setDetalhesSolicitacao(response.data.ticket)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }


    async function EnviarMensagemSolicitacao(conteudo, anexo) {

        setMsgLoading('Enviando Mensagem Solicitação')
        setLoading(true)

        const url = conexao.endereco + "/consultas/mensagens/incluir"

        let requisicao = new XMLHttpRequest()

        let body = {
            "consulta": detalhesSolicitacao?.cons_id,
            "usuario": tipoAcesso === "OPERADOR" ? 0 : Number(usuario.id),
            "operador": tipoAcesso === "USUARIO" ? 0 : Number(usuario.id),
            "conteudo": conteudo,
            "anexo": anexo
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE CRIAR: ' + this.response)
            if (requisicao.status === 201) {
                let resposta = JSON.parse(this.response)
                ListaSolicitacao(detalhesSolicitacao?.cons_id)

                if (tipoAcesso === 'OPERADOR') {
                    AtualizaStatusSolicitacao('PENDENTE CLIENTE')
                }
                if (tipoAcesso === 'USUARIO') {
                    AtualizaStatusSolicitacao('PENDENTE OPERADOR')
                }

                ExibirAlerta('Parabéns', resposta.message)
                setMensagemAtual({ conteudo: '' })
            } else {
                alert('Falha ao Enviar Mensagem')
            }
        }
    }

    async function AtualizaStatusSolicitacao(status) {

        setMsgLoading('Atualizando Status Solicitação')
        setLoading(true)

        const url = conexao.endereco + "/consultas/atualizar"

        let requisicao = new XMLHttpRequest()

        let body = {
            "status": status,
            "consulta": detalhesSolicitacao?.cons_id,
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status !== 200) {
                ExibirAlerta('Atenção', 'Falha ao Alterar Status da Solicitação')
                setMensagemAtual({ conteudo: '' })
            }

        }
    }

    async function FinalizarSolicitacao(observacao, nota) {

        axios.post(conexao.endereco + '/consultas/atualizar',
            {
                "status": "FINALIZADA",
                "consulta": detalhesSolicitacao?.cons_id,
                "avaliacao": Number(nota),
                "comentarios": observacao
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status !== 200) {
                    ExibirAlerta('Atenção', 'Falha ao Alterar Status da Consulta')
                    setMensagemAtual({ conteudo: '' })
                } else {
                    Navigate('/mais-opcoes/consultas')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })



    }

    async function AtualizaOperadorSolicitacao(operador, nome) {

        setMsgLoading('Atualizando Operador Solicitação')
        setLoading(true)

        const url = conexao.endereco + "/Solicitacoes/"

        let requisicao = new XMLHttpRequest()

        let body = {
            "tipo_requisicao": "EDITAR_OPERADOR_SOLICITACAO",
            "id_solicitacao": detalhesSolicitacao?.cons_id,
            "novo_operador": operador
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status !== 200) {
                ExibirAlerta('Atenção', 'Falha ao Alterar Operador da Solicitação')
                setMensagemAtual({ conteudo: '' })
            } else {

                EnviarMensagemSolicitacao('Seu Atendimento foi Encaminhado para o Operador: ' + nome, '')
                setTelaEstalonamento(false)
            }

        }
    }

    async function EnviarArquivo() {

        setMsgLoading('Enviando Arquivo')
        setLoading(true)

        const formData = new FormData()

        formData.append('arquivo', mensagemAtual.anexo)

        axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                EnviarMensagemSolicitacao(mensagemAtual.conteudo, response.data.url)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    async function ListarOperadores() {

        setMsgLoading('Listando Operadores')
        setLoading(true)

        const url = conexao.endereco + '/Operadores/'

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                setOperadores(JSON.parse(this.response))
                setTelaEstalonamento(true)
            }
            if (requisicao.status === 204) {
                setOperadores([])
            }
            if (requisicao.status === 400) {
                ExibirAlerta('Falha ao Listar Operadores', JSON.stringify(this.response))
            }
        }
    }

    function ExibeOperadores() {

        const colunas = [
            {
                name: 'Nome',
                selector: row => row.nome,
                sortable: true,
            },
            {
                name: 'Função',
                selector: row => row.funcao,
                sortable: true,
                wrap: true
            },
            {
                name: 'E-mail',
                selector: row => row.email,
                sortable: true,
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (
            <DataTable
                columns={colunas}
                data={operadores}
                onRowClicked={(row, index) => {
                    AtualizaOperadorSolicitacao(row.id, row.nome)
                }}

                striped={true}
                highlightOnHover={true}
                responsive={true}
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        )
    }


    return (
        <>
            <Navegacao />

            <div style={{
                display: 'flex',
                backgroundColor: '#f5f5f5',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center', alignItems: 'center'
            }}>
                <Container style={{
                    display: 'flex', backgroundColor: '#f5f5f5',
                    width: '100%',
                    flexDirection: 'column',
                    padding: 0
                }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>{detalhesSolicitacao?.cons_assunto}</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        alert(JSON.stringify(usuario.cons_id))
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>
                        <hr />
                    </Row>

                    <Row style={{
                        paddingRight: "2.5rem",
                        paddingLeft: "2.5rem",
                    }}> {/* Conteudo da Solicitação */}
                        <Col>
                            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Col style={{ backgroundColor: 'white', width: '100%', minHeight: "4rem", borderRadius: 15, padding: 25, overflow: 'auto' }}>
                                    <Row>
                                        <div
                                            style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}
                                            dangerouslySetInnerHTML={{ __html: detalhesSolicitacao?.cons_conteudo }}
                                        />

                                        {/* {detalhesSolicitacao?.cons_conteudo}
                                        </div> */}
                                    </Row>
                                    <Row lg={12} sm={12} style={{ height: 10, margin: 0, padding: 0, display: 'flex', flexDirection: 'row' }}>
                                        <Col lg={6} sm={6} style={{ cursor: "pointer" }} onClick={() => { window.open(detalhesSolicitacao?.cons_anexo, '_blank'); }}>
                                            {detalhesSolicitacao?.cons_anexo &&
                                                <>
                                                    <MdAttachFile size="16" color="#E30613" title="Anexar" />
                                                    <strong style={{ color: 'grey', fontSize: 12 }}>Anexo Disponível</strong>
                                                </>
                                            }
                                        </Col>
                                        <Col lg={6} sm={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                            <strong style={{ color: 'grey', fontSize: 12, textAlign: "end" }}>{detalhesSolicitacao?.data_cadastro}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{
                        marginTop: 15,
                        marginBottom: 15,
                        paddingRight: "2.5rem",
                        paddingLeft: "2.5rem",
                    }}
                    >
                        <hr />
                    </Row>

                    <Row style={{
                        paddingRight: "2.5rem",
                        paddingLeft: "2.5rem",
                    }}>  {/* Mensagens da Solicitação */}
                        {mensagens.map((msg) => {

                            if (msg.mens_origem === tipoAcesso.replace("USUARIO", "USÚARIO")) {
                                return (
                                    <NovaCaixaMensagemMe
                                        style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}
                                        key={msg.mens_id}
                                        message={msg.mens_conteudo}
                                        name={msg.mens_usuario}
                                        anexo={msg.mens_anexo}
                                        data={new Date(msg.mens_data).toLocaleDateString
                                            ("pt-br", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit"
                                            })
                                        }
                                    />
                                )
                            }

                            if (msg.mens_origem !== tipoAcesso.replace("USUARIO", "USÚARIO")) {
                                return (
                                    <NovaCaixaMensagemFrom
                                        style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}
                                        key={msg.mens_id}
                                        message={msg.mens_conteudo}
                                        name={msg.mens_usuario}
                                        anexo={msg.mens_anexo}
                                        data={new Date(msg.mens_data).toLocaleDateString
                                            ("pt-br", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit"
                                            })
                                        }
                                    />
                                )
                            }

                        })
                        }
                    </Row>
                    <Row
                        style={{
                            paddingLeft: 40,
                            paddingRight: 40,
                            marginTop: 15,
                            marginBottom: 15
                        }}>
                        <hr />
                    </Row>

                    <Row style={{ marginLeft: 40, marginRight: 40, marginBottom: 120, backgroundColor: 'white', borderRadius: 10 }}>
                        <Row style={{ padding: 25 }}>
                            <Form>
                                <Row>
                                    <Col sm={12} lg={12}>
                                        <Form.Group className="mb-3" controlId="formCadCNPJ">
                                            <Form.Label><strong>Responder</strong></Form.Label>

                                            {/* <Form.Control as="textarea"
                                             rows={6}
                                              placeholder="Descreva o Máximo de Detalhes"
                                               value={mensagemAtual.conteudo}
                                                onChange={(e) => { setMensagemAtual({ ...mensagemAtual, conteudo: e.target.value }) }}
                                                 /> */}

                                            <TextoEditor
                                                content={mensagemAtual}
                                                setContent={setMensagemAtual}
                                            />

                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} lg={12} style={{ height: 50, display: 'flex', flexDirection: 'row' }}>
                                        <Col sm={12} lg={8} style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                                            <Form.Group className="mb-3" controlId="formAnexoMensagem">
                                                {/* <Form.Label>Foto 3X4</Form.Label> */}
                                                <Form.Control type="file" onChange={(e) => { setMensagemAtual({ ...mensagemAtual, anexo: e.target.files[0] }) }} />
                                            </Form.Group>
                                            {/* <MdAttachFile size="24" color="#E30613" title="Anexar" style={{ cursor: "pointer" }} /> */}
                                        </Col>


                                    </Col>

                                </Row>
                                <Row
                                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

                                    <Col
                                        lg
                                        style={{ marginBottom: "0.5rem" }}
                                    // sm={12}
                                    // lg={4}
                                    /// </Row> style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                    >
                                        <Button
                                            style={{ backgroundColor: '#E30613', border: 0, width: "100%" }}
                                            size='sm'
                                            onClick={() => {
                                                if (mensagemAtual.conteudo) {
                                                    if (mensagemAtual.anexo) {
                                                        EnviarArquivo()
                                                    } else {
                                                        EnviarMensagemSolicitacao(mensagemAtual.conteudo, '')
                                                    }

                                                } else {
                                                    ExibeTelaAviso('Atenção', 'Informe a Mensagem')
                                                }

                                            }}
                                        >
                                            Enviar Resposta
                                        </Button>

                                    </Col>
                                    <Col
                                        lg
                                        style={{ marginBottom: "0.5rem" }}
                                    ///  xs lg="2"
                                    // sm={12}
                                    // lg={4}
                                    >
                                        {tipoAcesso === 'USUARIO' &&
                                            <Button
                                                style={{ backgroundColor: '#E30613', border: 0, width: "100%" }}
                                                size='sm'
                                                onClick={() => {
                                                    FinalizaSolicitacao(detalhesSolicitacao?.mens_id)
                                                }}
                                            >
                                                Finalizar Solicitação
                                            </Button>
                                        }

                                        {tipoAcesso === 'ADM' &&
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    style={{ backgroundColor: '#E30613', border: 0, marginLeft: 15 }}
                                                    size='sm'
                                                    id="dropdown-basic"
                                                >
                                                    Opções do Operador
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => {
                                                        ListarOperadores()
                                                    }}>Encaminhar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                    </Col>
                                </Row>

                            </Form>
                        </Row>

                    </Row>


                </Container>
                {/* <Footer /> */}
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAvaliacao} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                        <Row style={{ marginBottom: 40 }}>
                            <p style={{ textAlign: 'center' }}>{telaMsg}</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form>

                                    <Form.Group className="mb-3" controlId="formCadCNPJ">
                                        <Form.Label><strong>Avalie o Atendimento</strong></Form.Label>

                                        <Form.Range min={1} max={4} value={notaAvaliacao} onChange={(e) => { setNotaAvaliacao(e.target.value) }} />

                                    </Form.Group>


                                </Form>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Col>
                                {notaAvaliacao == 1 &&
                                    <strong style={{ color: 'red' }}>Insatisfeito</strong>
                                }
                                {notaAvaliacao == 2 &&
                                    <strong style={{ color: '#FFC107' }}>Pouco Satisfeito</strong>
                                }
                                {notaAvaliacao == 3 &&
                                    <strong style={{ color: '#007BFE' }}>Satisfeito</strong>
                                }
                                {notaAvaliacao == 4 &&
                                    <strong style={{ color: '#28A745' }}>Muito Satisfeito</strong>
                                }
                            </Col>
                        </Row>
                        <Row lg={12} sm={12} style={{ display: 'flex', flex: 1 }}>
                            <Form>

                                <Form.Group className="mb-3" controlId="formCadCNPJ">
                                    <Form.Label><strong>Observações</strong></Form.Label>

                                    <Form.Control as="textarea" rows={6} placeholder="Apenas os gestores da área tem acesso a essa informação" value={textoAvaliacao} onChange={(e) => { setTextoAvaliacao(e.target.value) }} />

                                </Form.Group>


                            </Form>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaAvaliacao(false) }}>Fechar</Button>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            FinalizarSolicitacao(textoAvaliacao, notaAvaliacao)
                        }}>Confirmar</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={telaEstalonamento} centered dialogClassName='modal-50'>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        Selecione o Operador
                    </Modal.Header>
                    <Modal.Body >
                        <Row>
                            <Col>
                                <ExibeOperadores />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            setTelaEstalonamento(false)
                        }}>Sair</Button>

                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default DetalheConsulta