import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../contexts/global";
import {
  Modal,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Table,
  Popover,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { formatToCNPJ } from "brazilian-values";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { MdCheck, MdClose, MdInfo } from "react-icons/md";
// import { formatarReal } from "../utils/formatarReal";

export function ModalEmpresas(props) {
  const { conexao, setMsgLoading, GetEmpresas, empresas } =
    useContext(GlobalContext);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [msgLoading, setMsgLoading] = useState('')
  // const [empresas, setEmpresas] = useState([])

  useEffect(() => {
    GetEmpresas();
    setShow(props.modal);
  }, [props.modal]);

  // async function GetEmpresas() {
  //     console.log("Entrou no get")

  //     setMsgLoading('Obtendo Empresas')
  //     setLoading(true)

  //     await axios.post(conexao.endereco + '/empresas/listar',
  //         {}, { headers: { token: localStorage.getItem('Token') } })

  //         .then(function (response) {
  //             setEmpresas(response.data)
  //             console.log("Response: ", response.data)
  //             setLoading(false)
  //         })
  //         .catch(function (error) {
  //             console.error(error)
  //             setLoading(false)
  //         })

  // }

  function ExibeEmpresasUsuario() {
    const colunas = [
      {
        name: "Nome Fantasia",
        selector: (row) => row.empr_nome,
        sortable: true,
        minWidth: "30%",
      },
      {
        name: "ID Empresa",
        selector: (row) => row.empr_id,
        omit: true,
      },
      {
        name: "Razão Social",
        selector: (row) => row.empr_razao,
        sortable: true,
        minWidth: "40%",
      },
      {
        name: "CNPJ",
        selector: (row) => row.empr_cnpj,
        format: (row) => formatToCNPJ(row.empr_cnpj),
        sortable: true,
        wrap: true,
        minWidth: "20%",
      },
      {
        name: "Empresa",
        selector: (row) => row.empr_questor_empresa,
        sortable: true,
        wrap: true,
        maxWidth: "5%",
        // omit: true
      },
      {
        name: "Estab",
        selector: (row) => row.empr_questor_estabelecimento,
        sortable: true,
        wrap: true,
        maxWidth: "5%",
        // omit: true
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    const tableDataEmpresas = {
      columns: colunas,
      data: empresas,
      filterPlaceholder: "Filtrar",
      exportHeaders: true,
      print: false,
      export: false,
      filterDigit: 1,
    };

    return (
      <DataTableExtensions {...tableDataEmpresas}>
        <DataTable
          // columns={colunas}
          // data={empresas}
          onRowClicked={(row, index) => {
            props.setEmpresa({
              id: row.empr_id,
              nome: row.empr_razao,
              empr_questor_empresa: row.empr_questor_empresa,
              empr_questor_estab: row.empr_questor_estabelecimento,
            });
            props.setModal(false);
          }}
          // expandOnRowClicked={true}
          striped={true}
          dense
          highlightOnHover={true}
          responsive={true}
          pointerOnHover={true}
          noDataComponent={"Nenhum Registro Encontrado =/"}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
        />
      </DataTableExtensions>
    );
  }

  return (
    <Modal show={show} centered dialogClassName="modal-75">
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
        Selecione a Empresa
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ExibeEmpresasUsuario />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#E30613", border: 0 }}
          onClick={() => {
            props.setModal(false);
          }}
        >
          Sair
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalSalario(props) {
  const { usuario, tipoAcesso, conexao, licenca, statusSolicitacoes } =
    useContext(GlobalContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  const [cargos, SetCargos] = useState([]);

  useEffect(() => {
    GetCargos();
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  async function GetCargos() {
    setMsgLoading("Obtendo Empresas");
    setLoading(true);

    axios
      .post(
        conexao.endereco + "/questor/cargos_salarios/listar",
        {
          empresa: props.empresa,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        SetCargos(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  function ExibeSalariosCargo() {
    const colunas = [
      {
        name: "Código",
        selector: (row) => row.qcar_codigo,
        sortable: true,
        wrap: true,
        maxWidth: "7%",
        ///  omit: true
      },
      {
        name: "Descrição",
        selector: (row) => row.qcar_descricao,
        sortable: true,
        minWidth: "30%",
      },
      {
        name: "Menor Salário",
        selector: (row) => row.qcar_menor_salario,
        sortable: true,
        minWidth: "30%",
      },
      {
        name: "Maior Salário",
        selector: (row) => row.qcar_maior_salario,
        sortable: true,
        minWidth: "30%",
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    const tableDataCargos = {
      columns: colunas,
      data: cargos,
      filterPlaceholder: "Filtrar",
      exportHeaders: true,
      print: false,
      export: false,
      filterDigit: 1,
    };

    return (
      <DataTableExtensions {...tableDataCargos}>
        <DataTable
          // columns={colunas}
          // data={empresas}
          onRowClicked={(row, index) => {
            props.setCriarCargo(false);
            props.setIsOpen(false);
            props.setFieldValue("admi_cargo", row.qcar_descricao);
          }}
          // expandOnRowClicked={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
          dense
          pointerOnHover={true}
          noDataComponent={"Nenhum Registro Encontrado =/"}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
        />
      </DataTableExtensions>
    );
  }

  return (
    <Modal show={isOpen} centered dialogClassName="modal-75">
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
        Selecione o cargo
        <div
          onClick={() => {
            props.setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <MdClose />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ExibeSalariosCargo />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#E30613", border: 0 }}
          onClick={() => {
            props.setCriarCargo(true);
            props.setIsOpen(false);
            props.setFieldValue("admi_cargo", "");
            props.setFieldValue("admi_salario", "");
          }}
        >
          Informar cargo não cadastrado
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalFuncionarios(props) {
  const { conexao } = useContext(GlobalContext);
  const [funcionarios, setFuncionarios] = useState([]);

  async function getFuncionarios() {
    axios
      .post(
        conexao.endereco + "/funcionarios/listar",
        {
          empresa: localStorage.getItem("id_empresa"),
          estab: localStorage.getItem("estabelecimento"),
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setFuncionarios(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  useEffect(() => {
    getFuncionarios();
  }, []);

  const colunas = [
    {
      name: "Código",
      selector: (row) => row.func_id_contrato,
      sortable: true,
      wrap: true,
      maxWidth: "7%",
      ///  omit: true
    },
    {
      name: "Nome",
      selector: (row) => row.func_nome,
      sortable: true,
      minWidth: "30%",
    },
    {
      name: "CPF",
      selector: (row) => row.func_cpf,
      sortable: true,
      minWidth: "30%",
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const tableDataCargos = {
    columns: colunas,
    data: funcionarios,
    filterPlaceholder: "Filtrar",
    exportHeaders: true,
    print: false,
    export: false,
    filterDigit: 1,
  };

  return (
    <Modal
      show={props.isOpen}
      centered
      dialogClassName="modal-75"
      onHide={() => props.setIsOpen(false)}
    >
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
        Selecione o Funcionário
        <div
          onClick={() => {
            props.setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <MdClose />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <DataTableExtensions {...tableDataCargos}>
              <DataTable
                // columns={colunas}
                // data={empresas}
                onRowClicked={(row, index) => {
                  props.rowClick(row);
                  props.setIsOpen(false);
                }}
                // expandOnRowClicked={true}
                striped={true}
                // paginationPerPage={9999}
                highlightOnHover={true}
                responsive={true}
                dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={false}
                paginationComponentOptions={paginationComponentOptions}
              />
            </DataTableExtensions>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export function ModalFuncionariosLote(props) {
  const { conexao } = useContext(GlobalContext);
  const [funcionarios, setFuncionarios] = useState([]);
  const [lote, setLote] = useState([]);

  async function getFuncionarios() {
    axios
      .post(
        conexao.endereco + "/funcionarios/listar",
        {
          empresa: localStorage.getItem("id_empresa"),
          estab: localStorage.getItem("estabelecimento"),
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setFuncionarios(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  useEffect(() => {
    getFuncionarios();
  }, []);

  const colunas = [
    {
      name: "Código",
      selector: (row) => row.func_id_contrato,
      sortable: true,
      wrap: true,
      maxWidth: "7%",
      ///  omit: true
    },
    {
      name: "Nome",
      selector: (row) => row.func_nome,
      sortable: true,
      minWidth: "30%",
    },
    {
      name: "CPF",
      selector: (row) => row.func_cpf,
      sortable: true,
      minWidth: "30%",
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const tableDataCargos = {
    columns: colunas,
    data: funcionarios,
    filterPlaceholder: "Filtrar",
    exportHeaders: true,
    print: false,
    export: false,
    filterDigit: 1,
  };

  return (
    <Modal
      show={props.isOpen}
      centered
      dialogClassName="modal-75"
      onHide={() => props.setIsOpen(false)}
    >
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
        Selecione os Funcionários
        {lote.length > 0 && (
          <Button
            size="sm"
            style={{ backgroundColor: "#E30613", border: 0, marginRight: 15 }}
            onClick={() => {
              props.confirmar(lote);
            }}
          >
            Confirmar Selecionados [{lote.length}]
          </Button>
        )}
        <div
          onClick={() => {
            props.setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <MdClose />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <DataTableExtensions {...tableDataCargos}>
              <DataTable
                // columns={colunas}
                // data={empresas}
                // expandOnRowClicked={true}
                striped={true}
                selectableRows
                selectableRowsHighlight
                onSelectedRowsChange={(rows) => {
                  setLote(rows.selectedRows);
                }}
                // paginationPerPage={9999}
                highlightOnHover={true}
                responsive={true}
                dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={false}
                paginationComponentOptions={paginationComponentOptions}
              />
            </DataTableExtensions>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export function ModalEscalas(props) {
  const { usuario, tipoAcesso, conexao, licenca, statusSolicitacoes } =
    useContext(GlobalContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  const [escalas, setEscalas] = useState([]);

  useEffect(() => {
    GetEscalas();
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  async function GetEscalas() {
    setMsgLoading("Obtendo Escalas");
    setLoading(true);

    axios
      .post(
        conexao.endereco + "/questor/escalas/listar",
        {
          empresa: localStorage.empresa,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setEscalas(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  function ExibeEscalas() {
    const colunas = [
      {
        name: "Código",
        selector: (row) => row.qesc_codigo,
        sortable: true,
        wrap: true,
        maxWidth: "7%",
        ///  omit: true
      },
      {
        name: "Descrição",
        selector: (row) => row.qesc_descricao,
        sortable: true,
        minWidth: "30%",
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    const tableDataEscalas = {
      columns: colunas,
      data: escalas,
      filterPlaceholder: "Filtrar",
      exportHeaders: true,
      print: false,
      export: false,
      filterDigit: 1,
    };

    return (
      <DataTableExtensions {...tableDataEscalas}>
        <DataTable
          // columns={colunas}
          // data={empresas}
          onRowClicked={(row, index) => {
            props.setJornadaHorarios(row.qesc_descricao);
            props.setCriarEscala(false);
            props.setIsOpen(false);
          }}
          // expandOnRowClicked={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
          dense
          pointerOnHover={true}
          noDataComponent={"Nenhum Registro Encontrado =/"}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
        />
      </DataTableExtensions>
    );
  }

  return (
    <Modal
      show={isOpen}
      centered
      dialogClassName="modal-75"
      onHide={() => props.setIsOpen(false)}
    >
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }} closeButton>
        Selecione a Escala
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ExibeEscalas />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#E30613", border: 0 }}
          onClick={() => {
            props.setCriarEscala(true);
            props.setIsOpen(false);
            props.setJornadaHorarios("");
          }}
        >
          Criar escala
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalJornadas(props) {
  const { conexao } = useContext(GlobalContext);

  const { estaAberto, setEstaAberto, setFieldValue, dia, campo } = props;

  const [listaEscalas, setListaEscalas] = useState();
  const [escalaCompleta, setEscalaCompleta] = useState();

  const transformDiasDaSemana = {
    SEG: "Segunda-feira",
    TER: "Terça-feira",
    QUA: "Quarta-feira",
    QUI: "Quinta-feira",
    SEX: "Sexta-feira",
    SAB: "Sabado",
    DOM: "Domingo",
  };

  function calcularTempoTotal(tempoString) {
    const tempos = tempoString.split(" ");

    console.log(tempos);

    let totalMinutos = 0;

    if (tempos.length == 5) {
      const [horasT1, minutosT1] = tempos[0].split(":");

      const [horasT2, minutosT2] = tempos[1].split(":");

      const [horasT3, minutosT3] = tempos[3].split(":");

      const [horasT4, minutosT4] = tempos[4].split(":");

      const minutosP1 =
        parseInt(horasT2) * 60 +
        parseInt(minutosT2) -
        (parseInt(horasT1) * 60 + parseInt(minutosT1));

      const minutosP2 =
        parseInt(horasT4) * 60 +
        parseInt(minutosT4) -
        (parseInt(horasT3) * 60 + parseInt(minutosT3));

      console.log("Minutos P1: ", minutosP1);
      console.log("Minutos P2: ", minutosP2);

      totalMinutos = parseInt(minutosP1) + parseInt(minutosP2);
      console.log(totalMinutos);
      return;
    } else return totalMinutos;

    // for (const tempo of tempos) {
    //     if (tempo === '-') {
    //         intervalo = true;
    //     } else {
    //         const [horas, minutos] = tempo.split(':');
    //         const minutosTotais = parseInt(horas) * 60 + parseInt(minutos);

    //         if (intervalo) {
    //             totalMinutos -= minutosTotais; // Deduz o tempo do intervalo
    //         } else {
    //             totalMinutos += minutosTotais;
    //         }

    //         console.log('Processando tempo: ', tempoString)

    //         intervalo = false;
    //         ultimoTempo = minutosTotais;
    //     }
    // }

    // const horasTotal = Math.floor(Math.abs(totalMinutos) / 60);
    // const minutosRestantes = Math.abs(totalMinutos) % 60;
    // const sinal = totalMinutos >= 0 ? '' : '-';

    // return `${sinal}${horasTotal}:${minutosRestantes.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    ListarEscalas();
  }, [estaAberto]);

  const ListarEscalas = async () => {
    axios
      .post(
        conexao.endereco + "/questor/horarios/listar",
        {
          empresa: props.empresa,
          // dia: props.dia
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        console.log("RESPONSE: ", response.data);

        const escalaFiltrada = response.data.filter(
          (x) => x.qhor_dia_semana == dia
        );
        const escalaCompleta = response.data;

        console.log("Escala Filtrada:", escalaFiltrada);

        let listaEscalaAtual = [];

        for (let i = 0; i < escalaFiltrada.length; i++) {
          let escala = escalaCompleta.filter(
            (x) =>
              x.qhor_codigo_escala == escalaFiltrada[i].qhor_codigo_escala &&
              x.qhor_questor_empresa == escalaFiltrada[i].qhor_questor_empresa
          );

          // console.log('Escala: ', escala)

          let obj = {
            qhor_id: escalaFiltrada[i].qhor_id,
            qhor_questor_empresa: escalaFiltrada[i].qhor_questor_empresa,
            qhor_codigo_escala: escalaFiltrada[i].qhor_codigo_escala,
            qhor_codigo_horario: escalaFiltrada[i].qhor_codigo_horario,
            qhor_dia_semana: escalaFiltrada[i].qhor_dia_semana,
            qhor_descricao: escalaFiltrada[i].qhor_descricao,
            escala: escala,
          };

          listaEscalaAtual.push(obj);
        }

        setListaEscalas(listaEscalaAtual);

        setEscalaCompleta(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  function ExibeEscalas() {
    const colunas = [
      {
        name: "Escala",
        selector: (row) => row.qhor_codigo_escala,
        sortable: true,
        maxWidth: "15%",
        // omit: true,
      },
      {
        name: "Horário",
        selector: (row) => row.qhor_codigo_horario,
        sortable: true,
        maxWidth: "15%",
        // omit: true,
      },
      {
        name: "Dia da semana",
        selector: (row) => row.qhor_dia_semana,
        omit: true,
      },
      {
        name: "Horários",
        selector: (row) => row.qhor_descricao,
        sortable: true,
        // center: true,
      },
      {
        name: "Escala",
        sortable: true,
        cell: (row, index) => (
          <div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="inserir">Inserir apenas este horário</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="transparent"
                onClick={() => {
                  setEstaAberto(false);
                  setFieldValue(campo, row.qhor_descricao);
                  setFieldValue(
                    `${campo}_codigo_horario`,
                    row.qhor_codigo_horario
                  );
                  setFieldValue(
                    `${campo}_codigo_escala`,
                    row.qhor_codigo_escala
                  );
                }}
              >
                <MdCheck color="blue" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="inserir">Inserir escala completa</Tooltip>}
            >
              <Button
                size="sm"
                variant="transparent"
                onClick={() => {
                  setEstaAberto(false);

                  setFieldValue(
                    "admi_jornada_trab_seg",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEG"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_seg_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEG"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_seg_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEG"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_ter",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "TER"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_ter_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "TER"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_ter_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "TER"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_qua",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUA"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_qua_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUA"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_qua_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUA"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_qui",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUI"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_qui_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUI"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_qui_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "QUI"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_sex",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEX"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_sex_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEX"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_sex_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SEX"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_sab",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SAB"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_sab_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SAB"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_sab_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "SAB"
                    ).qhor_codigo_escala
                  );

                  setFieldValue(
                    "admi_jornada_trab_dom",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "DOM"
                    ).qhor_descricao
                  );
                  setFieldValue(
                    "admi_jornada_trab_dom_codigo_horario",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "DOM"
                    ).qhor_codigo_horario
                  );
                  setFieldValue(
                    "admi_jornada_trab_dom_codigo_escala",
                    row.escala.find(
                      (x) =>
                        x.qhor_codigo_escala == row.qhor_codigo_escala &&
                        x.qhor_dia_semana == "DOM"
                    ).qhor_codigo_escala
                  );
                }}
              >
                <MdCheck color="green" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Escala {row.qhor_codigo_escala} Detalhada
                  </Popover.Header>
                  <Popover.Body>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Dia</th>
                          <th>Descrição</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.escala.map((item) => {
                          return (
                            <tr>
                              <td>{item.qhor_dia_semana}</td>
                              <td>{item.qhor_descricao}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button size="sm" variant="transparent">
                <MdInfo color="orange" />
              </Button>
            </OverlayTrigger>
          </div>
        ),
        // center: true,
      },
      {
        name: "Minutos",
        selector: (row) => calcularTempoTotal(row.qhor_descricao),
        sortable: true,
        maxWidth: "80px",
        // center: true,
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    const tableDataEmpresas = {
      columns: colunas,
      data: listaEscalas,
      filterPlaceholder: "Filtrar",
      exportHeaders: true,
      print: false,
      export: false,
      filterDigit: 1,
    };

    return (
      <DataTableExtensions {...tableDataEmpresas}>
        <DataTable
          // onRowClicked={(row, index) => {
          //     console.log("row: ", row)
          //     console.log("Campo: ", campo)

          //     setEstaAberto(false)
          //     setFieldValue(campo, row.qhor_descricao);
          //     setFieldValue(`${campo}_codigo_horario`, row.qhor_codigo_horario)
          //     setFieldValue(`${campo}_codigo_escala`, row.qhor_codigo_escala)

          // }}
          dense
          striped={true}
          highlightOnHover={true}
          responsive={true}
          pointerOnHover={true}
          noDataComponent={"Nenhum Registro Encontrado =/"}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
        />
      </DataTableExtensions>
    );
  }

  return (
    <Modal show={estaAberto} centered dialogClassName="modal-50">
      <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
        Horários Válidos para {props.descricao} 
        <div
          onClick={() => {
            setEstaAberto(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <MdClose />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ExibeEscalas />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#E30613", border: 0 }}
          onClick={() => {
            setEstaAberto(false);
          }}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
