import { Row, Col, Button } from 'react-bootstrap'

export default function Header(props) {
    return (
        <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5, paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
            <Col sm={12} lg={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minHeight: 50 }}>
                {props.titulo}
            </Col>
            <Col sm={12} lg={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {props.children}
            </Col>
            <Row>
                <hr />
            </Row>
        </Row>
    )
}