import axios from 'axios';

import dayjs from "dayjs";
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form as FormReact, Modal, ProgressBar, Row, Stack } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { MdAdd, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { ErroField, InputFieldPadrao, InputFieldTextArea, SelectFieldPadrao } from '../../components/field';
import Footer from '../../components/footer';
import { ModalFuncionarios, ModalFuncionariosLote } from '../../components/modal';
import Navegacao from '../../components/navbar';
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';



export default function Acidente() {

    const { conexao, tipoAcesso } = useContext(GlobalContext)

    const formRef = useRef()
    const Navigate = useNavigate()
    const [modalConcluidoAberto, setModalConcluidoAberto] = useState(false)
    const [modalLoadingAberto, setModalLoadingAberto] = useState(false)
    const [mensagemLoading, setMensagemLoading] = useState(false)
    const [inputDiasSelecionado, setInputDiasSelecionado] = useState({})
    const [dataAtual, setDataAtual] = useState("")
    const [modalFuncionarios, setModalFuncionarios] = useState(false)
    const [modalFuncionariosLote, setModalFuncionariosLote] = useState(false)
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const [modalPrompt, setModalPrompt] = useState(false)
    const [promptTitulo, setPromptTitulo] = useState('')
    const [promptTipo, setPromptTipo] = useState(null)
    const [promptValor, setPromptValor] = useState(null)
    const [promptCampo, setPromptCampo] = useState(null)
    const [promptIndex, setPromptIndex] = useState(null)

    const [progresso] = useState(0)

    const [edicao, setEdicao] = useState(false)

    const urlParams = new URLSearchParams(window.location.search)

    const id_ferias = urlParams.get("id")

    const [lote, setLote] = useState(false)
    const [listaLote, setListaLote] = useState([])


    const [valoresPadraoForm, setValoresPadraoForm] = useState({
        empresa: localStorage.getItem("razao"),
        data: dayjs(new Date()).format("YYYY-MM-DD"),
        id_empregado: "",
        data_inicio: "",
        tipo_de_ferias: "",
        dias_adicionais: "",
        observacoes: ""
    })

    useEffect(() => {

        if (id_ferias) {
            setEdicao(true)
            getFerias()
        }
        GetDataAtual()

    }, [])

    useEffect(() => {
        formRef.current.setFieldValue('empregado', '');
        formRef.current.setFieldValue('id_empregado', '');
        setListaLote([])
    }, [lote])


    const GetDataAtual = () => {
        const now = dayjs.utc().format("YYYY-MM-DD")
        setDataAtual(now)
    }

    // const schemaFerias = Yup.object().shape({
    //     empresa: Yup.string().required('Campo Obrigatório'),
    //     data_inicio: Yup.date().required('Campo Obrigatório'),
    //     id_empregado: Yup.string().required('Campo Obrigatório'),
    //     tipo_de_ferias: Yup.string().required('Campo Obrigatório'),
    //     dias_adicionais: Yup.string().when('tipo_de_ferias', {
    //         is: ((val) => val === 'Férias Fracionadas' || val === 'Férias Coletivas'),
    //         then: Yup.string().required('Campo Obrigatório quando tipo de férias selecionado seja Férias Fracionadas ou Férias Coletivas')
    //     }),
    // })

    const schemaFerias = Yup.object().shape({
        empresa: Yup.string().required('Campo Obrigatório'),
        data_inicio: Yup.date().required('Campo Obrigatório'),
        id_empregado: Yup.string().required('Campo Obrigatório'),
        tipo_de_ferias: Yup.string().required('Campo Obrigatório'),
        dias_adicionais: Yup.number().min(0, 'O número de dias adicionais não pode ser menor que zero').when('tipo_de_ferias', {
            is: 'Férias Coletivas',
            then: Yup.number().min(10, 'O número mínimo de dias adicionais para Férias Coletivas é 10').required('Campo Obrigatório quando tipo de férias selecionado seja Férias Coletivas')
        }).when('tipo_de_ferias', {
            is: 'Férias Fracionadas',
            then: Yup.number().min(5, 'O número mínimo de dias adicionais para Férias Fracionadas é 5').required('Campo Obrigatório quando tipo de férias selecionado seja Férias Fracionadas')
        }),
    })


    const enviarSolicitacao = async (values, id) => {
        const id_empresa = tipoAcesso === "USUARIO" ? localStorage.getItem('id_empresa') : "0";
        const id_operador = tipoAcesso !== "USUARIO" ? localStorage.getItem('id_usuario') : "0";
        const id_usuario = localStorage.getItem('id_usuario');
        const quantidade_de_dias = `${values.qtd_dias}${inputDiasSelecionado.value}`;

        setMensagemLoading("Enviando pedido");
        setModalLoadingAberto(true);

        try {
            const response = await axios.post(`${conexao.endereco}/solicitacoes/incluir`, {
                empresa: localStorage.getItem("id_empresa"),
                estabelecimento: localStorage.getItem("estabelecimento"),
                usuario: id_usuario,
                operador: id_operador,
                categoria: "16",
                prioridade: "7",
                funcionario: values.empregado,
                tipo: "FERIAS",
                assunto: `Solicitação de Férias ${values.empregado}`,
                conteudo: 'Solicitação de Férias do Colaborador ' + values.empregado,
            }, { headers: { token: localStorage.getItem('Token') } });

            setModalLoadingAberto(false);
            if (response.status === 201) {
                setModalConcluidoAberto(true);
                return response.data.ticket;
            } else {
                return null
            }
        } catch (error) {
            console.log(error);
            return null
        }

    };

    const enviarFerias = async (values) => {

        setLoading(true)
        setMsgLoading("Enviando solicitação")

        // const idTicket = await enviarSolicitacao(values)
        const id_usuario = localStorage.getItem('id_usuario');

        await axios.post(`${conexao.endereco}/ferias/incluir`, {
            empresa: localStorage.getItem("id_empresa"),
            estab: localStorage.getItem("estabelecimento"),
            func: values.id_empregado,
            data_inicio: values.data_inicio,
            tipo: values.tipo_de_ferias,
            dias_adicionais: values.dias_adicionais,
            observacoes: values.observacoes,
            lote: listaLote,
            usuario: id_usuario,
            funcionario: values.empregado,

        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {

                setLoading(false)
                if (response.status === 201) {
                    setModalConcluidoAberto(true);
                } else {
                    alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getFerias = async (values) => {

        setLoading(true)
        setMsgLoading("buscando dados")

        axios.post(`${conexao.endereco}/ferias/listar`, {
            ticket: id_ferias
        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {

                setLoading(false)
                if (response.status === 200) {

                    console.log('Dados Loading: ', response.data[0])

                    setValoresPadraoForm({
                        empresa: response.data[0].empr_razao,
                        numero_empresa: response.data[0].empr_id,
                        id_empregado: response.data[0].func_id,
                        empregado: response.data[0].func_nome,
                        data_inicio: moment(response.data[0].feri_data_inicio).format('YYYY-MM-DD'),
                        tipo_de_ferias: response.data[0].feri_tipo,
                        dias_adicionais: response.data[0].feri_dias_adicionais,
                        observacoes: response.data[0].feri_obs

                    })

                    if (response.data[0].feri_lote) {

                        setListaLote(JSON.parse(response.data[0].feri_lote))
                        formRef.current.setFieldValue('empregado', `Lote de ${JSON.parse(response.data[0].feri_lote).length} Funcionários`)
                    }

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Navegacao />


            <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', paddingBottom: "4rem" }}>
                <Col sm={12} lg={12} style={{ paddingTop: 20 }}>
                    <Row>
                        <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                            <h3>Solicitação de Férias</h3>
                        </Col>
                        <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>

                            <Button
                                style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                size='sm'
                                type="button"
                                onClick={() => {
                                    Navigate('/departamento-pessoal')
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                size='sm'
                                type="submit"
                                form="formAcidente"
                            >
                                Salvar
                            </Button>
                        </Col>
                        <Row >
                            <hr />
                        </Row>
                    </Row>
                </Col>
                <Row>
                    <Col>

                        <Formik
                            enableReinitialize={true}
                            onSubmit={async (values, actions) => {
                                console.log('Dados para Atualizacao', values)
                                await enviarFerias(values)
                                // await enviarSolicitacao(values)
                                // window.location.reload()
                            }}
                            initialValues={{
                                ...valoresPadraoForm
                            }}
                            validationSchema={schemaFerias}
                            innerRef={formRef}
                            render={({ values, errors, touched, isValid, setFieldValue }) => (
                                <Form id="formAcidente" autoComplete='off'>
                                    <Stack gap={3}>
                                        <Row>
                                            <Col sm={12} lg={8} >
                                                <label htmlFor="empresa" className="form-label">Empresa</label>
                                                <Field id="empresa" name="empresa" type="text" className="form-control" disabled style={{ textTransform: 'capitalize' }} />
                                                <ErroField campo="empresa" />
                                            </Col>


                                            <Col sm={12} lg={4}>
                                                <label htmlFor="data" className="form-label">Data</label>
                                                <Field id="data" name="data" type="date" className="form-control" disabled style={{ textTransform: 'none' }} />
                                                <ErroField campo="data" />
                                            </Col>
                                        </Row>

                                        <Row>


                                            <Col sm={12} lg={3}>
                                                <InputFieldPadrao campo="data_inicio" tipo="date" texto="Data de inicio" disabled={lote || edicao} />
                                                <ErroField campo="data_inicio" />
                                            </Col>
                                            <Col sm={12} lg={formRef?.current?.values?.tipo_de_ferias === 'Férias Fracionadas' || formRef?.current?.values?.tipo_de_ferias === 'Férias Coletivas' ? 6 : 9}>
                                                <SelectFieldPadrao texto="Tipo de Férias" campo="tipo_de_ferias" disabled={lote || edicao}>
                                                    <option value="" selected disabled hidden>Selecione...</option>
                                                    <option>30 Dias de Férias</option>
                                                    <option>20 Dias de Férias + 10 Dias de Abono</option>
                                                    <option>Férias Fracionadas</option>
                                                    <option>Férias Coletivas</option>
                                                    <option hidden>Lote</option>
                                                </SelectFieldPadrao>
                                                <ErroField campo="tipo_de_ferias" />
                                            </Col>

                                            {(formRef?.current?.values?.tipo_de_ferias === 'Férias Fracionadas' || formRef?.current?.values?.tipo_de_ferias === 'Férias Coletivas') &&
                                                <Col lg={3}>
                                                    <InputFieldPadrao
                                                        campo="dias_adicionais"
                                                        tipo="number"
                                                        texto={
                                                            <span>Quantidade de Dias
                                                                {formRef?.current?.values?.tipo_de_ferias === 'Férias Coletivas' && <sup style={{ color: "brown", marginLeft: 5 }}>Mínimo 10 dias</sup>}
                                                                {formRef?.current?.values?.tipo_de_ferias === 'Férias Fracionadas' && <sup style={{ color: "brown", marginLeft: 5 }}>Mínimo 5 dias</sup>}
                                                            </span>}
                                                        disabled={lote || edicao}
                                                    />
                                                    <ErroField campo="dias_adicionais" />
                                                </Col>
                                            }
                                        </Row>
                                        <Row>

                                            <Col sm={12} lg={12}>
                                                <InputFieldPadrao campo="empregado" tipo="text" disabled texto={
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        Empregado

                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {

                                                                if (formRef?.current?.values?.data_inicio && formRef?.current?.values?.tipo_de_ferias) {

                                                                    !lote ? setModalFuncionarios(true) : setModalFuncionariosLote(true)


                                                                } else {
                                                                    alert('Preencha os campos Data de Inicio e Tipo de Férias antes de selecionar o(s) Funcionário(s)')
                                                                }
                                                                // setCriarCargo(false)
                                                                // setIsOpenModalSalario(true)
                                                            }
                                                            }
                                                        >
                                                            {!lote ? < BsSearch style={{ marginLeft: 10, fontSize: 16, color: "#E30613" }} /> : < MdAdd style={{ marginLeft: 10, fontSize: 16, color: "#E30613" }} />}

                                                        </div>
                                                        <label style={{ marginLeft: 40 }}>
                                                            <input type="checkbox" name="lote" className="form-check-input" value={lote} onChange={() => setLote(!lote)} />
                                                            <span style={{ marginLeft: 5 }}>Lote</span>
                                                        </label>

                                                    </div>
                                                } />
                                                <ErroField campo="id_empregado" />
                                            </Col>
                                        </Row>

                                        {listaLote.map((item, index) => (
                                            <Row key={index}>
                                                <strong>{item.nome}</strong>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <span>Data de inicio: {moment(item.data_inicio).format('DD/MM/YYYY')}</span>
                                                    {!edicao &&
                                                        <MdEdit
                                                            style={{ marginLeft: 10, fontSize: 16, color: "#E30613", cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPromptTitulo(`Informe a Data de Inicio para o Funcionário: ${item.nome}`);
                                                                setPromptValor(item.data_inicio);
                                                                setPromptTipo('data');
                                                                setPromptCampo('data_inicio');
                                                                setPromptIndex(index);
                                                                setModalPrompt(true);

                                                            }}
                                                        />
                                                    }
                                                </div>
                                                <span>Tipo de Férias: {item.tipo_de_ferias}
                                                    {!edicao &&
                                                        <MdEdit
                                                            style={{ marginLeft: 10, fontSize: 16, color: "#E30613", cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPromptTitulo(`Informe o Tipo de Férias para o Funcionário: ${item.nome}`);
                                                                setPromptValor(item.tipo_de_ferias);
                                                                setPromptTipo('lista');
                                                                setPromptCampo('tipo_de_ferias');
                                                                setPromptIndex(index);
                                                                setModalPrompt(true);

                                                            }}
                                                        />
                                                    }
                                                </span>
                                                <span>Quantidade de Dias: {item.dias_adicionais}
                                                    {!edicao &&
                                                        <MdEdit
                                                            style={{ marginLeft: 10, fontSize: 16, color: "#E30613", cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPromptTitulo(`Informe os Dias Adicionais para o Funcionário: ${item.nome}`);
                                                                setPromptValor(item.dias_adicionais);
                                                                setPromptTipo('dias');
                                                                setPromptCampo('dias_adicionais');
                                                                setPromptIndex(index);
                                                                setModalPrompt(true);

                                                            }}
                                                        />
                                                    }
                                                </span>
                                            </Row>

                                        ))}

                                        <Row>
                                            <Col>
                                                <InputFieldTextArea linhas={6} campo="observacoes" tipo="text" texto="Observação" disabled={edicao} />
                                                <ErroField campo="observacoes" />
                                            </Col>
                                        </Row>


                                    </Stack>

                                </Form>

                            )}
                        >
                        </Formik>
                    </Col>
                </Row>
            </Container>

            <Footer />



            <Modal show={modalLoadingAberto} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={'lazy'}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                            {mensagemLoading}
                        </div>

                        {progresso > 0 &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', padding: 10 }}>
                                <ProgressBar
                                    animated={true}
                                    now={progresso}
                                    variant="success"
                                    label={`${progresso}%`}
                                />
                            </div>
                        }
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={modalConcluidoAberto} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Concluido
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    O pedido de férias foi enviado
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        window.location.reload()
                    }}>OK</Button>
                </Modal.Footer>
            </Modal>

            <ModalFuncionarios
                isOpen={modalFuncionarios}
                setIsOpen={setModalFuncionarios}
                rowClick={(row) => {
                    formRef.current.setFieldValue('empregado', row.func_nome);
                    formRef.current.setFieldValue('id_empregado', row.func_id_contrato);
                    // alert(JSON.stringify(row))
                }}
            />
            <ModalFuncionariosLote
                isOpen={modalFuncionariosLote}
                setIsOpen={setModalFuncionariosLote}
                confirmar={(data) => {
                    setModalFuncionariosLote(false)
                    formRef.current.setFieldValue('empregado', `Lote de ${data.length} Funcionários`);
                    formRef.current.setFieldValue('id_empregado', 0);
                    formRef.current.setFieldValue('tipo_de_ferias', 'Lote');
                    formRef.current.setFieldValue('data_inicio', new Date().toISOString().slice(0, 10));
                    let lista = []
                    data.forEach(item => {
                        let obj = {
                            id: item.func_id,
                            id_empresa: item.func_id_empresa,
                            id_estab: item.func_id_estab,
                            id_contrato: item.func_id_contrato,
                            id_pessoa: item.func_id_pessoa,
                            cpf: item.func_cpf,
                            nome: item.func_nome,
                            data_inicio: formRef.current.values.data_inicio,
                            tipo_de_ferias: formRef.current.values.tipo_de_ferias,
                            dias_adicionais: formRef.current.values.dias_adicionais,
                            status: 'pendente'
                        }
                        lista.push(obj)
                    })
                    setListaLote(lista)
                }}
            />

            <Modal show={modalPrompt} centered>
                <Modal.Header style={{ color: '#E30613' }}>
                    {promptTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {promptTipo == 'data' &&
                        <FormReact.Group>
                            {/* <FormReact.Label>Data</FormReact.Label> */}
                            <FormReact.Control type="date" value={promptValor} onChange={(e) => { setPromptValor(e.target.value) }} autoFocus />
                        </FormReact.Group>
                    }
                    {promptTipo == 'dias' &&
                        <FormReact.Group>
                            {/* <FormReact.Label>Data</FormReact.Label> */}
                            <FormReact.Control type="number" value={promptValor} onChange={(e) => { setPromptValor(e.target.value) }} autoFocus />
                        </FormReact.Group>
                    }
                    {promptTipo == 'lista' &&


                        <FormReact.Select aria-label="tipo_ferias" value={promptValor} onChange={(e) => { setPromptValor(e.target.value) }}>
                            <option value="" selected disabled hidden>Selecione...</option>
                            <option>30 Dias de Férias</option>
                            <option>20 Dias de Férias + 10 Dias de Abono</option>
                            <option>Férias Fracionadas</option>
                            <option>Férias Coletivas</option>
                            <option hidden>Lote</option>
                        </FormReact.Select>


                    }
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        let lista = [...listaLote];
                        if (promptCampo == 'data_inicio') {
                            lista[promptIndex].data_inicio = promptValor
                        }
                        if (promptCampo == 'dias_adicionais') {

                            if (+promptValor <= 0) {
                                alert('Informe um número maior que zero')
                                return
                            }

                            if (lista[promptIndex].tipo_de_ferias == 'Férias Coletivas' && +promptValor < 10) {
                                alert('O número mínimo de dias adicionais para Férias Coletivas é 10')
                                return
                            }

                            if (lista[promptIndex].tipo_de_ferias == 'Férias Fracionadas' && +promptValor < 5) {
                                alert('O número mínimo de dias adicionais para Férias Coletivas é 5')
                                return
                            }

                            lista[promptIndex].dias_adicionais = promptValor
                        }
                        if (promptCampo == 'tipo_de_ferias') {
                            lista[promptIndex].tipo_de_ferias = promptValor
                        }
                        setListaLote(lista)
                        setModalPrompt(false)
                    }}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}