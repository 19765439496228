import React, { useState, useContext, useLayoutEffect } from "react";
import { GlobalContext } from '../../contexts/global'
import { useNavigate, useParams } from 'react-router-dom'
import { Table, Stack, Container } from 'react-bootstrap'
import { decode as base64_decode } from 'base-64';
import axios from "axios";
import { MdOutlineAttachFile } from "react-icons/md";
import { AdmissaoPDF } from "../../Relatorios/Clientes/AdmissaoPDF";
import { AiOutlineFilePdf } from "react-icons/ai";


const transformDiasTrabalhados = {
    admi_jornada_trab_seg: "Segunda-feira",
    admi_jornada_trab_ter: "Terça-feira",
    admi_jornada_trab_qua: "Quarta-feira",
    admi_jornada_trab_qui: "Quinta-feira",
    admi_jornada_trab_sex: "Sexta-feira",
    admi_jornada_trab_sab: "Sabado",
    admi_jornada_trab_sabcomp: "Sabado compensado",
    admi_jornada_trab_dom: "Domingo",
}


const transformAdicionais = {
    admi_adicionais_40: "Adicional 40%",
    admi_adicionais_quebra_caixa: "Quebra de Caixa",
    admi_adicionais_insalubridade: "Insalubridade",
    admi_adicionais_periculosidade: "Periculosidade",
    admi_adicionais_vale_transporte: "Vale Transporte",
}

const transformAutorizacoes = {
    admi_autoriz_sem_curso_capacitacao: "Admissão sem os cursos de capacitação",
    admi_autoriz_sem_exame_admissional: "Admissão sem exame toxicologico",
    admi_autoriz_sem_exame_toxicologico: "Admissão sem exame admissional",
}

const transformDocumentos = {
    admi_dcto_atestado_medico_admissional: "",
    admi_dcto_certificado_reservista: "",
    admi_dcto_cnh: "",
    admi_dcto_comprovante_residencia: "",
    admi_dcto_cpf: "",
    admi_dcto_ctps: "",
    admi_dcto_exame_toxicologico: "",
    admi_dcto_foto3x4: "",
    admi_dcto_rg: "",
    admi_dcto_titulo_eleitor: ""
}



function DetalhamentoAdmissao() {

    const Navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')
    const [listaJornadas, setListaJornadas] = useState([])

    const { conexao } = useContext(GlobalContext)

    const [dadosAdmissao, setDadosAdmissao] = useState({})
    const [dados, setDados] = useState({})
    const [listaFilhos, setListaFilhos] = useState([])
    const [diasTrabalhados, setDiasTrabalhados] = useState([])
    const [adicionais, setAdicionais] = useState([])
    const [autorizacoes, setAutorizacoes] = useState([])
    const [jornadasFormatadas, setJornadasFormatadas] = useState([])

    const { id } = useParams()

    const pegarParteArray = (regex, array, arrayTransform) => {

        const keys = Object.keys(array)
        const usedRegex = new RegExp(regex)
        const newArray = [];

        keys.map((key) => {
            if (usedRegex.exec(key)) {
                if (array[usedRegex.exec(key)[0]]) {
                    newArray.push(arrayTransform[key])
                }
            }
        })

        return newArray;
    }


    async function getAdmissao(id) {

        setMsgLoading('Obtendo Admissão')
        setLoading(true)

        axios.post(conexao.endereco + '/admissoes/individual',
            {
                identificacao: id
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(async function (response) {

                await getJornadas(id)
                setDadosAdmissao(response.data)
                setLoading(false)

                const newDiasTrabalhados = pegarParteArray(/admi_jornada_trab\w+/, response.data, transformDiasTrabalhados)
                const newAdicionais = pegarParteArray(/admi_adicionais\w+/, response.data, transformAdicionais)
                const newAutorizacoes = pegarParteArray(/admi_autoriz_sem\w+/, response.data, transformAutorizacoes)

                setAdicionais(newAdicionais)
                setDiasTrabalhados(newDiasTrabalhados)
                setAutorizacoes(newAutorizacoes)

                const filhos = await getFilhos();


                setDados({
                    dadosOriginais: response.data,
                    adicionaisFormatados: newAdicionais,
                    diasTrabalhadosFormatados: newDiasTrabalhados,
                    autorizacoesFormatadas: newAutorizacoes,
                    filhos: filhos,
                })
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    async function getFilhos() {

        setMsgLoading('Listando Filhos')
        setLoading(true)
        let filhos;

        await axios.post(conexao.endereco + '/admissoes/filhos/listar',
            {
                id: base64_decode(id)
            }, {
            headers: {
                token: localStorage.getItem('Token'),
                "Content-Type": "application/json"
            }

        })

            .then(function (response) {
                setListaFilhos(response.data)
                setLoading(false)
                filhos = response.data
            })

            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

        return filhos
    }



    async function getJornadas() {

        setMsgLoading('Listando Jornadas de Trabalho')
        setLoading(true)


        await axios.post(conexao.endereco + '/admissoes/jornadas/listar',
            {
                id: base64_decode(id),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(async function (response) {
                setListaJornadas(response.data)
                setLoading(false)
                const jornadas = await FormatarJornadas(response.data)
                return jornadas
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function FormatarJornadas(jornadas) {
        const novoArray = [];

        await jornadas.map(async (jornada) => {
            const jornadasSplit = jornada.adjo_dias_semana.split(",")

            if (jornadasSplit.length > 1) {
                await jornadasSplit.map(async (dia) => {
                    novoArray.push({
                        adjo_id: jornada.adjo_id,
                        adjo_id_admi: jornada.adjo_id_admi,
                        adjo_dias_semana: dia,
                        adjo_horarios: jornada.adjo_horarios,
                        adjo_observacoes: jornada.adjo_observacoes
                    })
                })
            }
            else {
                novoArray.push({
                    adjo_id: jornada.adjo_id,
                    adjo_id_admi: jornada.adjo_id_admi,
                    adjo_dias_semana: jornada.adjo_dias_semana,
                    adjo_horarios: jornada.adjo_horarios,
                    adjo_observacoes: jornada.adjo_observacoes
                })
            }
        })


        setJornadasFormatadas(novoArray)
        return novoArray
    }

    useLayoutEffect(() => {
        getAdmissao(base64_decode(id))
    }, [])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: "0 0.5rem"
                }}
            >

                <div style={{
                    width: "58rem",
                    marginTop: '2rem',
                    marginBottom: "10rem"
                }}>
                    <div
                        style={{
                            display: "flex",
                            width: "8rem",
                            height: "2rem",
                            backgroundColor: "#B22222",
                            color: "#FFFFFF",
                            marginLeft: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 3,
                            cursor: "pointer"
                        }}
                        onClick={() => AdmissaoPDF(dados, jornadasFormatadas)}
                    >

                        <AiOutlineFilePdf style={{ marginRight: 10 }} />
                        <div> Baixar PDF  </div>

                    </div>


                    <Container
                        style={{
                            backgroundColor: '#FFFFFF',
                            maxWidth: "58rem",
                            marginTop: '0.5rem',
                            borderRadius: "5px",
                            paddingLeft: "2rem",
                            paddingTop: "2rem",
                            paddingBottom: "2rem"


                        }}
                    >

                        <h3 className="text-center mb-0" > DETALHAMENTO DA ADMISSÃO </h3>
                        <p className="text-center mt-1"  > {`Data de admissão: ${dadosAdmissao.admi_data_admissao}`}</p>

                        <Stack direction="vertical" gap={1}>
                            <div style={{
                                // marginTop: "2rem",

                            }}>
                                <p style={{
                                    opacity: "50%",
                                    marginBottom: "0px",
                                }}
                                > NOME COMPLETO </p>


                                <p className="mt-0 " > {dadosAdmissao.admi_nome} </p>
                            </div>



                            <div
                                style={{
                                    display: 'flex',
                                    //  marginTop: "1rem"
                                }}>
                                <div style={{
                                    marginRight: "2rem"
                                }}>
                                    <p className="opacity-50 mb-0"> CPF </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_cpf} </p>
                                </div>

                                <div>
                                    <p className="opacity-50 mb-0"> DATA NASCIMENTO </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_data_nascimento}  </p>
                                </div>
                            </div>



                            <div
                                style={{
                                    display: 'flex',
                                    //  marginTop: "0.5rem"
                                }}>

                                <div style={{
                                    marginRight: "2rem"
                                }} >
                                    <p className="opacity-50 mb-0">  NATURALIDADE </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_data_nascimento} </p>
                                </div>

                                <div >
                                    <p className="opacity-50 mb-0">  Nº PIS </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_pis} </p>
                                </div>
                            </div>


                            <div style={{
                                // marginTop: "0.5rem"
                            }}>
                                <p className="opacity-50 mb-0"> FILIAÇÃO </p>
                                <p className="mt-0 mb-0" > {dadosAdmissao.admi_nome_mae} </p>
                                <p className="mt-0" > {dadosAdmissao.admi_nome_pai} </p>

                            </div>



                            <div
                                style={{
                                    display: 'flex',
                                    //    marginTop: "0.5rem"
                                }}>
                                <div style={{
                                    marginRight: "2rem"
                                }}>
                                    <p className="opacity-50 mb-0"> CIDADE </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_end_cidade} </p>
                                </div>

                                <div>
                                    <p className="opacity-50 mb-0"> UF </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_end_uf} </p>
                                </div>
                            </div>



                            <div
                                style={{
                                    display: 'flex',
                                    //    marginTop: "0.5rem"
                                }}>
                                <div style={{
                                    marginRight: "2rem"
                                }}>
                                    <p className="opacity-50 mb-0"> BAIRRO </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_end_bairro} </p>
                                </div>

                                <div>
                                    <p className="opacity-50 mb-0"> CEP </p>

                                    <p className="mt-0 " > {dadosAdmissao.admi_end_cep} </p>
                                </div>
                            </div>



                            <div style={{
                                //  marginTop: "0.5rem"
                            }}>
                                <p className="opacity-50 mb-0"> ENDEREÇO </p>

                                <div>
                                    <p> {`${dadosAdmissao.admi_end_endereco}, ${dadosAdmissao.admi_end_numero}`} </p>
                                </div>
                            </div>




                            <div style={{
                                //  marginTop: "0.5rem"
                            }}>
                                <div className="d-flex">
                                    <div style={{
                                        marginRight: "3rem"
                                    }}>
                                        <p className="opacity-50 mb-0" > CARGO </p>
                                        <p> {dadosAdmissao.admi_cargo} </p>
                                    </div>

                                    <div>
                                        <p className="opacity-50 mb-0" > SALARIO </p>
                                        <p> {`R$${dadosAdmissao.admi_salario}`}  </p>
                                    </div>
                                </div>



                                {adicionais && adicionais.length > 0 && (
                                    <div style={{
                                        //     marginTop: "0.5rem"
                                    }}>
                                        <p className="opacity-50 mb-0"> ADICIONAIS </p>

                                        <div style={{
                                            paddingLeft: "1rem",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr"
                                        }}
                                        >
                                            {
                                                adicionais.map((adicional) => {
                                                    return (
                                                        <li className="mb-0" > {adicional}</li>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                )}

                            </div>

                            {
                                jornadasFormatadas && jornadasFormatadas.length > 0 && (
                                    <div style={{
                                        marginTop: "1rem"
                                    }}>
                                        <p className="opacity-50 mb-0"> JORNADA DE TRABALHO </p>
                                        <Table responsive className="mb-0" >
                                            <thead>
                                                <tr className="opacity-50" >
                                                    <th></th>
                                                    <th > Dia</th>
                                                    <th > Horários</th>
                                                    <th >Observação</th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {
                                                    jornadasFormatadas.map((jornada) => {
                                                        return (
                                                            <tr key={Math.random()} className="mt-0 mb-0">
                                                                <td className="border-0">•</td>
                                                                <td className="border-0" >{jornada.adjo_dias_semana}</td>
                                                                <td className="border-0">{jornada.adjo_horarios}</td>
                                                                <td className="border-0">{jornada.adjo_observacoes ? jornada.adjo_observacoes : "Nenhuma observação"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                )

                            }

                            {
                                autorizacoes && autorizacoes.length > 0 && (
                                    <div style={{
                                        marginTop: "1rem"
                                    }}>
                                        <p className="opacity-50 mb-0"> AUTORIZAÇÕES </p>

                                        <div style={{
                                            paddingLeft: "1rem",
                                        }}
                                        >

                                            {
                                                autorizacoes.map((autorizacao) => {
                                                    return (
                                                        <li className="mb-0" > {autorizacao} </li>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                )

                            }

                            <div style={{
                                marginTop: "1rem",
                            }}>
                                <p className="opacity-50 mb-0"> CONTRATO DE EXPERIÊNCIA </p>

                                <div style={{
                                    marginLeft: "1rem"
                                }}>
                                    <li className="m-0">{`Primeiro período: ${dadosAdmissao.admi_experiencia_p1} `}</li>
                                    <li>{`Segundo período: ${dadosAdmissao.admi_experiencia_p2}`}</li>
                                </div>


                            </div>

                            <div style={{
                                marginTop: "1rem",
                            }}>
                                <p className="opacity-50 mb-0"> DOCUMENTAÇÃO </p>


                                <div style={{
                                    paddingLeft: "1rem",
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
                                    gridAutoRows: "auto"
                                }}
                                >
                                    {dadosAdmissao.admi_dcto_foto3x4 &&
                                        <a href={dadosAdmissao.admi_dcto_foto3x4} > Foto 3x4 <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_ctps &&
                                        <a href={dadosAdmissao.admi_dcto_ctps} > Carteira de Trabalho (CTPS) <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_rg &&
                                        <a href={dadosAdmissao.admi_dcto_rg} > Carteira de Identidade (RG) <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_cpf &&
                                        <a href={dadosAdmissao.admi_dcto_cpf} > CPF <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_titulo_eleitor &&
                                        <a href={dadosAdmissao.admi_dcto_titulo_eleitor} > Titulo de Eleitor <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_cnh &&
                                        <a href={dadosAdmissao.admi_dcto_cnh} > Carteira de Motorista (CNH) <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_comprovante_residencia &&
                                        <a href={dadosAdmissao.admi_dcto_comprovante_residencia} > Comprovante de Residência <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_certificado_reservista &&
                                        <a href={dadosAdmissao.admi_dcto_certificado_reservista} > Certificado de Reservista <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_atestado_medico_admissional &&
                                        <a href={dadosAdmissao.admi_dcto_atestado_medico_admissional} > Atestado Médico Admissional <MdOutlineAttachFile /> </a>
                                    }
                                    {dadosAdmissao.admi_dcto_exame_toxicologico &&
                                        <a href={dadosAdmissao.admi_dcto_exame_toxicologico} > Exame Toxicológico (Motoristas) <MdOutlineAttachFile /> </a>
                                    }
                                </div>
                            </div>

                            {
                                listaFilhos && listaFilhos.length > 0 && (
                                    <div style={{
                                        marginTop: "1rem",
                                    }}>
                                        <p className="opacity-50 mb-0"> DEPENDENTES </p>

                                        {listaFilhos.map((filho) => {
                                            return (
                                                <p className="mb-0"> {filho.adfi_nome}</p>
                                            )
                                        })}


                                    </div>
                                )
                            }
                        </Stack>
                    </Container>
                </div>


            </div >
        </>
    )
}

export default DetalhamentoAdmissao;