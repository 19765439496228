import axios from "axios";

import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form as FormReact,
  Modal,
  ProgressBar,
  Row,
  Stack
} from "react-bootstrap";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import {
  ErroField,
  InputFieldPadrao,
  InputFieldTextArea
} from "../../components/field";
import Footer from "../../components/footer";
import { ModalFuncionarios } from "../../components/modal";
import Navegacao from "../../components/navbar";
import { GlobalContext } from "../../contexts/global";
import LogoArquivo from "../../images/logo.svg";

export default function Folha_mensal() {
  const { conexao, tipoAcesso } = useContext(GlobalContext);

  const formRef = useRef();
  const Navigate = useNavigate();
  const [modalConcluidoAberto, setModalConcluidoAberto] = useState(false);
  const [modalLoadingAberto, setModalLoadingAberto] = useState(false);
  const [mensagemLoading, setMensagemLoading] = useState(false);
  const [inputDiasSelecionado, setInputDiasSelecionado] = useState({});
  const [dataAtual, setDataAtual] = useState("");
  const [modalFuncionarios, setModalFuncionarios] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");

  const [progresso] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);

  const empresa = urlParams.get("empresa");
  const estab = urlParams.get("estab");
  const func = urlParams.get("func");
  const id_folha = urlParams.get("id");

  const [edicao, setEdicao] = useState(false);

  const [listaAnexos, setListaAnexos] = useState([]);

  const [valoresPadraoForm, setValoresPadraoForm] = useState({
    empresa: localStorage.getItem("razao"),
    data: dayjs(new Date()).format("YYYY-MM-DD"),
    id_empregado: "",
    competencia: "",
    planilha: "",
    observacoes: "",
  });

  useEffect(() => {
    if ((empresa && estab && func) || id_folha) {
      setEdicao(true);
      getFolha();
    }

    GetDataAtual();
  }, []);

  const GetDataAtual = () => {
    const now = dayjs.utc().format("YYYY-MM-DD");
    setDataAtual(now);
  };

  const schemaFerias = Yup.object().shape({
    empresa: Yup.string().required("Campo Obrigatório"),
    competencia: Yup.date().required("Campo Obrigatório"),
    planilha: Yup.string().required("Campo Obrigatório"),
  });

  function transformarNomeArquivo(nomeArquivo) {
    return nomeArquivo
      .replace(/[^\w\s\.]/gi, "")
      .replace(/\s+/g, "_")
      .toLowerCase();
  }

  async function enviarArquivo(values) {

    setMsgLoading("Enviando Arquivo " + values.name);
    setModalLoadingAberto(true);

    const formData = new FormData();
    formData.append("arquivo", values);

    await axios.post(conexao.endereco + "/arquivos/enviar",
      formData, { headers: { token: localStorage.getItem("Token") } })

      .then(function (response) {
        setModalLoadingAberto(false);

        let obj = {
          nome: transformarNomeArquivo(values.name),
          url: response.data.url,
        };

        listaAnexos.push(obj);
        console.log(response.data.url);
        return response.data.url;
      })
      .catch(function (error) {
        console.error(error);
        setModalLoadingAberto(false);
        return null;
      });
  }

  const enviarSolicitacao = async (values) => {
    const id_empresa = tipoAcesso === "USUARIO" ? localStorage.getItem('id_empresa') : "0";
    const id_operador = tipoAcesso !== "USUARIO" ? localStorage.getItem('id_usuario') : "0";
    const id_usuario = localStorage.getItem('id_usuario');

    setMensagemLoading("Enviando pedido");
    setModalLoadingAberto(true);

    try {
      const response = await axios.post(`${conexao.endereco}/solicitacoes/incluir`, {
        empresa: localStorage.getItem("id_empresa"),
        estabelecimento: localStorage.getItem("estabelecimento"),
        usuario: id_usuario,
        operador: id_operador,
        categoria: "16",
        funcionario: "0",
        prioridade: "7",
        tipo: "FOLHA_MENSAL",
        assunto: `Folha Mensal`,
        conteudo: "Folha Mensal",
      }, { headers: { token: localStorage.getItem('Token') } });

      setModalLoadingAberto(false);
      if (response.status === 201) {
        setModalConcluidoAberto(true);
        return response.data.ticket;
      } else {
        return null
      }
    } catch (error) {
      console.log(error);
      return null
    }

  };

  const enviarFolha = async (values) => {

    console.log("LISTA DE ANEXOS", listaAnexos);

    setLoading(true);
    setMsgLoading("Enviando solicitação");

    // const idTicket = await enviarSolicitacao(values);

    // if (!idTicket) {
    //   setLoading(false)
    //   alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
    //   return;
    // }

    await axios
      .post(
        `${conexao.endereco}/folha_mensal/incluir`,
        {
          empresa: localStorage.getItem("id_empresa"),
          estab: localStorage.getItem("estabelecimento"),
          competencia: values.competencia,
          arquivo: listaAnexos,
          observacoes: values.observacoes
        },
        { headers: { token: localStorage.getItem("Token") } }
      )
      .then((response) => {
        setLoading(false);

        if (response.status === 201) {
          setModalConcluidoAberto(true);
        } else {
          alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFolha = async (values) => {
    setLoading(true);
    setMsgLoading("buscando dados");

    axios
      .post(
        `${conexao.endereco}/folha_mensal/listar`,
        {
          ticket: id_folha,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data[0].folh_arquivo) {
            setListaAnexos(JSON.parse(response.data[0].folh_arquivo));
          }

          setValoresPadraoForm({
            empresa: response.data[0].empr_razao,
            numero_empresa: response.data[0].empr_id,
            data: new Date(response.data[0].folh_created)
              .toISOString()
              .slice(0, 10),
            competencia: response.data[0].folh_competencia,
            observacoes: response.data[0].folh_obs,
            // atestado: response.data[0].ates_arquivo
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Navegacao />

      <Container
        style={{
          display: "flex",
          backgroundColor: "#f5f5f5",
          width: "100%",
          flexDirection: "column",
          paddingBottom: "4rem",
        }}
      >
        <Col sm={12} lg={12} style={{ paddingTop: 20 }}>
          <Row>
            <Col
              sm={12}
              lg={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <h3>Envio de Planilha Folha Mensal</h3>
            </Col>
            <Col
              sm={12}
              lg={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              {!edicao && (
                <>
                  <Button
                    style={{
                      backgroundColor: "#E30613",
                      border: 0,
                      marginRight: 15,
                    }}
                    size="sm"
                    type="button"
                    onClick={() => {
                      Navigate('/departamento-pessoal')
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E30613",
                      border: 0,
                      marginRight: 15,
                    }}
                    size="sm"
                    type="submit"
                    form="formAcidente"
                  >
                    Salvar
                  </Button>
                </>
              )}
            </Col>
            <Row>
              <hr />
            </Row>
          </Row>
        </Col>
        <Row>
          <Col>
            <Formik
              enableReinitialize={true}
              onSubmit={async (values, actions) => {
                console.log("Dados para Atualizacao", values);

                const anexos = values.planilha;

                console.log("ANEXOS ON SUBMIT", anexos);

                if (anexos) {
                  for (let i = 0; i < anexos.length; i++) {
                    let urlAnexo = await enviarArquivo(anexos[i]);
                  }
                }

                console.log("Lista de Anexos", listaAnexos);

                await enviarFolha(values);

                // console.log('Dados para Atualizacao', values)
                // await enviarArquivo(values)
                // await enviarSolicitacao(values)
                // window.location.reload()
              }}
              initialValues={{
                ...valoresPadraoForm,
              }}
              validationSchema={schemaFerias}
              innerRef={formRef}
              render={({ values, errors, touched, isValid, setFieldValue }) => (
                <Form id="formAcidente" autoComplete="off">
                  <Stack gap={3}>
                    <Row>
                      <Col sm={12} lg={8}>
                        <label htmlFor="empresa" className="form-label">
                          Empresa
                        </label>
                        <Field
                          id="empresa"
                          name="empresa"
                          type="text"
                          className="form-control"
                          disabled
                          style={{ textTransform: "capitalize" }}
                        />
                        <ErroField campo="empresa" />
                      </Col>

                      <Col sm={12} lg={4}>
                        <label htmlFor="data" className="form-label">
                          Data
                        </label>
                        <Field
                          id="data"
                          name="data"
                          type="date"
                          className="form-control"
                          disabled
                          style={{ textTransform: "none" }}
                        />
                        <ErroField campo="data" />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} lg={4}>
                        <InputFieldPadrao
                          campo="competencia"
                          tipo="month"
                          texto="Competência"
                          disabled={edicao}
                        />
                        <ErroField campo="competencia" />
                      </Col>
                      <Col sm={12} lg={8}>
                        {!edicao && (
                          <FormReact.Group
                            controlId="formFileSm"
                            className="mb-3"
                          >
                            <FormReact.Label>Planilha</FormReact.Label>
                            <FormReact.Control
                              type="file"
                              multiple
                              value={formRef?.values?.planilha}
                              onChange={(e) =>
                                setFieldValue("planilha", e.target.files)
                              }
                              disabled={edicao}
                            />
                            <ErroField campo="planilha" />
                          </FormReact.Group>
                        )}
                        {/* {edicao &&
                                                    <>
                                                        <FormReact.Label>Atestado Médico</FormReact.Label><br />
                                                        <a style={{ textDecoration: 'none' }} href={formRef?.current?.values?.atestado} target="_blank"><sup>{formRef?.current?.values?.atestado}</sup></a><br />
                                                    </>

                                                } */}

                        {edicao && (
                          <div>
                            <h6>Planilha</h6>
                            {listaAnexos.map((item) => {
                              return (
                                <div>
                                  <sub>{item?.nome}</sub>
                                  <br />
                                  <a
                                    style={{ textDecoration: "none" }}
                                    href={item?.url}
                                    target="_blank"
                                  >
                                    <sup>{item?.url}</sup>
                                  </a>
                                  <br />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldTextArea
                          linhas={6}
                          campo="observacoes"
                          tipo="text"
                          texto="Observação"
                          disabled={edicao}
                        />
                        <ErroField campo="observacoes" />
                      </Col>
                    </Row>
                  </Stack>
                </Form>
              )}
            ></Formik>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal show={modalLoadingAberto} centered>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          <img
            src={LogoArquivo}
            width={"20%"}
            alt={"Imagem de Nuvem"}
            loading={"lazy"}
          ></img>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              {msgLoading}
            </div>

            {progresso > 0 && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  padding: 10,
                }}
              >
                <ProgressBar
                  animated={true}
                  now={progresso}
                  variant="success"
                  label={`${progresso}%`}
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={modalConcluidoAberto} centered>
        <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
          Concluido
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          O arquivo foi enviado com sucesso
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "#E30613", border: 0 }}
            onClick={() => {
              window.location.reload();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalFuncionarios
        isOpen={modalFuncionarios}
        setIsOpen={setModalFuncionarios}
        rowClick={(row) => {
          formRef.current.setFieldValue("empregado", row.func_nome);
          formRef.current.setFieldValue("id_empregado", row.func_id_contrato);
          // alert(JSON.stringify(row))
        }}
      />
    </>
  );
}
