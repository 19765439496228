import React from 'react'
import { Col, Card } from 'react-bootstrap'
import { RiUserSearchLine, RiFilePaperLine } from 'react-icons/ri'
import { BsArrowLeftRight, BsPiggyBank, BsFillPlusCircleFill, BsPersonLinesFill, BsBank, BsFileMedical } from 'react-icons/bs'
import { TbReportMoney } from "react-icons/tb";
import { FaPercentage, FaFileContract, FaHistory, FaUmbrellaBeach } from 'react-icons/fa'
import { GoQuestion } from 'react-icons/go'
import { IoHelpBuoy } from 'react-icons/io5'
import { BiImport, BiExport } from 'react-icons/bi'
import { AiOutlineUnorderedList, AiOutlineFileSearch, AiFillAlert, AiOutlineWarning } from 'react-icons/ai'
import { MdCancel, MdEvent, MdEventAvailable, MdOutlinePendingActions, MdAutoFixHigh, MdOutlinePriorityHigh, MdOutlineDesignServices, MdOutlineLibraryBooks, MdOutlineFolder } from 'react-icons/md'
import { HiOutlineReceiptTax, HiOutlineNewspaper, HiOutlineDocumentText } from 'react-icons/hi'
import { GiHandTruck } from 'react-icons/gi'

function RenderizaIcone(props) {

    switch (props.icone) {
        case 'GoQuestion':
            return (<GoQuestion color="#E30614" size="36" />)
        case 'BiImport':
            return (<BiImport color="#E30614" size="36" />)
        case 'BiExport':
            return (<BiExport color="#E30614" size="36" />)
        case 'AiOutlineUnorderedList':
            return (<AiOutlineUnorderedList color="#E30614" size="36" />)
        case 'MdCancel':
            return (<MdCancel color="#E30614" size="36" />)
        case 'FaFileContract':
            return (<FaFileContract color="#E30614" size="36" />)
        case 'FaPercentage':
            return (<FaPercentage color="#E30614" size="36" />)
        case 'BsArrowLeftRight':
            return (<BsArrowLeftRight color="#E30614" size="36" />)
        case 'AiOutlineFileSearch':
            return (<AiOutlineFileSearch color="#E30614" size="36" />)
        case 'RiUserSearchLine':
            return (<RiUserSearchLine color="#E30614" size="36" />)
        case 'MdEvent':
            return (<MdEvent color="#E30614" size="36" />)
        case 'MdEventAvailable':
            return (<MdEventAvailable color="#E30614" size="36" />)
        case 'HiOutlineReceiptTax':
            return (<HiOutlineReceiptTax color="#E30614" size="36" />)
        case 'FaHistory':
            return (<FaHistory color="#E30614" size="36" />)
        case 'MdOutlinePendingActions':
            return (<MdOutlinePendingActions color="#E30614" size="36" />)
        case 'BsPiggyBank':
            return (<BsPiggyBank color="#E30614" size="36" />)
        case 'IoHelpBuoy':
            return (<IoHelpBuoy color="#E30614" size="36" />)
        case 'BsFillPlusCircleFill':
            return (<BsFillPlusCircleFill color="#E30614" size="36" />)
        case 'AiFillAlert':
            return (<AiFillAlert color="#E30614" size="36" />)
        case 'MdAutoFixHigh':
            return (<MdAutoFixHigh color="#E30614" size="36" />)
        case 'BsPersonLinesFill':
            return (<BsPersonLinesFill color="#E30614" size="36" />)
        case 'MdOutlinePriorityHigh':
            return (<MdOutlinePriorityHigh color="#E30614" size="36" />)
        case 'BsBank':
            return (<BsBank color="#E30614" size="36" />)
        case "TbReportMoney":
            return (<TbReportMoney color="#E30614" size="36" />)
        case "AiOutlineWarning":
            return (<AiOutlineWarning color="#E30614" size="36" />)
        case "FaUmbrellaBeach":
            return (<FaUmbrellaBeach color="#E30614" size="36" />)
        case "GiHandTruck":
            return (<GiHandTruck color="#E30614" size="36" />)
        case "HiOutlineNewspaper":
            return (<HiOutlineNewspaper color="#E30614" size="36" />)
        case "RiFilePaperLine":
            return (<RiFilePaperLine color="#E30614" size="36" />)
        case "MdOutlineDesignServices":
            return (<MdOutlineDesignServices color="#E30614" size="36" />)
        case "MdOutlineLibraryBooks":
            return (<MdOutlineLibraryBooks color="#E30614" size="36" />)
        case "HiOutlineDocumentText":
            return (<HiOutlineDocumentText color="#E30614" size="36" />)
        case "BsFileMedical":
            return (<BsFileMedical color="#E30614" size="36" />)
        case 'MdOutlineFolder':
            return (<MdOutlineFolder color="#E30614" size="36" />)
        default:
            break
    }

}

{/* <GoQuestion color="#E30614" size="36" /> */ }
export default function CardIcone(props) {
    return (
        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={props.click}>
            <Card className="card-interno" >
                <Card.Body>
                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <RenderizaIcone icone={props.icone} />
                    </Card.Header>
                    <Card.Title style={{ textAlign: 'center', fontSize: 16 }}>{props.titulo}</Card.Title>
                </Card.Body>

            </Card>
        </Col>
    )
}

