import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap"


export function BotaoGrid(props) {
    return (
        <OverlayTrigger trigger="hover" placement={props.posicao} overlay={<Tooltip id={props.id}>{props.texto}</Tooltip>}>
            <button onClick={props.onClick} style={{ marginLeft: 5, cursor: 'pointer', border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}>
                {props.children}
            </button>
        </OverlayTrigger>

    )
}