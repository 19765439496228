import React, { useContext } from "react";
import { Container, Row } from 'react-bootstrap';
import Footer from "../components/footer";
import Navegacao from "../components/navbar";
import { GlobalContext } from '../contexts/global';
import LogoArquivo from '../images/logo.svg';

function NaoEncontrado() {
    
    // eslint-disable-next-line
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)
    return (
        <>
            <Navegacao />
''
            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', flexDirection: 'column', width: '30%' }}>
                    <Row style={{ padding: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={LogoArquivo}
                            width={'30%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Row>
                    <Row>
                        <hr />
                    </Row>
                    <Row style={{ textAlign: 'center' }}>
                        <h1>Página Não Encontrada</h1>
                    </Row>
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default NaoEncontrado;