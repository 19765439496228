import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global'
import Footer from "../../components/footer";
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Button, Modal, Dropdown } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import DataTable from "react-data-table-component";
import axios from "axios";
import { encode as base64_encode } from 'base-64';



function ConsultasUsuario() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    // eslint-disable-next-line
    const { usuario, tipoAcesso, conexao, licenca, statusSolicitacoes } = useContext(GlobalContext)

    const [solicitacoes, setSolicitacoes] = useState([])

    const [filtroAtual, setFiltroAtual] = useState('TODOS')

    useEffect(() => {
        ListarSolicitacoes(filtroAtual)
    }, [filtroAtual])


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    async function ListarSolicitacoes(status) {
        setMsgLoading('Listando Solicitacoes')
        setLoading(true)

        axios.post(conexao.endereco + '/consultas/listar',
            {
                "status": status,
                "usuario": localStorage.getItem('id_usuario')
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setSolicitacoes(response.data)
                console.log(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    function RenderizaSolicitacoes() {

        const colunas = [
            {
                name: 'Data da Consulta',
                selector: row => row.data_cadastro,
                sortable: true,
                maxWidth: '200px',
            },
            {
                name: 'Assunto',
                selector: row => row.cons_assunto,
                sortable: true,
            },
            {
                name: 'Situação',
                selector: row => row.cons_status,
                sortable: true,
                maxWidth: '250px',

            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (

            <>
                <Row style={{ marginTop: 20, paddingBottom: 80, textAlign: 'center' }}>
                    <Col>
                        <DataTable
                            columns={colunas}
                            data={solicitacoes}
                            onRowClicked={(row, index) => {
                                Navigate(`/mais-opcoes/consultas/detalhe/${base64_encode(row.cons_id)}`, { state: { dados: row } })
                            }}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            pointerOnHover={true}
                            noDataComponent={"Nenhum Registro Encontrado =/"}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>

                    <Row sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25, }}>
                        <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                            <h3>Minhas Consultas</h3>
                        </Col>
                        <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0 }}
                                size='sm'
                                onClick={() => {
                                    console.log(JSON.stringify(solicitacoes))
                                }}
                            >
                                Obter Ajuda
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <Row style={{ paddingLeft: 25, paddingRight: 25 }} >

                        <Col sm={12} lg={12}>
                            <Row sm={12} lg={12}>
                                <Col sm={12} lg={9} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Button
                                        onClick={() => { Navigate('/mais-opcoes/consultas/nova') }}
                                        style={{ backgroundColor: '#E30613', border: 0 }}
                                        size='sm'
                                        id="dropdown-basic"
                                    >
                                        NOVA CONSULTA
                                    </Button>
                                </Col>
                                <Col sm={12} lg={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            style={{ backgroundColor: '#E30613', border: 0 }}
                                            size='sm'
                                            id="dropdown-basic"
                                        >
                                            {filtroAtual}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {statusSolicitacoes.map((item) => {
                                                return (
                                                    <Dropdown.Item onClick={() => {
                                                        setFiltroAtual(item.STATUS)
                                                    }}>{item.STATUS}</Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row sm={12} lg={12}>
                                <Col sm={12} lg={12}>
                                    <RenderizaSolicitacoes />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default ConsultasUsuario;