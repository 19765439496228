export const ESTADO_CIVIL = [
    "Solteiro",
    "Casado",
    "Casado - Comunhão Total de Bens",
    "Casado - Sem Comunhão de Bens",
    "Casado - Comunhão Parcial de Bens",
    "Divorciado ou Separado Judicialmente",
    "Viúvo",
    "Concubinato",
    "Outros"
]
