import React, { useState } from "react";
import { Col, Row, Container, Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { MdAttachFile } from "react-icons/md"

export function NovaCaixaMensagemFrom(props) {

    const { name, message, data, anexo } = props

    const [listaAnexos, setListaAnexos] = useState([])

    function htmlDecode(input) {
        console.log("Input: ", input)
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 20,
        }}
        >
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-start",
                }}>
                    <p style={{ marginBottom: 0, fontWeight: "bold" }} >
                        {name}
                    </p>
                </div>

                <div className="caixaMensagem"
                    style={{
                        backgroundColor: "#C9EBED",
                        paddingTop: "0.938rem",
                        paddingRight: "0.938rem",
                        paddingLeft: "0.938rem",
                        minWidth: anexo ? "18rem" : 0,
                        color: "black",
                        borderRadius: "15px 15px 15px 0px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line"
                    }}>
                    <div style={{
                        marginBottom: 4,
                        wordWrap: "break-word",
                        whiteSpace: "pre-line"
                    }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                    {/* {message}
                </div> */}


                    <div style={{
                        display: "flex",
                        justifyContent: anexo ? "space-between" : "flex-end",
                        marginTop: anexo ? "1.25rem" : "auto",
                        fontSize: 14,
                        color: 'grey'

                    }}>

                        {anexo &&
                            <div style={{
                                display: "flex",
                                color: "#FFFFFF",

                            }}
                                onClick={() => {

                                    try {
                                        const objeto = JSON.parse(anexo);
                                        if (Array.isArray(objeto)) {
                                            setListaAnexos(objeto)
                                        } else {
                                            window.open(anexo, '_blank')
                                        }
                                    } catch (error) {
                                        window.open(anexo, '_blank')
                                    }
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
                                        <MdAttachFile size="16" color="black" title="Anexar" />
                                        <strong style={{ color: "black", fontSize: 12 }}>Anexo Disponível</strong>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>


                                        {listaAnexos.map((anexo, index) => (
                                            <div key={index} style={{ color: "black", fontSize: 11, cursor: "pointer" }} onClick={() => window.open(anexo.arquivo, '_blank')}>
                                                {anexo.nome}
                                            </div>

                                        ))}

                                    </div>



                                </div>

                            </div>
                        }
                        <p style={{
                            opacity: 0.6
                        }}>
                            {data}
                        </p>
                    </div>
                </div>
            </div>


        </div >
    )
}