import axios from "axios";

const token = localStorage.getItem('Token');

export const api = axios.create({
  baseURL: process.env.REACT_APP_ENDERECO_WS,
});

api.defaults.headers.common["token"] = `${token}`;

