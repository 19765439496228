import axios from "axios";
import { encode as base64_encode } from 'base-64';
import { formatToCPF, isCPF } from 'brazilian-values';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { BotaoGrid } from "../../components/button";
import { ErroField, InputFieldPadrao } from '../../components/field';
import Footer from "../../components/footer";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';

function Admissoes() {

    const Navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaNovaAdmissao, setTelaNovaAdmissao] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')
    // eslint-disable-next-line
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [admissoes, setAdmissoes] = useState([])
    const [idAdmissao, setIdAdmissao] = useState(0)

    const SchemaNovaAdmissao = Yup.object().shape({
        nome: Yup.string()
            .required('Campo Obrigatório'),
        cpf: Yup.string()
            .required('Campo Obrigatório')
            .test('is-cpf', 'CPF Invalido', value => isCPF(value))
    })

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function FormataCPF(CPF) {
        return formatToCPF(CPF);
    }

    function GetConfirmaDescartar(funcionario, id) {
        setTelaMsg('Descartar Admissão do Colaborador ' + funcionario + '?')
        setTelaTitulo('Cancelar Admissão')
        setIdAdmissao(id)
        setTelaAlerta(true)
    }

    async function getAdmissoes(empresa) {

        setMsgLoading('Obtendo Admissões')
        setLoading(true)

        axios.post(conexao.endereco + '/admissoes/pendentes',
            {
                empresa: localStorage.getItem('empresa'),
                estab: localStorage.getItem('estabelecimento'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setAdmissoes(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    function ListaAdmissoesPendentes() {

        const colunas = [
            {
                name: 'Nome do Colaborador',
                selector: row => row.admi_nome,
                sortable: true,
                // maxWidth: '50%'
            },
            {
                name: 'CPF',
                selector: row => row.admi_cpf,
                format: row => FormataCPF(row.admi_cpf),
                sortable: true,
                maxWidth: '20%',
            },
            {
                name: '',
                button: true,
                right: true,
                cell: row => (
                    <BotaoGrid id="descartar_candidato" texto="Descartar Candidato" posicao="top"
                        onClick={() => { GetConfirmaDescartar(row.admi_nome, row.admi_id) }} >
                        <span style={{ color: 'brown' }}>Descartar</span>
                    </BotaoGrid>
                ),
            },
            {
                name: '',
                button: true,
                right: true,
                cell: row => (
                    <BotaoGrid id="continuar_admissao" texto="Continuar Admissão" posicao="top"
                        onClick={() => {
                            Navigate('/departamento-pessoal/admissoes/edicao/' + base64_encode(row.admi_id))
                            localStorage.setItem("tab", "dados-pessoais")
                        }} >
                        <span style={{ color: 'black' }}>Continuar</span>
                    </BotaoGrid>
                ),
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        const tableDataConfig = {
            columns: colunas,
            data: admissoes,
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: true,
            export: true
        };

        return (
            <DataTableExtensions {...tableDataConfig}>
                <DataTable
                    columns={colunas}
                    data={admissoes}
                    // onRowClicked={(row, index) => {
                    //     setTelaSelecaoEmpresas(false)
                    //     localStorage.setItem('razao', row.razao)
                    //     localStorage.setItem('empresa', row.questor_empresa)
                    //     localStorage.setItem('estabelecimento', row.questor_estabelecimento)
                    //     Navigate('/')
                    // }}
                    // expandOnRowClicked={true}
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    // pointerOnHover={true}
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }

    async function incluirAdmissao(values, actions) {

        if (!isCPF(values.cpf)) {
            ExibirAlerta('Atenção', 'CPF Inválido')
            return
        }

        setTelaNovaAdmissao(false)

        setMsgLoading('Incluindo Admissão')
        setLoading(true)

        const url = conexao.endereco + '/admissoes/inclusao-inicial'

        const body = {
            empresa: localStorage.getItem('empresa'),
            estab: localStorage.getItem('estabelecimento'),
            nome: values.nome,
            cpf: values.cpf.replace(/\D/g, ''),
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('POST', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('token', conexao.autenticacao)

        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: incluirAdmissao')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: incluirAdmissao')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: incluirAdmissao: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)

            if (requisicao.status === 201) {
                ExibirAlerta('Parabéns', 'Admissão Incluída com Sucesso!')
                getAdmissoes(localStorage.getItem('empresa'))
            } else {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Atenção', resposta.message)
            }

        }
    }

    async function descartarAdmissao(id) {

        setMsgLoading('Descartando Admissão')
        setLoading(true)

        const url = conexao.endereco + '/admissoes/descartar'

        const body = {
            identificacao: id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('POST', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('token', conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: descartarAdmissao')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: descartarAdmissao')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: descartarAdmissao: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)

            setIdAdmissao(0)

            if (requisicao.status === 200) {
                ExibirAlerta('Parabéns', 'Admissão Descartada com Sucesso!')
                getAdmissoes(localStorage.getItem('empresa'))
            } else {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Atenção', resposta.message)
            }

        }
    }


    useEffect(() => {
        getAdmissoes(localStorage.getItem('empresa'))
    }, [])


    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Admissões em Andamento</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                    size='sm'
                                    onClick={() => {
                                        setTelaNovaAdmissao(true)
                                    }}
                                >
                                    Nova Admissão
                                </Button>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        // alert(JSON.stringify(dadosAdmissao))
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <Col>
                                <Row >


                                    <ListaAdmissoesPendentes />


                                </Row>
                            </Col>

                        </Row>
                    </div>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                                {msgLoading}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {idAdmissao !== 0 &&
                            <>
                                <Button variant="secondary" onClick={() => {
                                    setIdAdmissao(0)
                                    setTelaAlerta(false)
                                }}>Cancelar</Button>
                                <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { descartarAdmissao(idAdmissao) }}>Confirmar</Button>
                            </>
                        }
                        {idAdmissao === 0 &&
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={telaNovaAdmissao}
                    onHide={() => { setTelaNovaAdmissao(false) }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Nova Admissão</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Formik
                            onSubmit={incluirAdmissao}
                            validationSchema={SchemaNovaAdmissao}
                            initialValues={{
                                nome: '',
                                cpf: ''
                            }}
                            render={({ values, errors, touched, isValid }) => (
                                <>
                                    <Form autoComplete="off">
                                        <Row>
                                            <Col lg={12} className="mb-3">
                                                <InputFieldPadrao campo="nome" tipo="text" texto="Nome do Colaborador" />
                                                <ErroField campo="nome" />
                                            </Col>
                                            <Col lg={12} className="mb-3">
                                                <InputFieldPadrao campo="cpf" tipo="text" texto="CPF" />
                                                <ErroField campo="cpf" />
                                            </Col>
                                            <Col>
                                                <Button style={{ backgroundColor: '#E30613', border: 0, marginTop: 15 }} type="submit">Cadastrar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            )}
                        />

                    </Modal.Body>

                </Modal>
            </div >
        </>
    )
}

export default Admissoes;