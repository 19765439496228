import React from "react";
import { Col, Row, Container, Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { MdAttachFile } from "react-icons/md"

export function NovaCaixaMensagemMe(props) {

    const { name, message, data, anexo } = props

    function htmlDecode(input) {
        console.log("Input: ", input)
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
        }}
        >
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <p style={{ marginBottom: 0, fontWeight: "bold" }} >{name}
                    </p>
                </div>

                <div
                    className="caixaMensagem"
                    style={{
                        backgroundColor: "#EE4540",
                        paddingTop: "0.938rem",
                        paddingRight: "0.938rem",
                        paddingLeft: "0.938rem",
                        minWidth: anexo ? "18rem" : 0,
                        color: "white",
                        borderRadius: "15px 15px 0px 10px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line"
                    }}

                >
                    <div style={{
                        marginBottom: 4,
                        wordWrap: "break-word",
                        whiteSpace: "pre-line"
                    }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />

                    {/* era um <p> */}
                    {/* {message}
                    </div> */}


                    <div style={{
                        display: "flex",
                        marginTop: anexo ? "1.25rem" : "auto",
                        justifyContent: anexo ? "space-between" : "flex-end",
                        fontSize: 14,
                    }}>

                        {anexo &&
                            <div style={{
                                display: "flex",
                                color: "#FFFFFF",
                                cursor: "pointer",
                            }}
                                onClick={() => { window.open(anexo, '_blank') }}>
                                <MdAttachFile size="16" color="#FFFFFF" title="Anexar" />
                                <strong style={{ color: "#FFFFFF", fontSize: 12 }}>Anexo Disponível</strong>
                            </div>
                        }
                        <p style={{
                            opacity: 0.6
                        }}>
                            {data}
                        </p>

                    </div>

                </div>
            </div >


        </div >
    )
}