import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom"
import { Col, Row, Container, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import { GlobalContext } from "../../contexts/global";
import DataTable from 'react-data-table-component';
import { MdEdit, MdDelete, MdViewList, MdCheck, MdError } from 'react-icons/md'
import axios from "axios";
import { formatToCNPJ, isCNPJ, formatToBRL } from "brazilian-values";
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { InputFieldPadrao, ErroField, SelectFieldPadrao } from "../../components/field"
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function ContasBancarias() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const { usuario, tipoAcesso, conexao, licenca, permissao } = useContext(GlobalContext)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [contas, setContas] = useState([])
    const [bancos, setBancos] = useState([])
    const [telaCadastro, setTelaCadastro] = useState(false)
    const [telaExtrato, setTelaExtrato] = useState(false)

    const [edicao, setEdicao] = useState(false)
    const [idExclusao, setIdExclusao] = useState('0')

    const [valoresIniciais, setValoresIniciais] = useState([])

    const [extrato, setExtrato] = useState([])

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function ExibirExclusao(id) {
        setTelaTitulo('Confirma Exclusão?')
        setTelaMsg('A Ação não poderá ser desfeita!')
        setIdExclusao(id)
        setTelaAlerta(true)
    }

    function ExibeDados() {

        const colunas = [
            {
                name: 'Descrição',
                selector: row => row.cont_descricao,
                cellExport: row => row.cont_descricao,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Banco',
                selector: row => row.cont_banco,
                cellExport: row => row.cont_banco,
                sortable: true,
                wrap: true,
                maxWidth: '80px',
            },
            {
                name: 'Agência',
                selector: row => row.cont_agencia,
                cellExport: row => row.cont_agencia,
                sortable: true,
                wrap: true,
                maxWidth: '120px',
            },
            {
                name: 'Digito',
                selector: row => row.cont_agencia_digito,
                cellExport: row => row.cont_agencia_digito,
                sortable: true,
                wrap: true,
                maxWidth: '80px',
            },
            {
                name: 'Conta',
                selector: row => row.cont_conta,
                cellExport: row => row.cont_conta,
                sortable: true,
                wrap: true,
                maxWidth: '120px',
            },
            {
                name: 'Digito',
                selector: row => row.cont_conta_digito,
                cellExport: row => row.cont_conta_digito,
                sortable: true,
                wrap: true,
                maxWidth: '80px',
            },
            {
                name: 'Conectado',
                selector: row => row.cont_id_kobana > 0 ? <MdCheck size="24" color="green" /> : <MdError size="24" color="red" />,
                cellExport: row => row.cont_id_kobana,
                sortable: true,
                center: true,
                wrap: true,
                maxWidth: '140px',
            },
            {
                name: 'Ações',
                button: true,
                center: true,
                cell: row => (
                    <>
                        {/* < a onClick={() => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao, idEmpresa: row.id } }) }} style={{ cursor: 'pointer' }}>
                            <TbListDetails size="24" color="#E30613" title="Usuários" />
                        </a> */}
                        <OverlayTrigger trigger="hover" placement="top" overlay={<Tooltip id="tooltip_extrato">Visualizar Extrato</Tooltip>}>
                            < a onClick={() => {
                                ListarExtrato(row.cont_id)
                            }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                                <MdViewList size="24" color="#E30613" />
                            </a>
                        </OverlayTrigger>
                        {/* <OverlayTrigger trigger="hover" placement="top" overlay={<Tooltip id="tooltip_editar_conta">Editar Conta</Tooltip>}>
                            < a onClick={() => {
                                setValoresIniciais(row)
                                setEdicao(true)
                                setTelaCadastro(true)
                            }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                                <MdEdit size="24" color="#E30613" />
                            </a>
                        </OverlayTrigger> */}
                        {/* <OverlayTrigger trigger="hover" placement="top" overlay={<Tooltip id="tooltip_excluir_conta">Excluir Conta</Tooltip>}>
                            < a onClick={() => { ExibirExclusao(row.cont_id) }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                                <MdDelete size="24" color="#E30613" />
                            </a>
                        </OverlayTrigger> */}
                    </>

                ),
            },

        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        const tableDataConfig = {
            columns: colunas,
            data: contas,
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: true,
            export: true,
            dropdown: true
        };

        return (
            <DataTableExtensions {...tableDataConfig}>
                <DataTable
                    columns={colunas}
                    data={contas}
                    // onRowClicked={(row, index) => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao } }) }}
                    // expandOnRowClicked={true}
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    // pointerOnHover={true}
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }

    function ExibeExtratoBancario() {

        const colunas = [
            {
                name: 'Data',
                selector: row => row.extb_data_extrato_format,
                cellExport: row => row.extb_data_extrato_format,
                sortable: true,
                maxWidth: '120px',
            },
            {
                name: 'Descrição',
                selector: row => row.extb_descricao,
                cellExport: row => row.extb_descricao,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Documento',
                selector: row => row.extb_documento,
                cellExport: row => row.extb_documento,
                sortable: true,
                wrap: true,
                maxWidth: '180px',
            },
            {
                name: 'Tipo',
                selector: row => row.extb_tipo == 'debit' ? 'Débito' : 'Crédito',
                cellExport: row => row.extb_tipo == 'debit' ? 'Débito' : 'Crédito',
                sortable: true,
                wrap: true,
                maxWidth: '90px',
            },
            {
                name: 'Valor',
                selector: row => row.extb_valor,
                format: row => formatToBRL(row.extb_valor),
                cellExport: row => row.extb_valor,
                sortable: true,
                wrap: true,
                maxWidth: '180px',
                conditionalCellStyles: [
                    {
                        when: row => row.extb_tipo == 'debit',
                        style: {
                            // backgroundColor: 'rgba(63, 195, 128, 0.9)',
                            color: 'rgb(204, 71, 65)'
                        }
                    },
                    {
                        when: row => row.extb_tipo == 'credit',
                        style: {
                            // backgroundColor: 'rgba(63, 195, 128, 0.9)',
                            color: 'rgb(63, 195, 128)'
                        }
                    }
                ]
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        const tableDataConfig = {
            columns: colunas,
            data: extrato,
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: true,
            export: true,
            dropdown: true
        };

        return (
            <DataTableExtensions {...tableDataConfig}>
                <DataTable
                    columns={colunas}
                    data={extrato}
                    // onRowClicked={(row, index) => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao } }) }}
                    // expandOnRowClicked={true}
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    pointerOnHover={true}
                    dense
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }


    async function ListarContas() {

        setMsgLoading('Listando Contas Bancárias')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/contas/listar/empresa/',
            {
                "empresa": localStorage.getItem('id_empresa')
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setContas(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })


    }
    async function ListarExtrato(id) {

        setMsgLoading('Listando Extrato Bancário')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/contas/extrato/listar',
            {
                "id": id
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setExtrato(response.data.extrato)
                setLoading(false)
                setTelaExtrato(true)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }

    async function ListarBancosFebraban() {
        setMsgLoading('Listando Bancos')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/listar',
            {}, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setBancos(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function CadastrarConta(valores) {

        setTelaCadastro(false)

        setMsgLoading('Cadastrando Conta Bancária')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/contas/incluir',
            {
                "descricao": valores.cont_descricao,
                "tipo": valores.cont_tipo,
                "banco": valores.cont_banco,
                "agencia": valores.cont_agencia,
                "agencia_digito": valores.cont_agencia_digito,
                "conta": valores.cont_conta,
                "conta_digito": valores.cont_conta_digito,
                "empresa": localStorage.getItem('id_empresa')
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                ListarContas()
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })



    }

    async function AtualizarConta(valores) {

        setTelaCadastro(false)

        setMsgLoading('Atualizando Conta Bancária')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/contas/atualizar',
            {
                "descricao": valores.cont_descricao,
                "tipo": valores.cont_tipo,
                "banco": valores.cont_banco,
                "agencia": valores.cont_agencia,
                "agencia_digito": valores.cont_agencia_digito,
                "conta": valores.cont_conta,
                "conta_digito": valores.cont_conta_digito,
                "empresa": localStorage.getItem('id_empresa'),
                "id": valores.cont_id
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                ListarContas()
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function DeletarConta(id) {

        setTelaCadastro(false)
        setTelaAlerta(false)
        setMsgLoading('Deletando Cadastro da Conta Bancária')
        setLoading(true)

        axios.post(conexao.endereco + '/bancos/contas/remover',
            {
                "id": id
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                ListarContas()
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    const SchemaEmpresas = Yup.object().shape({
        empr_nome: Yup.string()
            .required('Campo Obrigatório'),
        empr_razao: Yup.string()
            .required('Campo Obrigatório'),
        empr_cnpj: Yup.string()
            .required('Campo Obrigatório'),
        empr_questor_empresa: Yup.number()
            .required('Campo Obrigatório'),
        empr_questor_estabelecimento: Yup.number()
            .required('Campo Obrigatório')
    })

    useEffect(() => {

        ListarContas()
        ListarBancosFebraban()

    }, [])

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Contas Bancárias</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                {/* <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        setEdicao(false)
                                        setValoresIniciais({
                                            "descricao": "",
                                            "tipo": "",
                                            "banco": "",
                                            "agencia": "",
                                            "agencia_digito": "",
                                            "conta": "",
                                            "conta_digito": "",
                                            "empresa": localStorage.getItem('id_empresa')
                                        })
                                        setTelaCadastro(true)
                                    }}
                                >
                                    Incluir
                                </Button> */}
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginLeft: 15 }}
                                    size='sm'
                                    onClick={() => {
                                        ListarContas()
                                    }}
                                >
                                    Atualizar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <ExibeDados />
                        </Row>
                    </div>
                </Container>
                <Footer />

                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>

                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {idExclusao === '0' &&
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                        }
                        {idExclusao !== '0' &&
                            <>
                                <Button variant="secondary" onClick={() => { setTelaAlerta(false) }}>Cancelar</Button>
                                <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { DeletarConta(idExclusao) }}>Confirmar</Button>
                            </>
                        }

                    </Modal.Footer>
                </Modal>

                <Modal show={telaCadastro} centered dialogClassName='modal-50'>
                    <Modal.Header>
                        {edicao &&
                            <Modal.Title>Edição de Cadastro</Modal.Title>
                        }
                        {!edicao &&
                            <Modal.Title>Cadastro de Conta Bancária</Modal.Title>
                        }

                    </Modal.Header>

                    <Modal.Body>
                        <Formik
                            onSubmit={(values, actions) => {
                                if (edicao) {
                                    AtualizarConta(values)
                                } else {
                                    CadastrarConta(values)
                                }
                            }}
                            // validationSchema={SchemaEmpresas}
                            enableReinitialize={true}
                            initialValues={valoresIniciais}
                            render={({ values, errors, touched, isValid, setFieldValue }) => (
                                <Form autoComplete="off" id="formContas">
                                    <Row>
                                        <Col sm={12} lg={8} className="mb-3">
                                            <InputFieldPadrao campo="cont_descricao" tipo="text" texto="Descrição" />
                                            <ErroField campo="cont_descricao" />
                                        </Col>
                                        <Col sm={12} lg={4} className="mb-3">
                                            <SelectFieldPadrao campo="cont_tipo" texto="Tipo">
                                                <option value={null}>Selecione</option>
                                                <option value={'checking'}>Conta Corrente</option>
                                                <option value={'savings'}>Conta Poupança</option>
                                                <option value={'payment'}>Conta Pagamento</option>

                                            </SelectFieldPadrao>
                                            <ErroField campo="cont_tipo" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} lg={4} className="mb-3">
                                            {/* <InputFieldPadrao campo="cont_banco" tipo="text" texto="Banco" /> */}
                                            <SelectFieldPadrao campo="cont_banco" texto="Banco">
                                                <option value={null}>Selecione</option>
                                                {bancos.map((item) => {
                                                    return (
                                                        <option value={item.number}>{item.name}</option>
                                                    )
                                                })}
                                            </SelectFieldPadrao>
                                            <ErroField campo="cont_banco" />
                                        </Col>
                                        <Col sm={12} lg={2} className="mb-3">
                                            <InputFieldPadrao campo="cont_agencia" tipo="number" texto="Agência" />
                                            <ErroField campo="cont_agencia" />
                                        </Col>
                                        <Col sm={12} lg={2} className="mb-3">
                                            <InputFieldPadrao campo="cont_agencia_digito" tipo="number" texto="Digito" />
                                            <ErroField campo="cont_agencia_digito" />
                                        </Col>
                                        <Col sm={12} lg={2} className="mb-3">
                                            <InputFieldPadrao campo="cont_conta" tipo="number" texto="Conta" />
                                            <ErroField campo="cont_conta" />
                                        </Col>
                                        <Col sm={12} lg={2} className="mb-3">
                                            <InputFieldPadrao campo="cont_conta_digito" tipo="number" texto="Digito" />
                                            <ErroField campo="cont_conta_digito" />
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />

                    </Modal.Body>

                    <Modal.Footer>
                        <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaCadastro(false) }}>Fechar</Button>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} form="formContas" type="submit">Salvar</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={telaExtrato} centered dialogClassName='modal-75'>
                    <Modal.Header>
                        <Modal.Title>Extrato Bancário</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <ExibeExtratoBancario />

                    </Modal.Body>

                    <Modal.Footer>
                        <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaExtrato(false) }}>Fechar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )
}

export default ContasBancarias