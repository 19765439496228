import React, { useContext, useState } from "react"
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../../contexts/global"
import Navegacao from "../../components/navbar"
import Footer from "../../components/footer"
import DataTable from "react-data-table-component"
import { BsArrowLeftRight } from 'react-icons/bs'
import LogoArquivo from '../../images/logo.svg';

function ImportarExtrato() {

    const Navigate = useNavigate()

    const { usuario, tipoAcesso, conexao } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [ofxImportado, setOfxImportado] = useState([])
    const [transacoes, setTransacoes] = useState([])

    const [telaConciliar, setTelaConciliar] = useState(false)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [descricaoMvto, setDescricaoMvto] = useState('')

    function ConverterBase64(arquivo) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(arquivo)
            console.log('Convertendo Arquivo: ' + arquivo)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    async function LerOfx(arqInformado) {
        if (arqInformado) {
            const base64 = await ConverterBase64(arqInformado)
            console.log(base64)
            MvtoBancarioEnviaArquivoOFX(base64)
        } else {
            alert('Arquivo Não Informado')
        }
    }

    function ConciliarMovimento(descricao) {
        setDescricaoMvto(descricao)
        setTelaConciliar(true)
    }

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    async function MvtoBancarioEnviaArquivoOFX(arquivobase64) {

        setMsgLoading('Enviando Extrato')
        setLoading(true)

        const url = conexao.endereco + "/MvtoBancarioEnviarOfx"

        let requisicao = new XMLHttpRequest()

        let body = {
            "arquivo": arquivobase64,
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            if (requisicao.status === 200) {
                // console.log('Transações: ' + resposta.json['Transactions'])
                setOfxImportado(JSON.parse(resposta.cabecalho))
                setTransacoes(JSON.parse(resposta.transacoes))
            } else {
                alert('Falha ao Converter Arquivo')
            }


        }
    }

    function RenderizaExtrato() {

        const colunasExtrato = [
            {
                name: 'Data',
                selector: row => row.data,
                sortable: true,
                maxWidth: '100px',
            },
            {
                name: 'Descrição',
                selector: row => row.descricao,
                sortable: true,
            },
            {
                name: 'Tipo',
                selector: row => row.es,
                sortable: true,
                maxWidth: '20px',

            },
            {
                name: 'Valor',
                selector: row => Number(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                sortable: true,
                maxWidth: '120px',
                right: true
            },
            {
                name: 'Conciliado',
                button: true,
                style: {
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
                cell: row => (
                    < a onClick={() => {
                        ConciliarMovimento(row.descricao)
                    }} >
                        <BsArrowLeftRight size="24" color="#2980B9" title="Conciliar" />
                    </a>
                ),
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };


        const dados = ofxImportado


        return (

            <>

                <Row style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
                    <Col><strong>Banco: </strong> {dados.Banco}</Col>
                    <Col><strong>Agência: </strong> {dados.Agencia}</Col>
                    <Col><strong>Conta: </strong> {dados.Conta}</Col>
                </Row>


                <Col>
                    <DataTable
                        columns={colunasExtrato}
                        data={transacoes}
                        // onRowClicked={(row, index) => {
                        //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                        //     CarregaDetalheColetas(row.departamento, row.vencimento)
                        // }}
                        striped={true}
                        highlightOnHover={true}
                        responsive={true}
                        // pointerOnHover={true}
                        noDataComponent={"Nenhum Registro Encontrado =/"}
                        pagination={true}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </Col>
            </>
        )
    }

    return (
        <div>
            <Navegacao />
            <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80, paddingLeft: 40, paddingRight: 40 }}>

                <Row sm={12} lg={12} style={{ paddingTop: 20 }}>
                    <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                        <h3>Importação de Extrato Bancário</h3>
                    </Col>
                    <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                        <Button
                            style={{ backgroundColor: '#E30613', border: 0 }}
                            size="sm"
                            onClick={() => {
                                alert('ajuda')
                            }}
                        >
                            Obter Ajuda
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <hr />
                </Row>
                <Row>
                    <Col sm={12} lg={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formLogEmail">
                                <Form.Label>Arquivo OFX</Form.Label>
                                <Form.Control type="file" accept=".ofx" size="sm" required onChange={(e) => { LerOfx(e.target.files[0]) }} />
                            </Form.Group>
                        </Form>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <RenderizaExtrato />
                    </Col>
                </Row>
            </Container>
            <Footer />

            <Modal show={telaConciliar} centered>
                <Modal.Header style={{ color: '#E30613' }}>
                    <strong>{descricaoMvto}</strong>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <Form.Select aria-label="Default select example">
                        <option>Selecione o Tipo de Movimento</option>
                        <option value="1">Venda de Mercadoria</option>
                        <option value="2">Retirada Sócios</option>
                        <option value="3">Compra Uso e Consumo</option>
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaConciliar(false) }}>Fechar</Button>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaConciliar(false) }}>Salvar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={telaAlerta} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {telaTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {telaMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>


        </div>
    )
}

export default ImportarExtrato