import React, { useContext, useEffect } from "react";
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { BsArrowLeftRight, BsBank, BsCardChecklist, BsFileMedical, BsFillPlusCircleFill, BsListCheck, BsPersonLinesFill, BsPiggyBank } from 'react-icons/bs';
import { RiUserSearchLine } from 'react-icons/ri';
import { TbFileDiff, TbRelationManyToMany, TbReportMoney } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import Navegacao from "../components/navbar";
import { GlobalContext } from '../contexts/global';

import { AiFillAlert, AiOutlineAudit, AiOutlineBorderInner, AiOutlineFileSearch, AiOutlineUnorderedList, AiOutlineWarning } from 'react-icons/ai';
import { BiExport, BiHelpCircle, BiImport } from 'react-icons/bi';
import { FaFileContract, FaHistory, FaPercentage, FaUmbrellaBeach } from 'react-icons/fa';
import { GoQuestion } from 'react-icons/go';
import { HiOutlineReceiptTax } from 'react-icons/hi';
import { IoHelpBuoy } from 'react-icons/io5';
import { MdAutoFixHigh, MdCancel, MdCloud, MdEvent, MdEventAvailable, MdOutlinePendingActions, MdOutlinePriorityHigh } from 'react-icons/md';

import Footer from '../components/footer';

function PaginaDetalhe({ props }) {

    useEffect(() => {

    }, [])


    const Navigate = useNavigate()


    // eslint-disable-next-line
    const { usuario, SalvarLogin, categoriasSolicitacoes, tipoAcesso } = useContext(GlobalContext)

    const menuNotasFiscais = [
        {
            "identificador": "como_emitir_nota_fiscal",
            "icone": "GoQuestion",
            "titulo": "Como Emitir Nota Fiscal",
        },
        {
            "identificador": "enviar_notas_fiscais",
            "icone": "BiExport",
            "titulo": "Enviar Notas Fiscais",
        },
        {
            "identificador": "consultar_notas_fiscais",
            "icone": "AiOutlineUnorderedList",
            "titulo": "Consultar Notas Fiscais",
        },
        {
            "identificador": "cancelar_nota_fiscal",
            "icone": "MdCancel",
            "titulo": "Cancelar Nota Fiscal",
        },
        {
            "identificador": "registrar_notas_tomadas",
            "icone": "FaFileContract",
            "titulo": "Registrar Notas Tomadas",
        },
        {
            "identificador": "consultar_aliquotas",
            "icone": "FaPercentage",
            "titulo": "Consultar Alíquotas",
        },
    ]

    const menuGestaoSolicitacoes = [
        {
            "identificador": "solicitacoes_operador",
            "icone": "AiFillAlert",
            "titulo": "Minhas Solicitacoes",
        },
        {
            "identificador": "solicitacoes_nao_atribuidas",
            "icone": "BsPersonLinesFill",
            "titulo": "Não Atribuídas",
        },
        {
            "identificador": "solicitacoes_categorias",
            "icone": "AiOutlineUnorderedList",
            "titulo": "Categorias",
        },
        {
            "identificador": "solicitacoes_prioridades",
            "icone": "MdOutlinePriorityHigh",
            "titulo": "Prioridades",
        },
        {
            "identificador": "solicitacoes_regras",
            "icone": "MdAutoFixHigh",
            "titulo": "Regras",
        },
    ]

    const menuAuditorFiscal = [
        {
            "identificador": "auditor_fiscal_entradas_nao_lancadas",
            "icone": "AiFillAlert",
            "titulo": "Entradas Não Lançadas",
        },
        {
            "identificador": "auditor_fiscal_relacionamento_cfop_produto",
            "icone": "TbRelationManyToMany",
            "titulo": "CFOP x Produto",
        },
        {
            "identificador": "auditor_fiscal_diferencial_aliquota",
            "icone": "FaPercentage",
            "titulo": "Diferencial de Alíquota",
        },
    ]

    const menuDiferencialAliquota = [
        {
            "identificador": "auditor_fiscal_entradas_nao_lancadas",
            "icone": "TbFileDiff",
            "titulo": "Diferença Valor Contábil",
        },
        {
            "identificador": "auditor_fiscal_relacionamento_cfop_produto",
            "icone": "BsCardChecklist",
            "titulo": "Revisão Produtos",
        },
        {
            "identificador": "auditor_fiscal_diferencial_aliquota",
            "icone": "BsListCheck",
            "titulo": "Revisão Fornecedores",
        },
        {
            "identificador": "auditor_fiscal_diferencial_aliquota",
            "icone": "AiOutlineBorderInner",
            "titulo": "DIFA Antecipação",
        },
    ]

    const menuMovimentoBancario = [
        {
            "identificador": "contas_bancarias",
            "icone": "BsBank",
            "titulo": "Contas Bancárias",
        },
        {
            "identificador": "enviar_extrato",
            "icone": "BiExport",
            "titulo": "Enviar Extrato Bancário",
        },
        // {
        //     "identificador": "conciliar_extratos",
        //     "icone": "BsArrowLeftRight",
        //     "titulo": "Conciliar Extratos",
        // },
    ]

    const menuFolhaPgto = [
        {
            "identificador": "consultar_folha",
            "icone": "AiOutlineFileSearch",
            "titulo": "Consultar Folha",
        },
        // {
        //     "identificador": "listar_funcionarios",
        //     "icone": "RiUserSearchLine",
        //     "titulo": "Listar Funcionários",
        // },
        // {
        //     "identificador": "informar_eventos",
        //     "icone": "MdEvent",
        //     "titulo": "Informar Eventos",
        // },
        {
            "identificador": "rh_admissoes",
            "icone": "BsFillPlusCircleFill",
            "titulo": "Admissões",
        },
        {
            "identificador": "rh_rescisao",
            "icone": "TbReportMoney",
            "titulo": "Rescisão",
        },
        {
            "identificador": "rh_acidente",
            "icone": "AiOutlineWarning",
            "titulo": "Acidente",
        },
        {
            "identificador": "rh_ferias",
            "icone": "FaUmbrellaBeach",
            "titulo": "Férias",
        },
        {
            "identificador": "rh_atestados",
            "icone": "BsFileMedical",
            "titulo": "Atestados",
        }
    ]

    const menuImpostos = [
        {
            "identificador": "guias_fiscais",
            "icone": "HiOutlineReceiptTax",
            "titulo": "Guias Fiscais",
        },
        // {
        //     "identificador": "historico_pagamento_impostos",
        //     "icone": "FaHistory",
        //     "titulo": "Histórico Financeiro",
        // },
        // {
        //     "identificador": "impostos_atraso",
        //     "icone": "MdOutlinePendingActions",
        //     "titulo": "Impostos em Atraso",
        // },
        // {
        //     "identificador": "solicitar_parcelamento",
        //     "icone": "BsPiggyBank",
        //     "titulo": "Solicitar Parcelamento",
        // },
    ]

    const menuMaisOpcoes = [
        {
            "identificador": "solicitacoes",
            "icone": "IoHelpBuoy",
            "titulo": "Minhas Solicitações",
        },
        {
            "identificador": "consultas",
            "icone": "BiHelpCircle",
            "titulo": "Minhas Consultas",
        },
    ]
    const menuMaisOpcoesOperador = [
        {
            "identificador": "arquivos",
            "icone": "MdCloud",
            "titulo": "Arquivos",
        },
        {
            "identificador": "auditor-fiscal",
            "icone": "AiOutlineAudit",
            "titulo": "Auditor Fiscal",
        },
    ]



    function RenderizaIcone(props) {

        switch (props.icone) {
            case 'GoQuestion':
                return (<GoQuestion color="#E30614" size="36" />)
            case 'BiImport':
                return (<BiImport color="#E30614" size="36" />)
            case 'BiExport':
                return (<BiExport color="#E30614" size="36" />)
            case 'AiOutlineUnorderedList':
                return (<AiOutlineUnorderedList color="#E30614" size="36" />)
            case 'MdCancel':
                return (<MdCancel color="#E30614" size="36" />)
            case 'FaFileContract':
                return (<FaFileContract color="#E30614" size="36" />)
            case 'FaPercentage':
                return (<FaPercentage color="#E30614" size="36" />)
            case 'BsArrowLeftRight':
                return (<BsArrowLeftRight color="#E30614" size="36" />)
            case 'AiOutlineFileSearch':
                return (<AiOutlineFileSearch color="#E30614" size="36" />)
            case 'RiUserSearchLine':
                return (<RiUserSearchLine color="#E30614" size="36" />)
            case 'MdEvent':
                return (<MdEvent color="#E30614" size="36" />)
            case 'MdEventAvailable':
                return (<MdEventAvailable color="#E30614" size="36" />)
            case 'HiOutlineReceiptTax':
                return (<HiOutlineReceiptTax color="#E30614" size="36" />)
            case 'FaHistory':
                return (<FaHistory color="#E30614" size="36" />)
            case 'MdOutlinePendingActions':
                return (<MdOutlinePendingActions color="#E30614" size="36" />)
            case 'BsPiggyBank':
                return (<BsPiggyBank color="#E30614" size="36" />)
            case 'IoHelpBuoy':
                return (<IoHelpBuoy color="#E30614" size="36" />)
            case 'BiHelpCircle':
                return (<BiHelpCircle color="#E30614" size="36" />)
            case 'BsFillPlusCircleFill':
                return (<BsFillPlusCircleFill color="#E30614" size="36" />)
            case 'AiFillAlert':
                return (<AiFillAlert color="#E30614" size="36" />)
            case 'MdAutoFixHigh':
                return (<MdAutoFixHigh color="#E30614" size="36" />)
            case 'BsPersonLinesFill':
                return (<BsPersonLinesFill color="#E30614" size="36" />)
            case 'MdOutlinePriorityHigh':
                return (<MdOutlinePriorityHigh color="#E30614" size="36" />)
            case 'BsBank':
                return (<BsBank color="#E30614" size="36" />)
            case "TbReportMoney":
                return (<TbReportMoney color="#E30614" size="36" />)
            case "AiOutlineWarning":
                return (<AiOutlineWarning color="#E30614" size="36" />)
            case "FaUmbrellaBeach":
                return (<FaUmbrellaBeach color="#E30614" size="36" />)
            case "AiOutlineFileSearch":
                return (<AiOutlineFileSearch color="#E30614" size="36" />)
            case "AiOutlineAudit":
                return (<AiOutlineAudit color="#E30614" size="36" />)
            case "BsFileMedical":
                return (<BsFileMedical color="#E30614" size="36" />)
            case "TbRelationManyToMany":
                return (<TbRelationManyToMany color="#E30614" size="36" />)
            case "TbFileDiff":
                return (<TbFileDiff color="#E30614" size="36" />)
            case "BsCardChecklist":
                return (<BsCardChecklist color="#E30614" size="36" />)
            case "BsListCheck":
                return (<BsListCheck color="#E30614" size="36" />)
            case "AiOutlineBorderInner":
                return (<AiOutlineBorderInner color="#E30614" size="36" />)
            case "MdCloud":
                return (<MdCloud color="#E30614" size="36" />)
            default:
                break
        }

    }

    function RenderizarMenu() {

        if (props.titulo === 'Notas Fiscais') {

            return (

                menuNotasFiscais.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Gestão de Solicitações') {

            return (

                menuGestaoSolicitacoes.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }
        if (props.titulo === 'Auditor Fiscal') {

            return (

                menuAuditorFiscal.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Diferencial de Alíquota') {

            return (

                menuDiferencialAliquota.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Movimento Bancário') {

            return (

                menuMovimentoBancario.map((menu) => {
                    return (
                        <Col key={menu.identificador} lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Folha de Pagamento') {

            return (

                menuFolhaPgto.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Impostos') {

            return (

                menuImpostos.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo === 'Mais Opções' && tipoAcesso === 'USUARIO') {

            return (

                menuMaisOpcoes.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }
        if (props.titulo === 'Mais Opções' && tipoAcesso === 'OPERADOR') {

            return (

                menuMaisOpcoesOperador.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

    }

    function ExecutaAcao(identificador) {
        switch (identificador) {
            case 'como_emitir_nota_fiscal':
                alert('Iniciando Consulta de Nota Fiscal')
                alert('OK?')
                break;
            case 'enviar_extrato':
                Navigate('/movimento-bancario/importar-extrato-ofx')
                break;
            case 'contas_bancarias':
                Navigate('/movimento-bancario/contas-bancarias')
                break;
            case 'solicitacoes':
                Navigate('/departamento-pessoal')
                break;
            case 'consultas':
                Navigate('/mais-opcoes/consultas')
                break;
            // case 'listar_funcionarios':
            //     {
            //         Navigate('/departamento-pessoal/lista-funcionarios')
            //     }
            //     break;
            case 'rh_admissoes':
                Navigate('/departamento-pessoal/admissoes')
                break;
            case 'solicitacoes_operador':
                Navigate('/adm/solicitacoes/minhas-solicitacoes', { state: { statusRecebido: 'PENDENTE OPERADOR' } })
                break;
            case 'solicitacoes_categorias':
                Navigate('/adm/solicitacoes/categorias')
                break;
            case 'solicitacoes_prioridades':
                Navigate('/adm/solicitacoes/prioridades')
                break;
            case 'solicitacoes_regras':
                Navigate('/adm/solicitacoes/regras')
                break;
            case 'rh_rescisao':
                Navigate('/departamento-pessoal/rescisoes')
                break;
            case 'rh_acidente':
                Navigate('/departamento-pessoal/acidentes')
                break;
            case 'rh_ferias':
                Navigate('/departamento-pessoal/ferias')
                break;
            case 'rh_atestados':
                Navigate('/departamento-pessoal/atestados')
                break;
            case 'guias_fiscais':
                Navigate(`/impostos/guias-fiscais/${new Date().toLocaleDateString('pt-BR', { month: 'long' })}/${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                break;
            case 'consultar_folha':
                Navigate(`/departamento-pessoal/consultar-folha/${new Date().toLocaleDateString('pt-BR', { month: 'long' })}/${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                break;
            case 'auditor-fiscal':
                Navigate('/mais-opcoes/auditor_fiscal')
                break;
            case 'arquivos':
                Navigate(`/adm/arquivos?detalhe=aquisicao_mercadorias&mes=${new Date().toLocaleDateString('pt-BR', { month: 'long' })}&ano=${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                break;
            case 'auditor_fiscal_entradas_nao_lancadas':
                Navigate(`/mais-opcoes/auditor_fiscal/entradas_nao_lancadas`)
                break;
            case 'auditor_fiscal_relacionamento_cfop_produto':
                Navigate(`/mais-opcoes/auditor_fiscal/cfop_produtos`)
                break;
            case 'auditor_fiscal_diferencial_aliquota':
                Navigate(`/mais-opcoes/auditor_fiscal/diferencial_aliquota/`)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Navegacao />
            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80 }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>{props.titulo}</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size="sm"
                                    onClick={() => {
                                        switch (props.titulo) {
                                            case 'Notas Fiscais':

                                                alert('Ajuda para Notas Fiscais')

                                                break;
                                            case 'Impostos':

                                                alert('Ajuda para Impostos')

                                                break;
                                            case 'Folha de Pagamento':

                                                alert('Ajuda para Folha de Pagamento')

                                                break;
                                            case 'Movimento Bancário':

                                                alert('Ajuda para Movimento Bancário')

                                                break;
                                            case 'Diferencial de Alíquota':

                                               Navigate('/mais-opcoes/auditor_fiscal')

                                                break;
                                            default:
                                                break
                                        }
                                    }}
                                >
                                    {props.titulo == 'Diferencial de Alíquota' ? 'Voltar' : 'Obter Ajuda'}
                                </Button>
                            </Col>
                        </Row>


                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >


                        <Row>
                            <RenderizarMenu />
                        </Row>

                    </div>

                </Container>
                <Footer />
            </div>

        </>

    )

}

export default PaginaDetalhe