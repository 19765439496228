import { ErrorMessage, Field } from "formik";
import React from "react";
import {
  Alert,
  Col,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import {
  MdDelete,
  MdFileDownload,
  MdFileUpload,
  MdSearch,
} from "react-icons/md";
import { NumericFormat } from "react-number-format";

export function InputFieldPadrao(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        type={props.tipo}
        className="form-control"
        style={{
          textTransform: props.tipo === "text" ? "capitalize" : "none",
        }}
        onBlur={props.blur}
        disabled={props.disabled}
        placeHolder={props.place}
      />
    </>
  );
}
export function InputFieldTelefone(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        type={props.tipo}
        className="form-control"
        onChange={(valor) => props.mudar(valor)}
        style={{
          textTransform: props.tipo === "text" ? "capitalize" : "none",
        }}
        onBlur={props.blur}
        disabled={props.disabled}
        placeHolder={props.place}
      />
    </>
  );
}
export function InputFieldMoeda(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        render={({ field }) => (
          <NumericFormat
            {...field}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="R$ "
            className="form-control"
            disabled={props.disabled}
          />
        )}
        type={"number"}
        onBlur={props.blur}
      />
    </>
  );
}
export function InputFieldPercentual(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        render={({ field }) => (
          <NumericFormat
            {...field}
            suffix="%"
            decimalScale={0}
            fixedDecimalScale={true}
            maxLength={4}
            className="form-control"
            disabled={props.disabled}
          />
        )}
        type={"number"}
        onBlur={props.blur}
      />
    </>
  );
}

export function InputFieldTextArea(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        disabled={props.disabled}
        name={props.campo}
        type={props.tipo}
        as="textarea"
        rows={props.linhas}
        className="form-control"
        style={{ textTransform: props.tipo === "text" ? "capitalize" : "none" }}
        onBlur={props.blur}
      />
    </>
  );
}

export function CheckFieldPadrao(props) {
  return (
    <>
      <label>
        <Field
          type="checkbox"
          name={props.campo}
          disabled={props.disabled}
          className="form-check-input"
        />
        &nbsp;&nbsp;&nbsp;{props.children}
      </label>
    </>
  );
}

export function SelectFieldPadrao(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        as="select"
        className="form-select"
        disabled={props.disabled}
      >
        {props.children}
      </Field>
    </>
  );
}
export function SelectFieldOnChange(props) {
  return (
    <>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        onChange={(valor) => props.mudar(valor)}
        as="select"
        className="form-select"
        disabled={props.disabled}
      >
        {props.children}
      </Field>
    </>
  );
}

export function FileFieldPadrao(props) {
  return (
    <div>
      <label htmlFor={props.campo} className="form-label">
        {props.texto}
      </label>
      <Field
        id={props.campo}
        name={props.campo}
        type={props.tipo}
        className="form-control"
        accept={props.accept}
      />
    </div>
  );
}

export function ErroField(props) {
  return (
    <ErrorMessage
      name={props.campo}
      render={(msg) => (
        <div
          style={{ color: "red", fontSize: 10, paddingTop: 5, paddingLeft: 2 }}
        >
          * {msg}
        </div>
      )}
    />
  );
}
export function DocumentoField(props) {
  return (
    <Col
      style={{
        backgroundColor: "white",
        width: 200,
        height: 120,
        borderRadius: 15,
        padding: 15,
      }}
      className="form-control"
    >
      <Row>
        <Col>
          <strong>{props.nome}</strong>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={6}>
          <span>{props.status}</span>
        </Col>
        {props.status == "Pendente" && !props.disabled && (
          <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
            <OverlayTrigger
              trigger="focus"
              placement="top"
              overlay={<Tooltip id="tooltip_enviar">Enviar Arquivo</Tooltip>}
            >
              <label htmlFor={props.campo} className="form-label">
                <div
                  style={{
                    backgroundColor: "#E30613",
                    border: 0,
                    height: 30,
                    width: 32,
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <MdFileUpload color="white" />
                </div>
              </label>
            </OverlayTrigger>
            <Field
              id={props.campo}
              name={props.campo}
              type={"file"}
              className="form-control"
              accept="image/png, image/jpeg, application/pdf"
              onChange={props.onChange}
              value={null}
              style={{ display: "none" }}
            />
          </Col>
        )}
        {props.status !== "Pendente" && (
          <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
            <OverlayTrigger
              trigger="focus"
              placement="bottom"
              overlay={<Tooltip id="tooltip_baixar">Baixar Arquivo</Tooltip>}
            >
              <div
                style={{
                  backgroundColor: "#E30613",
                  border: 0,
                  height: 30,
                  width: 32,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={props.clickDownload}
              >
                <MdFileDownload color="white" />
              </div>
            </OverlayTrigger>
          </Col>
        )}
        {props.status !== "Pendente" && !props.disabled && (
          <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
            <OverlayTrigger
              trigger="focus"
              placement="bottom"
              overlay={<Tooltip id="tooltip_remover">Remover Arquivo</Tooltip>}
            >
              <div
                style={{
                  backgroundColor: "#E30613",
                  border: 0,
                  height: 30,
                  width: 32,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={props.clickRemove}
              >
                <MdDelete color="white" />
              </div>
            </OverlayTrigger>
          </Col>
        )}
      </Row>
    </Col>
  );
}

export function AlertaErro(props) {
  return (
    <Alert key="danger" variant="danger">
      <strong>{props.nome}</strong> {props.campo}
    </Alert>
  );
}

export function InputLegenda(props) {
  return (
    <InputGroup className="mb-0">
      <InputGroup.Text
        id="inputGroup-sizing-default"
        style={{
          minWidth: 200,
          backgroundColor: "white",
          color: "#D3352D",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {props.descricao}{" "}
        {!props.disabled &&
          <MdSearch
            color="#D3352D"
            style={{ cursor: "pointer" }}
            onClick={props.onClick}
          />
        }
      </InputGroup.Text>
      <Field
        id={props.campo}
        name={props.campo}
        type="text"
        className="form-control"
        disabled
      />
    </InputGroup>
  );
}
export function InputHora(props) {
  return (
    <>
      <label htmlFor={props.campoHora} className="form-label">
        {props.texto}
      </label>
      <div style={{ display: "flex" }}>
        <InputGroup className="mb-3">
          <Field
            id={props.campoHora}
            name={props.campoHora}
            type="text"
            className="form-control"
            style={{ maxWidth: 70 }}
            disabled={props.disabled}
          />
          <InputGroup.Text
            id="inputGroup-sizing-default"
            style={{ display: "flex" }}
          >
            Horas
          </InputGroup.Text>
        </InputGroup>
        <InputGroup className="mb-3">
          <Field
            id={props.campoMinuto}
            name={props.campoMinuto}
            type="text"
            maxLength={2}
            className="form-control"
            style={{ maxWidth: 70 }}
            disabled={props.disabled}
          />
          <InputGroup.Text
            id="inputGroup-sizing-default"
            style={{ display: "flex" }}
          >
            Minutos
          </InputGroup.Text>
        </InputGroup>
      </div>
    </>
  );
}
