import React from "react";
import JoditEditor from 'jodit-react';
import { useRef, useState } from 'react';

const TextoEditor = (props) => {

    const { content, setContent } = props

    const editor = useRef(null);
    /// const [content, setContent] = useState('');


    const config = {
        readonly: false,
        height: "20rem",
        width: "80rem",
        padding: "2rem",

        buttonsMD: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],

    };

    return (
        <JoditEditor
            ref={editor}
            value={content.conteudo}
            config={config}
            tabIndex={1} // tabIndex of textarea
            // onBlur={newContent => console.log(newContent)} // preferencia usar apenas esse
            onBlur={(newContent) => { setContent({ ...content, conteudo: newContent }) }}
        />
    );
}

export { TextoEditor };