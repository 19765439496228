export const planilhaBancos = [
    {
     "codBanco": 1,
     "nomeBanco": "BANCO DO BRASIL S.A (BB)",
     "codISPB": 0
    },
    {
     "codBanco": 3,
     "nomeBanco": "BANCO DA AMAZONIA S.A",
     "codISPB": 4902979
    },
    {
     "codBanco": 4,
     "nomeBanco": "BANCO DO NORDESTE DO BRASIL S.A.",
     "codISPB": 7237373
    },
    {
     "codBanco": 7,
     "nomeBanco": "BNDES (BANCO NACIONAL DO DESENVOLVIMENTO SOCIAL)",
     "codISPB": 33657248
    },
    {
     "codBanco": 10,
     "nomeBanco": "CREDICOAMO",
     "codISPB": 81723108
    },
    {
     "codBanco": 11,
     "nomeBanco": "C.SUISSE HEDGING-GRIFFO CV S.A (CREDIT SUISSE)",
     "codISPB": 61809182
    },
    {
     "codBanco": 12,
     "nomeBanco": "BANCO INBURSA",
     "codISPB": 4866275
    },
    {
     "codBanco": 14,
     "nomeBanco": "NATIXIS BRASIL S.A",
     "codISPB": 9274232
    },
    {
     "codBanco": 15,
     "nomeBanco": "UBS BRASIL CCTVM S.A",
     "codISPB": 2819125
    },
    {
     "codBanco": 16,
     "nomeBanco": "CCM DESP TRÂNS SC E RS",
     "codISPB": 4715685
    },
    {
     "codBanco": 17,
     "nomeBanco": "BNY MELLON BANCO S.A",
     "codISPB": 42272526
    },
    {
     "codBanco": 18,
     "nomeBanco": "BANCO TRICURY S.A",
     "codISPB": 57839805
    },
    {
     "codBanco": 21,
     "nomeBanco": "BANCO BANESTES S.A",
     "codISPB": 28127603
    },
    {
     "codBanco": 24,
     "nomeBanco": "BCO BANDEPE S.A",
     "codISPB": 10866788
    },
    {
     "codBanco": 25,
     "nomeBanco": "BANCO ALFA S.A.",
     "codISPB": 3323840
    },
    {
     "codBanco": 29,
     "nomeBanco": "BANCO ITAÚ CONSIGNADO S.A",
     "codISPB": 33885724
    },
    {
     "codBanco": 33,
     "nomeBanco": "BANCO SANTANDER BRASIL S.A",
     "codISPB": 90400888
    },
    {
     "codBanco": 36,
     "nomeBanco": "BANCO BBI S.A",
     "codISPB": 6271464
    },
    {
     "codBanco": 37,
     "nomeBanco": "BANCO DO ESTADO DO PARÁ S.A",
     "codISPB": 4913711
    },
    {
     "codBanco": 40,
     "nomeBanco": "BANCO CARGILL S.A",
     "codISPB": 3609817
    },
    {
     "codBanco": 41,
     "nomeBanco": "BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
     "codISPB": 92702067
    },
    {
     "codBanco": 47,
     "nomeBanco": "BANCO BCO DO ESTADO DE SERGIPE S.A",
     "codISPB": 13009717
    },
    {
     "codBanco": 60,
     "nomeBanco": "CONFIDENCE CC S.A",
     "codISPB": 4913129
    },
    {
     "codBanco": 62,
     "nomeBanco": "HIPERCARD BM S.A",
     "codISPB": 3012230
    },
    {
     "codBanco": 63,
     "nomeBanco": "BANCO BRADESCARD",
     "codISPB": 4184779
    },
    {
     "codBanco": 64,
     "nomeBanco": "GOLDMAN SACHS DO BRASIL BM S.A",
     "codISPB": 4332281
    },
    {
     "codBanco": 65,
     "nomeBanco": "BANCO ANDBANK S.A",
     "codISPB": 48795256
    },
    {
     "codBanco": 66,
     "nomeBanco": "BANCO MORGAN STANLEY S.A",
     "codISPB": 2801938
    },
    {
     "codBanco": 69,
     "nomeBanco": "BANCO CREFISA S.A",
     "codISPB": 61033106
    },
    {
     "codBanco": 70,
     "nomeBanco": "BANCO DE BRASÍLIA (BRB)",
     "codISPB": 208
    },
    {
     "codBanco": 74,
     "nomeBanco": "BCO. J.SAFRA S.A",
     "codISPB": 3017677
    },
    {
     "codBanco": 75,
     "nomeBanco": "BCO ABN AMRO S.A",
     "codISPB": 3532415
    },
    {
     "codBanco": 76,
     "nomeBanco": "BANCO KDB BRASIL S.A.",
     "codISPB": 7656500
    },
    {
     "codBanco": 77,
     "nomeBanco": "BANCO INTER S.A",
     "codISPB": 416968
    },
    {
     "codBanco": 78,
     "nomeBanco": "HAITONG BI DO BRASIL S.A",
     "codISPB": 34111187
    },
    {
     "codBanco": 79,
     "nomeBanco": "BANCO ORIGINAL DO AGRONEGÓCIO S.A",
     "codISPB": 9516419
    },
    {
     "codBanco": 80,
     "nomeBanco": "B&T CC LTDA",
     "codISPB": 73622748
    },
    {
     "codBanco": 81,
     "nomeBanco": "BBN BANCO BRASILEIRO DE NEGOCIOS S.A",
     "codISPB": 10264663
    },
    {
     "codBanco": 82,
     "nomeBanco": "BANCO TOPÁZIO S.A",
     "codISPB": 7679404
    },
    {
     "codBanco": 83,
     "nomeBanco": "BANCO DA CHINA BRASIL S.A",
     "codISPB": 10690848
    },
    {
     "codBanco": 84,
     "nomeBanco": "UNIPRIME NORTE DO PARANÁ",
     "codISPB": 2398976
    },
    {
     "codBanco": 85,
     "nomeBanco": "COOP CENTRAL AILOS",
     "codISPB": 5463212
    },
    {
     "codBanco": 89,
     "nomeBanco": "CCR REG MOGIANA",
     "codISPB": 62109566
    },
    {
     "codBanco": 91,
     "nomeBanco": "UNICRED CENTRAL RS",
     "codISPB": 1634601
    },
    {
     "codBanco": 92,
     "nomeBanco": "BRK S.A",
     "codISPB": 12865507
    },
    {
     "codBanco": 93,
     "nomeBanco": "PÓLOCRED SCMEPP LTDA",
     "codISPB": 7945233
    },
    {
     "codBanco": 94,
     "nomeBanco": "BANCO FINAXIS",
     "codISPB": 11758741
    },
    {
     "codBanco": 95,
     "nomeBanco": "BANCO CONFIDENCE DE CÂMBIO S.A",
     "codISPB": 11703662
    },
    {
     "codBanco": 96,
     "nomeBanco": "BANCO B3 S.A",
     "codISPB": 997185
    },
    {
     "codBanco": 97,
     "nomeBanco": "CCC NOROESTE BRASILEIRO LTDA",
     "codISPB": 4632856
    },
    {
     "codBanco": 98,
     "nomeBanco": "CREDIALIANÇA CCR",
     "codISPB": 78157146
    },
    {
     "codBanco": 99,
     "nomeBanco": "UNIPRIME CENTRAL CCC LTDA",
     "codISPB": 3046391
    },
    {
     "codBanco": 100,
     "nomeBanco": "PLANNER CORRETORA DE VALORES S.A",
     "codISPB": 806535
    },
    {
     "codBanco": 101,
     "nomeBanco": "RENASCENCA DTVM LTDA",
     "codISPB": 62287735
    },
    {
     "codBanco": 102,
     "nomeBanco": "XP INVESTIMENTOS S.A",
     "codISPB": 2332886
    },
    {
     "codBanco": 104,
     "nomeBanco": "CAIXA ECONÔMICA FEDERAL (CEF)",
     "codISPB": 360305
    },
    {
     "codBanco": 105,
     "nomeBanco": "LECCA CFI S.A",
     "codISPB": 7652226
    },
    {
     "codBanco": 107,
     "nomeBanco": "BANCO BOCOM BBM S.A",
     "codISPB": 15114366
    },
    {
     "codBanco": 108,
     "nomeBanco": "PORTOCRED S.A",
     "codISPB": 1800019
    },
    {
     "codBanco": 111,
     "nomeBanco": "BANCO OLIVEIRA TRUST DTVM S.A",
     "codISPB": 36113876
    },
    {
     "codBanco": 113,
     "nomeBanco": "MAGLIANO S.A",
     "codISPB": 61723847
    },
    {
     "codBanco": 114,
     "nomeBanco": "CENTRAL COOPERATIVA DE CRÉDITO NO",
     "codISPB": 5790149
    },
    {
     "codBanco": 117,
     "nomeBanco": "ADVANCED CC LTDA",
     "codISPB": 92856905
    },
    {
     "codBanco": 118,
     "nomeBanco": "STANDARD CHARTERED BI S.A",
     "codISPB": 11932017
    },
    {
     "codBanco": 119,
     "nomeBanco": "BANCO WESTERN UNION",
     "codISPB": 13720915
    },
    {
     "codBanco": 120,
     "nomeBanco": "BANCO RODOBENS S.A",
     "codISPB": 33603457
    },
    {
     "codBanco": 121,
     "nomeBanco": "BANCO AGIBANK S.A",
     "codISPB": 10664513
    },
    {
     "codBanco": 122,
     "nomeBanco": "BANCO BRADESCO BERJ S.A",
     "codISPB": 33147315
    },
    {
     "codBanco": 124,
     "nomeBanco": "BANCO WOORI BANK DO BRASIL S.A",
     "codISPB": 15357060
    },
    {
     "codBanco": 125,
     "nomeBanco": "BRASIL PLURAL S.A BANCO",
     "codISPB": 45246410
    },
    {
     "codBanco": 126,
     "nomeBanco": "BR PARTNERS BI",
     "codISPB": 13220493
    },
    {
     "codBanco": 127,
     "nomeBanco": "CODEPE CVC S.A",
     "codISPB": 9512542
    },
    {
     "codBanco": 128,
     "nomeBanco": "MS BANK S.A BANCO DE CÂMBIO",
     "codISPB": 19307785
    },
    {
     "codBanco": 129,
     "nomeBanco": "UBS BRASIL BI S.A",
     "codISPB": 18520834
    },
    {
     "codBanco": 130,
     "nomeBanco": "CARUANA SCFI",
     "codISPB": 9313766
    },
    {
     "codBanco": 131,
     "nomeBanco": "TULLETT PREBON BRASIL CVC LTDA",
     "codISPB": 61747085
    },
    {
     "codBanco": 132,
     "nomeBanco": "ICBC DO BRASIL BM S.A",
     "codISPB": 17453575
    },
    {
     "codBanco": 133,
     "nomeBanco": "CRESOL CONFEDERAÇÃO",
     "codISPB": 10398952
    },
    {
     "codBanco": 134,
     "nomeBanco": "BGC LIQUIDEZ DTVM LTDA",
     "codISPB": 33862244
    },
    {
     "codBanco": 136,
     "nomeBanco": "UNICRED COOPERATIVA",
     "codISPB": 315557
    },
    {
     "codBanco": 137,
     "nomeBanco": "MULTIMONEY CC LTDA",
     "codISPB": 12586596
    },
    {
     "codBanco": 138,
     "nomeBanco": "GET MONEY CC LTDA",
     "codISPB": 10853017
    },
    {
     "codBanco": 139,
     "nomeBanco": "INTESA SANPAOLO BRASIL S.A",
     "codISPB": 55230916
    },
    {
     "codBanco": 140,
     "nomeBanco": "EASYNVEST – TÍTULO CV S.A",
     "codISPB": 62169875
    },
    {
     "codBanco": 142,
     "nomeBanco": "BROKER BRASIL CC LTDA",
     "codISPB": 16944141
    },
    {
     "codBanco": 143,
     "nomeBanco": "TREVISO CC S.A",
     "codISPB": 2992317
    },
    {
     "codBanco": 144,
     "nomeBanco": "BEXS BANCO DE CAMBIO S.A.",
     "codISPB": 13059145
    },
    {
     "codBanco": 145,
     "nomeBanco": "LEVYCAM CCV LTDA",
     "codISPB": 50579044
    },
    {
     "codBanco": 146,
     "nomeBanco": "GUITTA CC LTDA",
     "codISPB": 24074692
    },
    {
     "codBanco": 149,
     "nomeBanco": "FACTA S.A. CFI",
     "codISPB": 15581638
    },
    {
     "codBanco": 157,
     "nomeBanco": "ICAP DO BRASIL CTVM LTDA",
     "codISPB": 9105360
    },
    {
     "codBanco": 159,
     "nomeBanco": "CASA CREDITO S.A",
     "codISPB": 5442029
    },
    {
     "codBanco": 163,
     "nomeBanco": "COMMERZBANK BRASIL S.A BANCO MÚLTIPLO",
     "codISPB": 23522214
    },
    {
     "codBanco": 169,
     "nomeBanco": "BANCO OLÉ BONSUCESSO CONSIGNADO S.A",
     "codISPB": 71371686
    },
    {
     "codBanco": 172,
     "nomeBanco": "ALBATROSS CCV S.A",
     "codISPB": 5452073
    },
    {
     "codBanco": 173,
     "nomeBanco": "BRL TRUST DTVM SA",
     "codISPB": 13486793
    },
    {
     "codBanco": 174,
     "nomeBanco": "PERNAMBUCANAS FINANC S.A",
     "codISPB": 43180355
    },
    {
     "codBanco": 177,
     "nomeBanco": "GUIDE",
     "codISPB": 65913436
    },
    {
     "codBanco": 180,
     "nomeBanco": "CM CAPITAL MARKETS CCTVM LTDA",
     "codISPB": 2685483
    },
    {
     "codBanco": 182,
     "nomeBanco": "DACASA FINANCEIRA S\/A",
     "codISPB": 27406222
    },
    {
     "codBanco": 183,
     "nomeBanco": "SOCRED S.A",
     "codISPB": 9210106
    },
    {
     "codBanco": 184,
     "nomeBanco": "BANCO ITAÚ BBA S.A",
     "codISPB": 17298092
    },
    {
     "codBanco": 188,
     "nomeBanco": "ATIVA S.A INVESTIMENTOS",
     "codISPB": 33775974
    },
    {
     "codBanco": 189,
     "nomeBanco": "HS FINANCEIRA",
     "codISPB": 7512441
    },
    {
     "codBanco": 190,
     "nomeBanco": "SERVICOOP",
     "codISPB": 3973814
    },
    {
     "codBanco": 191,
     "nomeBanco": "NOVA FUTURA CTVM LTDA",
     "codISPB": 4257795
    },
    {
     "codBanco": 194,
     "nomeBanco": "PARMETAL DTVM LTDA",
     "codISPB": 20155248
    },
    {
     "codBanco": 196,
     "nomeBanco": "BANCO FAIR CC S.A",
     "codISPB": 32648370
    },
    {
     "codBanco": 197,
     "nomeBanco": "STONE PAGAMENTOS S.A",
     "codISPB": 16501555
    },
    {
     "codBanco": 204,
     "nomeBanco": "BANCO BRADESCO CARTOES S.A",
     "codISPB": 59438325
    },
    {
     "codBanco": 208,
     "nomeBanco": "BANCO BTG PACTUAL S.A",
     "codISPB": 30306294
    },
    {
     "codBanco": 212,
     "nomeBanco": "BANCO ORIGINAL S.A",
     "codISPB": 92894922
    },
    {
     "codBanco": 213,
     "nomeBanco": "BCO ARBI S.A",
     "codISPB": 54403563
    },
    {
     "codBanco": 217,
     "nomeBanco": "BANCO JOHN DEERE S.A",
     "codISPB": 91884981
    },
    {
     "codBanco": 218,
     "nomeBanco": "BANCO BS2 S.A",
     "codISPB": 71027866
    },
    {
     "codBanco": 222,
     "nomeBanco": "BANCO CRÉDIT AGRICOLE BR S.A",
     "codISPB": 75647891
    },
    {
     "codBanco": 224,
     "nomeBanco": "BANCO FIBRA S.A",
     "codISPB": 58616418
    },
    {
     "codBanco": 233,
     "nomeBanco": "BANCO CIFRA",
     "codISPB": 62421979
    },
    {
     "codBanco": 237,
     "nomeBanco": "BRADESCO S.A",
     "codISPB": 60746948
    },
    {
     "codBanco": 237,
     "nomeBanco": "NEXT BANK (FINTECH DO  BANCO BRADESCO)",
     "codISPB": 60746948
    },
    {
     "codBanco": 241,
     "nomeBanco": "BANCO CLASSICO S.A",
     "codISPB": 31597552
    },
    {
     "codBanco": 243,
     "nomeBanco": "BANCO MÁXIMA S.A",
     "codISPB": 33923798
    },
    {
     "codBanco": 246,
     "nomeBanco": "BANCO ABC BRASIL S.A",
     "codISPB": 28195667
    },
    {
     "codBanco": 249,
     "nomeBanco": "BANCO INVESTCRED UNIBANCO S.A",
     "codISPB": 61182408
    },
    {
     "codBanco": 250,
     "nomeBanco": "BANCO BCV",
     "codISPB": 50585090
    },
    {
     "codBanco": 253,
     "nomeBanco": "BEXS CC S.A",
     "codISPB": 52937216
    },
    {
     "codBanco": 254,
     "nomeBanco": "PARANA BANCO S.A",
     "codISPB": 14388334
    },
    {
     "codBanco": 260,
     "nomeBanco": "NU PAGAMENTOS S.A (NUBANK)",
     "codISPB": 18236120
    },
    {
     "codBanco": 265,
     "nomeBanco": "BANCO FATOR S.A",
     "codISPB": 33644196
    },
    {
     "codBanco": 266,
     "nomeBanco": "BANCO CEDULA S.A",
     "codISPB": 33132044
    },
    {
     "codBanco": 268,
     "nomeBanco": "BARIGUI CH",
     "codISPB": 14511781
    },
    {
     "codBanco": 269,
     "nomeBanco": "HSBC BANCO DE INVESTIMENTO",
     "codISPB": 53518684
    },
    {
     "codBanco": 270,
     "nomeBanco": "SAGITUR CC LTDA",
     "codISPB": 61444949
    },
    {
     "codBanco": 271,
     "nomeBanco": "IB CCTVM LTDA",
     "codISPB": 27842177
    },
    {
     "codBanco": 273,
     "nomeBanco": "CCR DE SÃO MIGUEL DO OESTE",
     "codISPB": 8253539
    },
    {
     "codBanco": 276,
     "nomeBanco": "SENFF S.A",
     "codISPB": 11970623
    },
    {
     "codBanco": 278,
     "nomeBanco": "GENIAL INVESTIMENTOS CVM S.A",
     "codISPB": 27652684
    },
    {
     "codBanco": 279,
     "nomeBanco": "CCR DE PRIMAVERA DO LESTE",
     "codISPB": 26563270
    },
    {
     "codBanco": 280,
     "nomeBanco": "AVISTA S.A",
     "codISPB": 23862762
    },
    {
     "codBanco": 283,
     "nomeBanco": "RB CAPITAL INVESTIMENTOS DTVM LTDA",
     "codISPB": 89960090
    },
    {
     "codBanco": 285,
     "nomeBanco": "FRENTE CC LTDA",
     "codISPB": 71677850
    },
    {
     "codBanco": 286,
     "nomeBanco": "CCR DE OURO",
     "codISPB": 7853842
    },
    {
     "codBanco": 288,
     "nomeBanco": "CAROL DTVM LTDA",
     "codISPB": 62237649
    },
    {
     "codBanco": 290,
     "nomeBanco": "PAGSEGURO INTERNET S.A (PAGBANK",
     "codISPB": 8561701
    },
    {
     "codBanco": 292,
     "nomeBanco": "BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS",
     "codISPB": 28650236
    },
    {
     "codBanco": 293,
     "nomeBanco": "LASTRO RDV DTVM LTDA",
     "codISPB": 71590442
    },
    {
     "codBanco": 298,
     "nomeBanco": "VIPS CC LTDA",
     "codISPB": 17772370
    },
    {
     "codBanco": 300,
     "nomeBanco": "BANCO LA NACION ARGENTINA",
     "codISPB": 33042151
    },
    {
     "codBanco": 301,
     "nomeBanco": "BPP INSTITUIÇÃO DE PAGAMENTOS S.A",
     "codISPB": 13370835
    },
    {
     "codBanco": 310,
     "nomeBanco": "VORTX DTVM LTDA",
     "codISPB": 22610500
    },
    {
     "codBanco": 318,
     "nomeBanco": "BANCO BMG S.A",
     "codISPB": 61186680
    },
    {
     "codBanco": 320,
     "nomeBanco": "BANCO CCB BRASIL S.A",
     "codISPB": 7450604
    },
    {
     "codBanco": 323,
     "nomeBanco": "MERCADO PAGO (CARTEIRA DIGITAL)",
     "codISPB": 10573521
    },
    {
     "codBanco": 335,
     "nomeBanco": "BANCO DIGIO S.A",
     "codISPB": 27098060
    },
    {
     "codBanco": 336,
     "nomeBanco": "BANCO C6 S.A – C6 BANK",
     "codISPB": 28326000
    },
    {
     "codBanco": 341,
     "nomeBanco": "ITAÚ UNIBANCO S.A (OS MESMOS DADOS PARA A CONTA ITI)",
     "codISPB": 60701190
    },
    {
     "codBanco": 366,
     "nomeBanco": "BANCO SOCIETE GENERALE BRASIL",
     "codISPB": 61533584
    },
    {
     "codBanco": 370,
     "nomeBanco": "BANCO MIZUHO S.A",
     "codISPB": 61088183
    },
    {
     "codBanco": 376,
     "nomeBanco": "BANCO J.P. MORGAN S.A",
     "codISPB": 33172537
    },
    {
     "codBanco": 380,
     "nomeBanco": "PICPAY SERVIÇOS S.A.",
     "codISPB": 22896431
    },
    {
     "codBanco": 389,
     "nomeBanco": "BANCO MERCANTIL DO BRASIL S.A",
     "codISPB": 17184037
    },
    {
     "codBanco": 389,
     "nomeBanco": "BANCO MERCANTIL DO BRASIL S.A.",
     "codISPB": 17184037
    },
    {
     "codBanco": 394,
     "nomeBanco": "BANCO BRADESCO FINANCIAMENTOS S.A",
     "codISPB": 7207996
    },
    {
     "codBanco": 399,
     "nomeBanco": "KIRTON BANK",
     "codISPB": 1701201
    },
    {
     "codBanco": 412,
     "nomeBanco": "BANCO CAPITAL S.A",
     "codISPB": 15173776
    },
    {
     "codBanco": 413,
     "nomeBanco": "BANCO VOTORANTIM S.A",
     "codISPB": 59588111
    },
    {
     "codBanco": 422,
     "nomeBanco": "BANCO SAFRA S.A",
     "codISPB": 58160789
    },
    {
     "codBanco": 456,
     "nomeBanco": "BANCO MUFG BRASIL S.A",
     "codISPB": 60498557
    },
    {
     "codBanco": 464,
     "nomeBanco": "BANCO SUMITOMO MITSUI BRASIL S.A",
     "codISPB": 60518222
    },
    {
     "codBanco": 473,
     "nomeBanco": "BANCO CAIXA GERAL BRASIL S.A",
     "codISPB": 33466988
    },
    {
     "codBanco": 477,
     "nomeBanco": "CITIBANK N.A",
     "codISPB": 33042953
    },
    {
     "codBanco": 479,
     "nomeBanco": "BANCO ITAUBANK S.A",
     "codISPB": 60394079
    },
    {
     "codBanco": 487,
     "nomeBanco": "DEUTSCHE BANK S.A BANCO ALEMÃO",
     "codISPB": 62331228
    },
    {
     "codBanco": 488,
     "nomeBanco": "JPMORGAN CHASE BANK",
     "codISPB": 46518205
    },
    {
     "codBanco": 492,
     "nomeBanco": "ING BANK N.V",
     "codISPB": 49336860
    },
    {
     "codBanco": 494,
     "nomeBanco": "BANCO REP ORIENTAL URUGUAY",
     "codISPB": 51938876
    },
    {
     "codBanco": 495,
     "nomeBanco": "LA PROVINCIA BUENOS AIRES BANCO",
     "codISPB": 44189447
    },
    {
     "codBanco": 505,
     "nomeBanco": "BANCO CREDIT SUISSE (BRL) S.A",
     "codISPB": 32062580
    },
    {
     "codBanco": 545,
     "nomeBanco": "SENSO CCVM S.A",
     "codISPB": 17352220
    },
    {
     "codBanco": 600,
     "nomeBanco": "BANCO LUSO BRASILEIRO S.A",
     "codISPB": 59118133
    },
    {
     "codBanco": 604,
     "nomeBanco": "BANCO INDUSTRIAL DO BRASIL S.A",
     "codISPB": 31895683
    },
    {
     "codBanco": 610,
     "nomeBanco": "BANCO VR S.A",
     "codISPB": 78626983
    },
    {
     "codBanco": 611,
     "nomeBanco": "BANCO PAULISTA",
     "codISPB": 61820817
    },
    {
     "codBanco": 612,
     "nomeBanco": "BANCO GUANABARA S.A",
     "codISPB": 31880826
    },
    {
     "codBanco": 613,
     "nomeBanco": "OMNI BANCO S.A",
     "codISPB": 60850229
    },
    {
     "codBanco": 623,
     "nomeBanco": "BANCO PAN",
     "codISPB": 59285411
    },
    {
     "codBanco": 626,
     "nomeBanco": "BANCO FICSA S.A",
     "codISPB": 61348538
    },
    {
     "codBanco": 630,
     "nomeBanco": "BANCO INTERCAP S.A",
     "codISPB": 58497702
    },
    {
     "codBanco": 633,
     "nomeBanco": "BANCO RENDIMENTO S.A",
     "codISPB": 68900810
    },
    {
     "codBanco": 634,
     "nomeBanco": "BANCO TRIANGULO S.A (BANCO TRIÂNGULO)",
     "codISPB": 17351180
    },
    {
     "codBanco": 637,
     "nomeBanco": "BANCO SOFISA S.A (SOFISA DIRETO)",
     "codISPB": 60889128
    },
    {
     "codBanco": 641,
     "nomeBanco": "BANCO ALVORADA S.A",
     "codISPB": 33870163
    },
    {
     "codBanco": 643,
     "nomeBanco": "BANCO PINE S.A",
     "codISPB": 62144175
    },
    {
     "codBanco": 652,
     "nomeBanco": "ITAÚ UNIBANCO HOLDING BM S.A",
     "codISPB": 60872504
    },
    {
     "codBanco": 653,
     "nomeBanco": "BANCO INDUSVAL S.A",
     "codISPB": 61024352
    },
    {
     "codBanco": 654,
     "nomeBanco": "BANCO DIGIMAIS S.A",
     "codISPB": 92874270
    },
    {
     "codBanco": 655,
     "nomeBanco": "NEON PAGAMENTOS S.A (FINTECH DO BANCO VOTORANTIM)",
     "codISPB": 59588111
    },
    {
     "codBanco": 707,
     "nomeBanco": "BANCO DAYCOVAL S.A",
     "codISPB": 62232889
    },
    {
     "codBanco": 712,
     "nomeBanco": "BANCO OURINVEST S.A",
     "codISPB": 78632767
    },
    {
     "codBanco": 739,
     "nomeBanco": "BANCO CETELEM S.A",
     "codISPB": 558456
    },
    {
     "codBanco": 741,
     "nomeBanco": "BANCO RIBEIRÃO PRETO",
     "codISPB": 517645
    },
    {
     "codBanco": 743,
     "nomeBanco": "BANCO SEMEAR S.A",
     "codISPB": 795423
    },
    {
     "codBanco": 745,
     "nomeBanco": "BANCO CITIBANK S.A",
     "codISPB": 33479023
    },
    {
     "codBanco": 746,
     "nomeBanco": "BANCO MODAL S.A",
     "codISPB": 30723886
    },
    {
     "codBanco": 747,
     "nomeBanco": "Banco RABOBANK INTERNACIONAL DO BRASIL S.A",
     "codISPB": 1023570
    },
    {
     "codBanco": 748,
     "nomeBanco": "SICREDI S.A",
     "codISPB": 1181521
    },
    {
     "codBanco": 751,
     "nomeBanco": "SCOTIABANK BRASIL",
     "codISPB": 29030467
    },
    {
     "codBanco": 752,
     "nomeBanco": "BNP PARIBAS BRASIL S.A",
     "codISPB": 1522368
    },
    {
     "codBanco": 753,
     "nomeBanco": "NOVO BANCO CONTINENTAL S.A BM",
     "codISPB": 74828799
    },
    {
     "codBanco": 754,
     "nomeBanco": "BANCO SISTEMA",
     "codISPB": 76543115
    },
    {
     "codBanco": 755,
     "nomeBanco": "BOFA MERRILL LYNCH BM S.A",
     "codISPB": 62073200
    },
    {
     "codBanco": 756,
     "nomeBanco": "BANCOOB (BANCO COOPERATIVO DO BRASIL)",
     "codISPB": 2038232
    },
    {
     "codBanco": 757,
     "nomeBanco": "BANCO KEB HANA DO BRASIL S.A",
     "codISPB": 2318507
    }
   ]
