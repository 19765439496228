import axios from "axios";
import { encode as base64_encode } from 'base-64';
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Nav, Navbar, Row, Toast, ToastContainer } from 'react-bootstrap';
import ReactPlayer from "react-player";
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from '../contexts/global';
import LogoArquivo from '../images/logo.svg';


function Navegacao() {

    const Navigate = useNavigate()

    const [exibirToast, setExibirToast] = useState(false)
    const [msgToast, setMsgToast] = useState('')

    const [tutorial, setTutorial] = useState({})
    const [modalTutorial, setModalTutorial] = useState(false)

    const [empresasUsuario, setEmpresasUsuario] = useState([])

    const { usuario, SalvarLogin, DefineTipoAcesso, tipoAcesso, DefineLicenca, conexao, SalvarPermissoes } = useContext(GlobalContext)

    const FloatingButton = () => {

        const buttonStyle = {
            position: 'fixed',
            bottom: '60px',
            right: '40px',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: '#3498db',
            color: '#fff',
            fontSize: '20px',
            cursor: 'pointer',
            border: 'none',
        };

        const ajudaTextStyle = {
            position: 'fixed',
            bottom: '60px',
            right: '100px',
            backgroundColor: '#fff',
            padding: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            maxWidth: '200px',
            display: 'inline-block',
            zIndex: '1',
        };

        return (
            <div>
                <button style={buttonStyle} onClick={() => setModalTutorial(true)} >
                    ?
                </button>
                <div style={ajudaTextStyle}>Ajuda Disponível</div>

            </div>
        );
    };

    function Sair() {

        console.log('Deslogando da Conta')
        setMsgToast('Logoff realizado com sucesso...')
        setExibirToast(true)
        SalvarLogin(null)
        localStorage.removeItem('razao')
        localStorage.removeItem('empresa')
        localStorage.removeItem('id_empresa')
        localStorage.removeItem('empresas')
        localStorage.removeItem('estabelecimento')
        localStorage.removeItem('id_usuario')
        localStorage.removeItem('token')
        Navigate('/')
    }

    async function ValidaToken() {

        const rotaAtual = window.location.pathname

        const tokenObtido = localStorage.getItem('Token')

        await axios.post(conexao.endereco + '/token/validacao',
            {
                "token": tokenObtido
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status !== 200) {
                    Sair()
                } else {
                    DefineTipoAcesso(response.data.dados.tipo)
                    if (response.data.dados.tipo === 'OPERADOR') {
                        SalvarLogin(response.data.dados.oper_email, response.data.dados.oper_id, response.data.dados.oper_nome, response.data.dados.tipo)
                    }
                    if (response.data.dados.tipo === 'USUARIO') {
                        SalvarLogin(response.data.dados.usua_email, response.data.dados.usua_id, response.data.dados.usua_nome, response.data.dados.tipo)
                    }
                    const permissoes = response.data.permissoes
                    const config = response.data.config

                    console.log('Permissoes: ', permissoes)
                    console.log('Config: ', config)

                    SalvarPermissoes(permissoes)

                    const emailsExcluidos = ['testedp@arquivocontabil.com.br', 'dp11@arquivocontabil.com.br', 'dp14@arquivocontabil.com.br'];

                    if (!emailsExcluidos.some(email => response.data.dados.usua_email.includes(email))) {
                        if (config.some(item => rotaAtual.includes(item.rota))) {

                            const categoriaValue = config.find(item => rotaAtual.includes(item.rota))?.categoria.value;
                            if (categoriaValue) {
                                const foundPermission = permissoes.find(permission => permission.Id === categoriaValue);
                                if (foundPermission) {
                                    // O "value" do array de configuração existe no "id" do array de permissões
                                    console.log('Permissão encontrada:', foundPermission);
                                } else {
                                    // Não foi encontrada uma permissão correspondente
                                    console.log('Permissão não encontrada');
                                    Navigate('/permissao/' + base64_encode(rotaAtual))
                                }
                            } else {
                                // "value" não encontrado no array de configuração
                                console.log('Categoria "value" não encontrado nas configurações');
                            }

                        }
                    }

                    GetTutorial()

                    // alert(JSON.stringify(permissoes))
                }
            })
            .catch(function (error) {
                console.error(error)
                Sair()
            })
    }
    async function GetTutorial() {

        const rotaAtual = window.location.pathname

        await axios.post(conexao.endereco + '/tutoriais/listar/individual',
            {
                "rota": rotaAtual,
                "tipo": 'portal'
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200 && response.data.vid_id) {
                    setTutorial(response.data)
                } else {
                    setTutorial({})
                }

            })
            .catch(function (error) {
                console.error(error)
                Sair()
            })
    }





    useEffect(() => {
        ValidaToken()
    }, [window.location.pathname])

    return (
        <>
            <Navbar className="barra-dash" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand className="titulo-dash" onClick={() => { Navigate("/dashboard") }} style={{ margin: 0 }}>
                        <img
                            src={LogoArquivo}
                            width={'100%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {tipoAcesso === 'USUARIO' &&
                            <Nav className="me-auto">
                                {usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/dashboard') }}>Dashboard</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/impostos') }} eventKey="1" >Impostos</Nav.Link>
                                        {/* {usuario.email === 'testedp@arquivocontabil.com.br' && */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/departamento-pessoal') }} eventKey="2" >Departamento Pessoal</Nav.Link>
                                        {/* } */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/movimento-bancario') }} eventKey="3" >Mvto Bancário</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/arquivos') }} eventKey="4" >Arquivos</Nav.Link>
                                        {/* {usuario.email === 'testedp@arquivocontabil.com.br' && */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/mais-opcoes') }} eventKey="5" >Mais Opções</Nav.Link>
                                        {/* } */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Sair() }}>Sair</Nav.Link>
                                    </>
                                }
                            </Nav>
                        }
                        {tipoAcesso === 'OPERADOR' &&
                            <Nav className="me-auto">
                                {usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => {
                                            Navigate('/dashboard')
                                        }}>Dashboard</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/cadastros/empresas') }} eventKey="1" >Empresas</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/cadastros/usuarios') }} eventKey="2" >Usuários</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/cadastros/operadores') }} eventKey="3" >Operadores</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/solicitacoes') }} eventKey="4" >Solicitações</Nav.Link>
                                        {/* <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/folha-pagamento') }} eventKey="3" >Folha de Pagamento</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/movimento-bancario') }} eventKey="4" >Mvto Bancário</Nav.Link> */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/mais-opcoes') }} eventKey="5" >Mais Opções</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Sair() }}>Sair</Nav.Link>
                                    </>
                                }
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>

            </Navbar>

            <ToastContainer className="p-3" position={"top-end"} style={{ zIndex: 20 }}>
                <Toast onClose={() => { setExibirToast(false) }} show={exibirToast} delay={3000} autohide>
                    <Toast.Header closeButton={true}>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">{msgToast}</strong>
                        <small>agora</small>
                    </Toast.Header>

                </Toast>
            </ToastContainer>

            {tutorial.vid_id && <FloatingButton />}

            <Modal show={modalTutorial} centered dialogClassName='modal-75'>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {tutorial.vid_titulo}
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                            <ReactPlayer
                                url={tutorial.vid_url}
                                controls={true}
                                width="100%"
                                height="100%"
                                fullscreen
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        setModalTutorial(false)
                    }}>Sair</Button>

                </Modal.Footer>
            </Modal>

        </>

    )
}

export default Navegacao;