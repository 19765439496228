import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


export function Hint(props) {
    return (
        <OverlayTrigger trigger={props.hover ? "hover" : "focus"} placement={props.posicao} overlay={<Tooltip id={props.id}>{props.texto}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )
}

