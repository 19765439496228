import { Field, Form, Formik } from 'formik';
import md5 from "js-md5";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, } from 'react-router-dom';
import * as Yup from 'yup';
import { ErroField } from '../../components/field';
import { GlobalContext } from "../../contexts/global";
import Logomarca from '../../images/logo.svg';
import './style.css';

function TelaLogin() {

    const [textoAcessar, setTextoAcessar] = useState('Acessar')
    const [exibeMsg, setExibeMsg] = useState(false)
    const [tituloMsg, setTituloMsg] = useState(false)
    const [textoMsg, setTextoMsg] = useState(false)

    const { conexao, loading, setLoading, DefineTipoAcesso, SalvarLogin } = useContext(GlobalContext)

    const Navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            setTextoAcessar('Aguarde...')
        } else {
            setTextoAcessar('Acessar')
        }
    }, [loading])

    useEffect(() => {
        localStorage.removeItem('Usuário Logado')
        localStorage.removeItem('Token')
        localStorage.removeItem('time_id')
        localStorage.removeItem('time_nome')
        localStorage.removeItem('razao')
        localStorage.removeItem('empresa')
        localStorage.removeItem('id_empresa')
        localStorage.removeItem('empresas')
        localStorage.removeItem('estabelecimento')
        localStorage.removeItem('id_usuario')
        localStorage.removeItem('token')
    }, [])

    function fazerLogin(values, actions) {
        console.log('Dados para Login', values)
        Autenticar(values.usuario, values.senha)
    }

    function Autenticar(usuario, senha) {

        setLoading(true)

        const url = conexao.endereco + '/portal/login'

        let body = {
            "usuario": usuario.toLowerCase(),
            "senha": md5(senha).toUpperCase()
        }

        console.log('URL: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setTituloMsg('Atenção!')
            setTextoMsg(this.responseText)
            setExibeMsg(true)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {

                let resposta = JSON.parse(this.response)

                if (resposta.tipo === 'USUARIO') {
                    localStorage.setItem('Usuário Logado', resposta.usuario.usua_nome)
                }
              
                localStorage.setItem('email', resposta.email)
                localStorage.setItem('Token', resposta.token)
                
                DefineTipoAcesso(resposta.tipo)

                if (resposta.tipo === 'OPERADOR') {
                    SalvarLogin(resposta.operador.oper_email, resposta.operador.oper_id, resposta.operador.oper_nome, resposta.tipo)
                }
                if (resposta.tipo === 'USUARIO') {
                    SalvarLogin(resposta.usuario.usua_email, resposta.usuario.usua_id, resposta.usuario.usua_nome, resposta.tipo)
                }
                Navigate('/dashboard')
            } else {
                let resposta = JSON.parse(this.response)
                setTituloMsg('Atenção!')
                setTextoMsg(resposta.message)
                setExibeMsg(true)

            }
        }
    }

    const SchemaLogin = Yup.object().shape({
        usuario: Yup.string()
            .required('Campo Obrigatório'),
        senha: Yup.string()
            .required('Campo Obrigatório')
            .min(3, 'Senha deve ter ao menos 3 digitos'),
    })

    return (
        <div className="main-login">

            <div className="left-login" />

            <div className="right-login">
                <div className="card-login">
                    <div className="logo-login">
                        <img
                            className="logo-image"
                            src={Logomarca}
                            alt={'Logo Arquivo'}
                            loading={"lazy"}
                        ></img>
                    </div>
                    <Formik
                        onSubmit={fazerLogin}
                        validationSchema={SchemaLogin}
                        initialValues={{
                            usuario: localStorage.getItem('email'),
                            senha: ''
                        }}
                        render={({ values, errors, touched, isValid }) => (
                            <>
                                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="textfield">
                                        <label for="usuario">Usuário</label>
                                        <Field name="usuario" type="text" />
                                        <ErroField campo="usuario" />
                                    </div>
                                    <div className="textfield">
                                        <label for="senha">Senha</label>
                                        <Field name="senha" type="password" />
                                        <ErroField campo="senha" />
                                    </div>

                                    <button className="btn-login" type="submit" >{textoAcessar}</button>
                                </Form>

                            </>
                        )}
                    />

                </div>
            </div>

            {/* <TelaLogin exibir={exibeMsg} titulo="Retorno API" mensagem={textoMsg} /> */}

            <Modal show={exibeMsg} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {tituloMsg}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {textoMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setExibeMsg(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default TelaLogin