import axios from "axios";
import { formatToCPF } from 'brazilian-values';
import ptbr from 'date-fns/locale/pt-BR';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Col, Container, Form as FormBoot, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillAlert } from "react-icons/ai";
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { GiHandTruck } from 'react-icons/gi';
import { HiOutlineDocumentText, HiOutlineNewspaper } from 'react-icons/hi';
import { MdClose, MdDelete, MdFileDownload, MdOutlineDesignServices, MdOutlineLibraryBooks } from "react-icons/md";
import { RiFilePaperLine } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { CheckFieldPadrao, ErroField, InputFieldPadrao, InputFieldTextArea } from '../../components/field';
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Hint } from "../../components/hint";
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';
registerLocale('pt-BR', ptbr)

const CATEGORIAS =
    [
        { value: 'aquisicao_mercadorias', label: 'Aquisição de Mercadorias / Fretes' },
        { value: 'comprovantes_pagamento', label: 'Comprovantes de Pagamento / Relatório Financeiro' },
        { value: 'extratos_bancarios', label: 'Extratos Bancários' },
        { value: 'faturas', label: 'Energia / Telecomunicação' },
        { value: 'servicos_tomados', label: 'Serviços Tomados / Aluguel Pago' },
        { value: 'arquivos_sped', label: 'Arquivos SPED / XML' },
        { value: 'livros_fiscais', label: 'Receita de Locação / Receita Incorporadora' },
        { value: 'arquivos_xml', label: 'Honorários Sucumbênciais' },
        { value: 'processo_importacao', label: 'Processo de Importação' },
        { value: 'docs_nao_contabeis', label: 'Docs Não Contábeis' },
    ]

function ArquivosDetalhe() {

    const Navigate = useNavigate()

    const { detalhe, mes, ano } = useParams()

    const formRef = useRef()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('Aguarde...')
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    // eslint-disable-next-line
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [calOpen, setCalOpen] = useState(false)
    const [mesAtual, setMesAtual] = useState(new Date())

    const [arquivos, setArquivos] = useState([])
    const [competenciasSemArquivo, setCompetenciasSemArquivo] = useState([])
    const [config, setConfig] = useState([])

    const [telaNovoArquivo, setTelaNovoArquivo] = useState(false)
    const [telaEnvioLote, setTelaEnvioLote] = useState(false)
    const [telaSemArquivo, setTelaSemArquivo] = useState(false)

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [ccustoLote, setCcustoLote] = useState('')

    const blockedCategories = [
        'aquisicao_mercadorias',
        'comprovantes_pagamento',
        'faturas',
        'servicos_tomados',
        'livros_fiscais',
        'arquivos_xml'
    ];
    const blockedExtensions = ['zip', 'gzip', '7z', 'rar', 'xml'];

    //teste

    const handleFileChange = (event) => {

        const files = Array.from(event.target.files);

        let contemExtensaoBloqueada = 0

        const updatedFiles = files.map((file) => {

            const fileNameFull = file.name;
            const fileExtension = fileNameFull.split('.').pop().toLowerCase();

            if (blockedCategories.includes(detalhe)) {

                if (!blockedExtensions.includes(fileExtension)) {
                    const fileName = file.name.split('.').slice(0, -1).join('.');
                    return {
                        name: file.name,
                        description: fileName,
                        observations: '',
                        file: file
                    };
                } else {
                    contemExtensaoBloqueada += 1
                }

            } else {
                const fileName = file.name.split('.').slice(0, -1).join('.');
                return {
                    name: file.name,
                    description: fileName,
                    observations: '',
                    file: file
                };
            }
        }).filter(Boolean); // Remove valores nulos do array

        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...updatedFiles]);

        if (contemExtensaoBloqueada > 0) {
            alert(`Arquivos Compactados serão ignorados para a categoria ${CATEGORIAS.find(x => x.value === detalhe)?.label}`);
        }
    };


    const handleDescriptionChange = (index, event) => {
        const { value } = event.target;
        setSelectedFiles((prevSelectedFiles) => {
            const updatedFiles = [...prevSelectedFiles];
            updatedFiles[index].description = value;
            return updatedFiles;
        });
    };

    const handleObservationsChange = (index, event) => {
        const { value } = event.target;
        setSelectedFiles((prevSelectedFiles) => {
            const updatedFiles = [...prevSelectedFiles];
            updatedFiles[index].observations = value;
            return updatedFiles;
        });
    };


    const handleChange = (e) => {
        setCalOpen(!calOpen)
        setMesAtual(e)
    }
    const handleClick = (e) => {
        e.preventDefault()
        setCalOpen(!calOpen)
    }

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function FormataCPF(CPF) {
        return formatToCPF(CPF);
    }

    function GetConfirmaDescartar(funcionario, id) {
        setTelaMsg('Descartar Admissão do Colaborador ' + funcionario + '?')
        setTelaTitulo('Cancelar Admissão')
        // setIdAdmissao(id)
        setTelaAlerta(true)
    }

    function ValidaAtraso(dia) {

        const hoje = new Date()
        const mesAtual = hoje.getMonth() + 1
        const mesCompetencia = +traduzMesString(mes)

        if (mesAtual == mesCompetencia) {
            return false
        }
        if (mesAtual != mesCompetencia) {
            const diferenca = mesAtual - mesCompetencia
            if (diferenca < 0) {
                return false
            } else
                if (diferenca == 1) {

                    const diaAtual = hoje.getDate()
                    const diaLimite = dia
                    if (diaAtual > diaLimite) {
                        return true
                    } else {
                        return false
                    }
                } else
                    if (diferenca > 1) {
                        return true
                    }

        }
    }

    function ExibeTitulo() {

        switch (detalhe) {
            case 'aquisicao_mercadorias':
                return (<><GiHandTruck color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Aquisição de Mercadorias / Fretes</strong></>)
            case 'comprovantes_pagamento':
                return (<><HiOutlineNewspaper color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Comprovantes de Pagamento / Relatório Financeiro</strong></>)
            case 'extratos_bancarios':
                return (<><HiOutlineNewspaper color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Extratos Bancários</strong></>)
            case 'faturas':
                return (<><RiFilePaperLine color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Energia / Telecomunicação</strong></>)
            case 'servicos_tomados':
                return (<><MdOutlineDesignServices color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Serviços Tomados / Aluguel Pago</strong></>)
            case 'arquivos_sped':
                return (<><AiFillAlert color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Arquivos SPED / XML</strong></>)
            case 'livros_fiscais':
                return (<><MdOutlineLibraryBooks color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Receita de Locação / Receita Incorporadora</strong></>)
            case 'arquivos_xml':
                return (<><HiOutlineDocumentText color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Honorários Sucumbênciais</strong></>)
            case 'processo_importacao':
                return (<><HiOutlineDocumentText color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Processo de Importação</strong></>)
            case 'docs_nao_contabeis':
                return (<><HiOutlineDocumentText color="#E30614" size="36" style={{ marginRight: 10 }} /><strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>Docs Não Contábeis</strong></>)

            default:
                break
        }

    }

    function ExibeArquivosEmpresa() {

        const colunas = [
            {
                name: 'Descrição',
                selector: row => (
                    row.arq_status == 'C' ?
                        <span style={{ textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationStyle: 'dotted' }}><Badge bg="danger">Arquivo Excluído</Badge>{row.arq_descricao}</span>
                        : row.arq_descricao
                ),
                sortable: true,
                minWidth: '30%',
                wrap: true
            },
            {
                name: 'Observações',
                selector: row => row.arq_obs,
                sortable: true,
                minWidth: '30%',
                wrap: true
            },
            {
                name: 'Ações',
                button: true,
                cell: row => (
                    <>
                        <Hint posicao={"top"} hover id={"arq-download"} texto={"Baixar Arquivo"}>
                            < a onClick={() => { window.open(row.arq_url) }} >
                                <MdFileDownload size="24" color="green" />
                            </a>
                        </Hint>
                        {row.arq_status !== 'C' &&
                            <Hint posicao={"top"} hover id={"arq-remover"} texto={"Remover Arquivo"}>
                                < a onClick={() => { RemoverArquivo(row.arq_id, row.nome_arquivo) }} >
                                    <MdClose size="24" color="red" style={{ marginLeft: 10 }} />
                                </a>
                            </Hint>
                        }
                    </>

                ),
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };


        const tableData = {
            columns: colunas,
            data: arquivos,
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: false,
            export: false,
            filterDigit: 1,
        };

        return (
            <DataTableExtensions {...tableData}>
                <DataTable
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    pointerOnHover={true}
                    noDataComponent={<ComponenteSemArquivos />}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }

    async function GetArquivos(mes, ano) {

        await GetConfiguracoes()
        await ListarSemArquivos()

        axios.post(conexao.endereco + '/drive/listar',
            {
                empresa: localStorage.getItem('empresa'),
                estab: localStorage.getItem('estabelecimento'),
                mes: mes,
                ano: ano,
                categoria: detalhe

            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setArquivos(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }
    async function GetConfiguracoes() {

        axios.post(conexao.endereco + '/configuracoes/drive/categorias',
            {
                empresa: localStorage.getItem('empresa')

            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setConfig(response.data.length > 0 ? response.data : [])
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function EnviarArquivo(values, url) {
        axios.post(conexao.endereco + '/drive/incluir',
            {
                empresa: localStorage.getItem('empresa'),
                usuario: localStorage.getItem('id_usuario'),
                estab: localStorage.getItem('estabelecimento'),
                mes: mes,
                ano: ano,
                categoria: detalhe,
                ccusto: values.arq_ccusto,
                url: url,
                descricao: values.arq_descricao,
                obs: values.arq_obs,
                atraso: ValidaAtraso(config.find(x => x.categoria === detalhe)?.dia_limite)

            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                GetArquivos(mes, ano)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })


        // if (ValidaAtraso(config.find(x => x.categoria === detalhe)?.dia_limite)) {

        //     axios.post(conexao.endereco + '/drive/emails',
        //         {
        //             empresa: localStorage.getItem('empresa'),
        //             usuario: localStorage.getItem('Usuário Logado'),
        //             categoria: detalhe,
        //         }, { headers: { token: localStorage.getItem('Token') } })

        //         .then(function (response) {
        //             setLoading(false)
        //         })
        //         .catch(function (error) {
        //             console.error(error)
        //             setLoading(false)
        //         })
        // }
    }

    async function UploadArquivo(values) {

        setTelaNovoArquivo(false)
        setMsgLoading('Enviando Arquivo')
        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values.file)

        axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                EnviarArquivo(values, response.data.url)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setTelaNovoArquivo(true)
                setLoading(false)
            })
    }

    function ComponenteSemArquivos() {

        const jaNotificado = competenciasSemArquivo.find(x => x.arqc_competencia === `${traduzMesString(mes)}/${ano}` && x.arqc_categoria === detalhe)

        if (!jaNotificado) {
            return (
                <div style={{ textAlign: 'center', padding: 20, gap: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                    <span
                        style={{
                            marginBottom: 10
                        }}
                    >
                        Nenhum arquivo para enviar? clique
                        <strong
                            onClick={() => { setTelaSemArquivo(true) }}
                            style={{
                                color: '#E30613',
                                cursor: 'pointer'
                            }}
                        >
                            {" "}AQUI{" "}😊
                        </strong>
                    </span>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center', padding: 20, gap: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                    <span
                        style={{
                            marginBottom: 10
                        }}
                    >
                        Foi informado pelo usuário {competenciasSemArquivo[0].usua_nome} que não existem arquivos a serem enviados para a categoria selecionada no mês {competenciasSemArquivo[0].arqc_competencia}.
                        <br />
                        <br />
                        <strong
                            style={{
                                color: '#E30613',
                            }}
                        >
                            {" "}Caso exista basta adicionar os arquivos normalmente{" "}😊
                        </strong>
                    </span>
                </div>
            )
        }

    }

    async function ExcluirArquivo(nomeArquivo) {

        setMsgLoading('Excluindo Arquivo')
        setLoading(true)

        axios.post(conexao.endereco + '/arquivos/remover',
            {
                arquivo: nomeArquivo
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function NotificaCategoriaCompetenciaSemArquivos() {

        setMsgLoading('Aguarde...')
        setLoading(true)

        setTelaSemArquivo(false)

        axios.post(conexao.endereco + '/drive/sem_arquivos/notificar',
            {
                empresa: localStorage.getItem('empresa'),
                estab: localStorage.getItem('estabelecimento'),
                competencia: `${traduzMesString(mes)}/${ano}`,
                categoria: detalhe,
                usuario: localStorage.getItem('id_usuario'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status !== 200) {
                    alert(response.data.msg)
                    return
                }
                GetArquivos(mesAtual.toLocaleDateString('pt-BR', { month: 'long' }), mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' }))
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }
    async function ListarSemArquivos() {

        setMsgLoading('Aguarde...')
        setLoading(true)

        setTelaSemArquivo(false)

        axios.post(conexao.endereco + '/drive/sem_arquivos/listar',
            {
                empresa: localStorage.getItem('empresa'),
                estab: localStorage.getItem('estabelecimento'),
                competencia: `${traduzMesString(mes)}/${ano}`,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                setCompetenciasSemArquivo(response.data)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }


    async function RemoverArquivo(identificacao, nomeArquivo) {

        setMsgLoading('Excluindo Arquivo')
        setLoading(true)

        // if (nomeArquivo) {
        //     ExcluirArquivo(nomeArquivo)
        // }

        axios.post(conexao.endereco + '/drive/remover',
            {
                id: identificacao
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                GetArquivos(mes, ano)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    function traduzMesString(mes) {

        switch (mes) {
            case 'janeiro':
                return '01'
            case 'fevereiro':
                return '02'
            case 'março':
                return '03'
            case 'abril':
                return '04'
            case 'maio':
                return '05'
            case 'junho':
                return '06'
            case 'julho':
                return '07'
            case 'agosto':
                return '08'
            case 'setembro':
                return '09'
            case 'outubro':
                return '10'
            case 'novembro':
                return '11'
            case 'dezembro':
                return '12'

            default:
                break
        }

    }


    // useEffect(() => {
    //     GetArquivos(mes, ano)
    //     alert('UseEffect Normal')
    // }, [])

    useEffect(() => {
        // alert('UseEffect MesAtual')
        Navigate(`/arquivos/${detalhe}/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`)
        GetArquivos(mesAtual.toLocaleDateString('pt-BR', { month: 'long' }), mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' }))
    }, [mesAtual])


    return (
        <>
            {/* <Navegacao /> */}
            {config.findIndex(x => x?.categoria == detalhe) !== -1 &&
                <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                        <Header
                            titulo={
                                <>
                                    <ExibeTitulo />
                                    <Hint posicao={"bottom"} id={"mes_anterior"} texto={"Mês Anterior"}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <BsFillArrowLeftSquareFill
                                                size={20}
                                                color="#E30613"
                                                style={{ cursor: 'pointer', border: 'none', marginLeft: 40 }}
                                                onClick={() => {
                                                    let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() - 1))
                                                    setMesAtual(novaData)
                                                }} />
                                        </div>
                                    </Hint>
                                    <Hint posicao={"top"} id={"selecionar_mes"} texto={"Selecionar Mês Especifico"}>
                                        <div onClick={handleClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase', color: '#E30614', cursor: 'pointer', width: 200, textAlign: 'center' }}>
                                            <span>{mes} de {ano}</span>
                                        </div>
                                    </Hint>
                                    <Hint posicao={"bottom"} id={"mes_posterior"} texto={"Mês Posterior"}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <BsFillArrowRightSquareFill
                                                size={20}
                                                color="#E30613"
                                                style={{ cursor: 'pointer', border: 'none' }}
                                                onClick={() => {
                                                    let novaData = new Date(mesAtual.setMonth(mesAtual.getMonth() + 1))
                                                    setMesAtual(novaData)
                                                }} />

                                        </div>
                                    </Hint>

                                </>}
                            botaoAjuda={() => {
                                // Navigate('/arquivos')
                            }}
                            exibirBotao
                            textoBotao="Voltar"
                            acaoBotao={() => {
                                Navigate('/arquivos')
                            }}
                        >
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                size='sm'
                                onClick={() => { GetArquivos(mes, ano) }}
                            >
                                Atualizar
                            </Button>
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                size='sm'
                                onClick={() => { setTelaNovoArquivo(true) }}
                            >
                                Adicionar Arquivo
                            </Button>

                        </Header>
                        <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 40, marginRight: 40 }}>
                            <Col>
                                {detalhe === 'aquisicao_mercadorias' &&
                                    <Alert key='warning' variant='warning'>
                                        Você está dispensado do envio se for de SC e possuir IE ativa
                                    </Alert>
                                }
                                {detalhe === 'comprovantes_pagamento' &&
                                    <Alert key='warning' variant='warning'>
                                        Enviar os comprovantes de pagamento e conciliação bancária
                                    </Alert>
                                }
                                {detalhe === 'extratos_bancarios' &&
                                    <Alert key='warning' variant='warning'>
                                        Enviar os extratos bancários e conciliação bancária
                                    </Alert>
                                }
                                {detalhe === 'arquivos_sped' &&
                                    <Alert key='warning' variant='warning'>
                                        Caso sua empresa seja de fora de SC, envie os arquivos XML nesta página
                                    </Alert>
                                }
                                {detalhe === 'faturas' &&
                                    <Alert key='warning' variant='warning'>
                                        Enviar as faturas de Comunicação, Telecomunicação, Energia e Água
                                    </Alert>
                                }
                                {detalhe === 'livros_fiscais' &&
                                    <Alert key='warning' variant='warning'>
                                        Utilize somente caso sua empresa receba alugueis ou faça locação de bens móveis
                                    </Alert>
                                }
                                {detalhe === 'servicos_tomados' &&
                                    <Alert key='warning' variant='warning'>
                                        Enviar todas as notas fiscais de serviços tomados
                                    </Alert>
                                }
                                {detalhe === 'arquivos_xml' &&
                                    <Alert key='warning' variant='warning'>
                                        Uso exclusivo para escritórios de advocacia
                                    </Alert>
                                }
                            </Col>
                        </Row>
                        {!loading &&
                            <Row style={{ marginLeft: 40, marginRight: 40, paddingBottom: 80 }}>
                                <ExibeArquivosEmpresa />
                            </Row>
                        }
                    </Container>
                    <Footer />
                    <Modal show={loading} centered>
                        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                            <img
                                src={LogoArquivo}
                                width={'20%'}
                                alt={'Imagem de Nuvem'}
                                loading={'lazy'}
                            ></img>
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                                    {msgLoading}
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={calOpen}
                        onHide={() => { setCalOpen(false) }}
                        dialogClassName='modal-calendario'

                    >
                        <Modal.Header closeButton style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>
                            Selecione o Mês
                        </Modal.Header>
                        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <DatePicker
                                selected={mesAtual}
                                onChange={handleChange}
                                inline
                                locale={ptbr}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                todayButton="Mês Atual"
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal show={telaAlerta} centered>
                        <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                            {telaTitulo}
                        </Modal.Header>
                        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {telaMsg}
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={telaNovoArquivo}
                        onHide={() => { setTelaNovoArquivo(false) }}
                        centered>
                        <Modal.Header style={{ color: '#30613', fontSize: 24 }} closeButton>
                            Inclusão de Arquivo
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                enableReinitialize={true}
                                innerRef={formRef}
                                onSubmit={(values) => {
                                    UploadArquivo(values)
                                }}
                                initialValues={{
                                    arq_descricao: '',
                                    arq_ccusto: '',
                                    arq_obs: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    arq_descricao: Yup.string()
                                        .required('Campo Obrigatório')
                                })}
                                render={({ values, errors, touched, isValid, setFieldValue }) => (
                                    <>
                                        <Form autoComplete="off" id="formFilhos">
                                            <Row>
                                                <Col sm={12} lg={12} className="mb-3">
                                                    <InputFieldPadrao campo="arq_descricao" tipo="text" texto="Descrição" />
                                                    <ErroField campo="arq_descricao" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} lg={6}>
                                                    <FormBoot.Group className="mb-3" controlId="formCadEmail">
                                                        <FormBoot.Label>Competência</FormBoot.Label>
                                                        <FormBoot.Control type="text" value={`${traduzMesString(mes)}/${ano}`} disabled />
                                                    </FormBoot.Group>
                                                </Col>
                                                <Col sm={12} lg={6} className="mb-3">
                                                    <InputFieldPadrao campo="arq_ccusto" tipo="text" texto="Centro de Custo" />
                                                    <ErroField campo="arq_ccusto" />
                                                </Col>
                                            </Row>
                                            {ValidaAtraso(config.find(x => x.categoria === detalhe)?.dia_limite) &&
                                                <Alert key='danger' variant='danger'>
                                                    arquivo está em atraso, o prazo é até o dia {config.find(x => x.categoria === detalhe)?.dia_limite}
                                                </Alert>
                                            }

                                            <Row>
                                                <Col sm={12} lg={12} className="mb-3">
                                                    <InputFieldTextArea linhas={4} campo="arq_obs" tipo="text" texto="Observações" />
                                                    <ErroField campo="arq_obs" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} lg={12} className="mb-3">
                                                    <label htmlFor={"arquivo_enviar"} className="form-label">Arquivo</label>
                                                    <Field
                                                        id={"arquivo_enviar"}
                                                        name={"arquivo_enviar"}
                                                        type={"file"}
                                                        className="form-control"
                                                        onChange={(event) => {

                                                            const fileName = event.currentTarget.files[0].name;
                                                            const fileExtension = fileName.split('.').pop().toLowerCase();

                                                            if (blockedExtensions.includes(fileExtension) && blockedCategories.includes(detalhe)) {
                                                                alert(`Extensão de arquivo não permitida para a categoria ${CATEGORIAS.find(x => x.value === detalhe)?.label}`);
                                                                event.currentTarget.value = null;
                                                                return;
                                                            }

                                                            setFieldValue("file", event.currentTarget.files[0])
                                                        }} />
                                                    <ErroField campo="arquivo_enviar" />
                                                </Col>
                                            </Row>
                                            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Button
                                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                                    // size='sm'
                                                    onClick={() => {
                                                        setSelectedFiles([])
                                                        setTelaNovoArquivo(false)
                                                        setTelaEnvioLote(true)
                                                    }}
                                                >
                                                    Envio em Lote
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                                    // size='sm'
                                                    type="submit"
                                                    form="formFilhos"
                                                >
                                                    Adicionar
                                                </Button>
                                            </Col>
                                        </Form>
                                    </>
                                )}
                            />
                        </Modal.Body>

                    </Modal>
                    <Modal
                        show={telaEnvioLote}
                        onHide={() => { setTelaEnvioLote(false) }}
                        centered
                        size="xl"
                    >
                        <Modal.Header style={{ color: '#30613', fontSize: 24 }} closeButton>
                            Envio em lote
                            <input
                                type="text"
                                placeholder="Centro de Custo"
                                value={ccustoLote}
                                onChange={(event) => setCcustoLote(event.target.value)}
                                className="form-control"
                                size={"sm"}
                                style={{ width: '20%', marginLeft: '10px', marginLeft: '30px' }}
                            />
                        </Modal.Header>
                        <Modal.Body>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Hint posicao={"top"} hover id={"add-arq-lista"} texto={"Adicionar Arquivo"}>
                                        <label htmlFor="fileInput" className="upload-button">
                                            +
                                        </label>
                                    </Hint>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        className="file-input"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div>
                                    <div>
                                        {selectedFiles.length > 0 &&
                                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                                                for (let i = 0; i < selectedFiles.length; i++) {
                                                    UploadArquivo({
                                                        file: selectedFiles[i].file,
                                                        arq_descricao: selectedFiles[i].description,
                                                        arq_obs: selectedFiles[i].observations,
                                                        arq_ccusto: ccustoLote
                                                    })
                                                }
                                                setTelaEnvioLote(false)
                                                setCcustoLote('')
                                            }}>
                                                Enviar Arquivos
                                            </Button>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div>
                                <ul className="file-list">
                                    {selectedFiles.map((file, index) => (
                                        <li key={index} className="file-item">
                                            {/* <div className="file-name">{file.name}</div> */}
                                            <div className="file-fields">
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                    <label htmlFor={`description_${index}`}>Descrição:</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Descrição"
                                                        value={file.description}
                                                        onChange={(event) => handleDescriptionChange(index, event)}
                                                        className="file-description"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                    <label htmlFor={`observations_${index}`}>Observações:</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Observações"
                                                        value={file.observations}
                                                        onChange={(event) => handleObservationsChange(index, event)}
                                                        className="file-observations"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, paddingTop: 20, marginLeft: 5, cursor: 'pointer' }}>
                                                    <MdDelete className="file-delete" size={24} color='brown' onClick={() => {
                                                        const lista = [...selectedFiles]
                                                        lista.splice(index, 1)
                                                        setSelectedFiles(lista)
                                                    }} />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </Modal.Body>

                    </Modal>

                    <Modal
                        show={telaSemArquivo}
                        onHide={() => { setTelaSemArquivo(false) }}
                        centered
                        dialogClassName='modal-50   '
                    >
                        <Modal.Header style={{ color: '#30613', fontSize: 24 }} closeButton>
                            Nenhum Arquivo
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                enableReinitialize={true}
                                innerRef={formRef}
                                onSubmit={(values) => {
                                    UploadArquivo(values)
                                }}
                                initialValues={{
                                    confirmo_sem_arquivo: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    arq_descricao: Yup.string()
                                        .required('Campo Obrigatório')
                                })}
                                render={({ values, errors, touched, isValid, setFieldValue }) => (
                                    <>
                                        <Form autoComplete="off" id="formFilhos">
                                            <Row>
                                                <Col sm={12} lg={12} className="mb-3 mt-3 text-center">
                                                    <CheckFieldPadrao campo="confirmo_sem_arquivo" tipo="checkbox" >
                                                        Confirmo que não existem arquivos a serem enviados da competência <strong>{`${traduzMesString(mes)}/${ano}`}</strong> na categoria <strong>{CATEGORIAS.find(x => x.value == detalhe)?.label}</strong>
                                                    </CheckFieldPadrao>
                                                </Col>
                                                {values.confirmo_sem_arquivo &&
                                                    <Col sm={12} lg={12} className="mb-3 mt-3 text-right" style={{ display: 'flex', justifyContent: 'center ' }}>
                                                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                                                            NotificaCategoriaCompetenciaSemArquivos()
                                                        }}>
                                                            Confirmar
                                                        </Button>
                                                    </Col>
                                                }
                                            </Row>

                                        </Form>
                                    </>
                                )}
                            />
                        </Modal.Body>

                    </Modal>

                </div >
            }
            {config?.findIndex(x => x.categoria == detalhe) == -1 &&
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 40, marginRight: 40 }}>
                        <Col>
                            {detalhe === 'aquisicao_mercadorias' &&
                                <Alert key='warning' variant='warning'>
                                    Você está dispensado do envio se for de SC e possuir IE ativa
                                </Alert>
                            }
                            {detalhe === 'comprovantes_pagamento' &&
                                <Alert key='warning' variant='warning'>
                                    Enviar os comprovantes de pagamento e conciliação bancária
                                </Alert>
                            }
                            {detalhe === 'extratos_bancarios' &&
                                <Alert key='warning' variant='warning'>
                                    Enviar os extratos bancários e conciliação bancária
                                </Alert>
                            }
                            {detalhe === 'arquivos_sped' &&
                                <Alert key='warning' variant='warning'>
                                    Caso sua empresa seja de fora de SC, envie os arquivos XML nesta página
                                </Alert>
                            }
                            {detalhe === 'faturas' &&
                                <Alert key='warning' variant='warning'>
                                    Enviar as faturas de Comunicação, Telecomunicação, Energia e Água
                                </Alert>
                            }
                            {detalhe === 'livros_fiscais' &&
                                <Alert key='warning' variant='warning'>
                                    Utilize somente caso sua empresa receba alugueis ou faça locação de bens móveis
                                </Alert>
                            }
                            {detalhe === 'servicos_tomados' &&
                                <Alert key='warning' variant='warning'>
                                    Enviar todas as notas fiscais de serviços tomados
                                </Alert>
                            }
                            {detalhe === 'arquivos_xml' &&
                                <Alert key='warning' variant='warning'>
                                    Uso exclusivo para escritórios de advocacia
                                </Alert>
                            }
                        </Col>
                        <Col>
                            <strong style={{ color: '#E30613', fontSize: 18, textTransform: 'uppercase' }}>A Categoria {CATEGORIAS.find(x => x?.value == detalhe)?.label} não está habilitada para sua empresa</strong>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default ArquivosDetalhe;