import axios from 'axios';

import dayjs from "dayjs";
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form as FormReact, Modal, Row, Stack } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { ErroField, InputFieldPadrao, InputFieldTextArea } from '../../components/field';
import Footer from '../../components/footer';
import { ModalFuncionarios } from '../../components/modal';
import Navegacao from '../../components/navbar';
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';



export default function Acidente() {

    const formRef = useRef()
    const Navigate = useNavigate()
    const [modalConcluidoAberto, setModalConcluidoAberto] = useState(false)
    const [modalLoadingAberto, setModalLoadingAberto] = useState(false)
    const [mensagemLoading, setMensagemLoading] = useState(false)
    const [dataAtual, setDataAtual] = useState("")
    const [modalFuncionarios, setModalFuncionarios] = useState(false)
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')


    const urlParams = new URLSearchParams(window.location.search)

    const empresa = urlParams.get("empresa")
    const estab = urlParams.get("estab")
    const func = urlParams.get("func")
    const id_acidente = urlParams.get("id")

    const [edicao, setEdicao] = useState(false)

    const [progresso] = useState(0)

    const [listaAnexos, setListaAnexos] = useState([])


    const { conexao, tipoAcesso } = useContext(GlobalContext)

    function transformarNomeArquivo(nomeArquivo) {
        return nomeArquivo.replace(/[^\w\s\.]/gi, '').replace(/\s+/g, '_').toLowerCase();
    }


    const enviarSolicitacao = async (values) => {
        console.log("Event: ", values)

        const id_empresa = tipoAcesso === "USUARIO" ? localStorage.getItem('id_empresa') : "0"
        const id_operador = tipoAcesso !== "USUARIO" ? localStorage.getItem('id_usuario') : "0"
        const id_usuario = localStorage.getItem('id_usuario')

        setMensagemLoading("Enviando solicitação")
        setModalLoadingAberto(true)

        try {
            const response = await axios.post(`${conexao.endereco}/solicitacoes/incluir`, {
                empresa: localStorage.getItem("id_empresa"),
                estabelecimento: localStorage.getItem("estabelecimento"),
                usuario: id_usuario,
                operador: id_operador,
                categoria: "16",
                prioridade: "7",
                tipo: "ACIDENTE",
                assunto: `Acidente de Trabalho ${values.empregado}`,
                funcionario: values.empregado,
                conteudo: 'Acidente de Trabalho do Colaborador ' + values.empregado,
            }, { headers: { token: localStorage.getItem('Token') } });

            setModalLoadingAberto(false);
            if (response.status === 201) {
                setModalConcluidoAberto(true);
                return response.data.ticket;
            } else {
                return null
            }

        } catch (error) {
            console.log(error);
            return null
        }

    }

    const enviarAcidente = async (values) => {

        setLoading(true)
        setMsgLoading("Enviando requisição")

        const idTicket = await enviarSolicitacao(values)

        if (!idTicket) {
            setLoading(false)
            alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
            return;
        }

        await axios.post(`${conexao.endereco}/acidentes/incluir`, {
            empresa: localStorage.getItem("id_empresa"),
            estab: localStorage.getItem("estabelecimento"),
            func: values.id_empregado,
            data_acidente: values.data_acidente,
            hora_acidente: values.hora_acidente,
            tipo_acidente: values.tipo_acidente,
            local: values.local,
            det_local: values.det_local,
            registro_policial: values.registro_policial,
            medico_nome: values.nome_medico,
            medico_crm: values.crm_medico,
            unidade_atendimento: values.unidade_atendimento,
            data_atestado: values.data_atestado,
            dias_lic_medica: values.dias_licenca,
            causa_acidente: values.causa_acidente,
            parte_corpo: values.parte_corpo_atingida,
            descricao_acidente: values.descricao_acidente,
            testemunha_a: values.testemunha_a,
            testemunha_b: values.testemunha_b,
            observacoes: values.observacoes,
            houve_afastamento: values.houve_afastamento,
            houve_obito: values.houve_obito,
            lateralidade: values.lateralidade,
            ultimo_dia_trabalhado: values.ultimo_dia_trabalhado,
            anexos: listaAnexos,
            ticket: idTicket
        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {
                window.location.reload();
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getAcidente = async (values) => {

        setLoading(true)
        setMsgLoading("buscando dados")

        axios.post(`${conexao.endereco}/acidentes/listar`, {
            ticket: id_acidente
        }, { headers: { token: localStorage.getItem('Token') } })
            .then((response) => {

                setLoading(false)
                if (response.status === 200) {

                    if (response.data[0].acid_anexos) {
                        setListaAnexos(JSON.parse(response.data[0].acid_anexos))
                    }

                    setValoresPadraoForm({
                        empresa: response.data[0].empr_razao,
                        numero_empresa: response.data[0].empr_id,
                        data_solicitacao: new Date(response.data[0].acid_created).toISOString().substr(0, 10),
                        id_empregado: response.data[0].func_id,
                        empregado: response.data[0].func_nome,
                        data_acidente: new Date(response.data[0].acid_data_hora_acidente).toISOString().substr(0, 10),
                        hora_acidente: new Date(response.data[0].acid_data_hora_acidente).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        det_local: response.data[0].acid_det_local,
                        tipo_acidente: response.data[0].acid_tipo,
                        local: response.data[0].acid_local,
                        registro_policial: response.data[0].acid_registro_policial,
                        testemunha_a: response.data[0].acid_acidente_testemunha_a,
                        testemunha_b: response.data[0].acid_acidente_testemunha_b,
                        observacoes: response.data[0].acid_obs,
                        nome_medico: response.data[0].acid_medico_nome,
                        crm_medico: response.data[0].acid_medico_crm,
                        unidade_atendimento: response.data[0].acid_unid_atendimento,
                        data_atestado: new Date(response.data[0].acid_data_atestado).toISOString().substr(0, 10),
                        dias_licenca: response.data[0].acid_dias_licenca,
                        causa_acidente: response.data[0].acid_acidente_causa,
                        parte_corpo_atingida: response.data[0].acid_acidente_parte_corpo,
                        descricao_acidente: response.data[0].acid_acidente_descricao,
                        houve_afastamento: response.data[0].acid_afastamento,
                        houve_obito: response.data[0].acid_obito,
                        lateralidade: response.data[0].acid_lateralidade,
                        ultimo_dia_trabalhado: new Date(response.data[0].acid_ultimo_dia_trab).toISOString().substr(0, 10),
                    })

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {

        if ((empresa && estab && func) || id_acidente) {
            setEdicao(true)
            getAcidente()
        }

        GetDataAtual()
    }, [])


    const GetDataAtual = () => {
        const now = dayjs.utc().format("YYYY-MM-DD")
        setDataAtual(now)
    }


    const schemaAcidente = Yup.object().shape({
        empresa: Yup.string().required('Campo Obrigatório'),
        data_acidente: Yup.date().required('Campo Obrigatório'),
        hora_acidente: Yup.string().required('Campo Obrigatório'),
        id_empregado: Yup.string().required('Campo Obrigatório'),
        tipo_acidente: Yup.string().required('Campo Obrigatório').nullable(),
        local: Yup.string().required('Campo Obrigatório').nullable(),
        registro_policial: Yup.string().required('Campo Obrigatório').nullable(),
        det_local: Yup.string().required('Campo Obrigatório'),
        nome_medico: Yup.string().required('Campo Obrigatório'),
        crm_medico: Yup.string().required('Campo Obrigatório'),
        unidade_atendimento: Yup.string().required('Campo Obrigatório'),
        data_atestado: Yup.date().required('Campo Obrigatório'),
        dias_licenca: Yup.string().required('Campo Obrigatório'),
        causa_acidente: Yup.string().required('Campo Obrigatório'),
        parte_corpo_atingida: Yup.string().required('Campo Obrigatório'),
        descricao_acidente: Yup.string().required('Campo Obrigatório'),
        // houve_afastamento: Yup.string().required('Campo Obrigatório').nullable(),
    })


    const [valoresPadraoForm, setValoresPadraoForm] = useState({
        empresa: localStorage.getItem("razao"),
        numero_empresa: localStorage.getItem("empresa"),
        data: dayjs(new Date()).format("YYYY-DD-MM"),
        id_empregado: '',
        data_acidente: '',
        hora_acidente: '',
        det_local: '',
        tipo_acidente: null,
        local: null,
        registro_policial: null,
        testemunha_a: '',
        testemunha_b: '',
        observacoes: '',
        nome_medico: '',
        crm_medico: '',
        unidade_atendimento: '',
        data_atestado: '',
        dias_licenca: '',
        causa_acidente: '',
        parte_corpo_atingida: '',
        descricao_acidente: '',
        data_solicitacao: new Date().toISOString().substr(0, 10)
    })

    async function UploadArquivo(values) {

        setMsgLoading(`Enviando Arquivo ${values.name}`)
        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values)

        await axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)

                let obj = {
                    nome: transformarNomeArquivo(values.name),
                    url: response.data.url
                }

                listaAnexos.push(obj)
                console.log(response.data.url)
                return response.data.url

            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
                return null
            })
    }

    return (
        <>
            <Navegacao />

            <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', paddingBottom: "4rem" }}>
                <Col sm={12} lg={12} style={{ paddingTop: 20 }}>
                    <Row>
                        <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                            <h3>Acidente de Trabalho</h3>
                        </Col>
                        <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>

                            {!edicao &&
                                <>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                        size='sm'
                                        type="button"
                                        onClick={() => {
                                            Navigate('/departamento-pessoal')
                                        }}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                        size='sm'
                                        type="submit"
                                        form="formAcidente"
                                    >
                                        Salvar
                                    </Button>
                                </>
                            }
                        </Col>
                        <Row >
                            <hr />
                        </Row>
                    </Row>
                </Col>
                <Row>
                    <Col>

                        <Formik
                            enableReinitialize={true}
                            onSubmit={async (values, actions) => {
                                console.log('Dados para Atualizacao', values)

                                const anexos = values.anexos

                                if (anexos) {
                                    for (let i = 0; i < anexos.length; i++) {

                                        let urlAnexo = await UploadArquivo(anexos[i])

                                    }
                                }

                                console.log('Lista de Anexos', listaAnexos)

                                await enviarAcidente(values)
                            }}
                            initialValues={{
                                ...valoresPadraoForm
                            }}
                            validationSchema={schemaAcidente}
                            innerRef={formRef}
                            render={({ values, errors, touched, isValid, setFieldValue }) => (
                                <Form id="formAcidente" autoComplete='off'>
                                    <Stack gap={3}>
                                        <Row>
                                            <Col sm={12} lg={6} >
                                                <label htmlFor="empresa" className="form-label">Empresa</label>
                                                <Field id="empresa" name="empresa" type="text" className="form-control" disabled style={{ textTransform: 'capitalize' }} />
                                                <ErroField campo="empresa" />
                                            </Col>

                                            <Col sm={12} lg={2} >
                                                <InputFieldPadrao
                                                    campo="data_solicitacao"
                                                    tipo="date"
                                                    texto="Data da Solicitação"
                                                    disabled={edicao}
                                                />

                                                <ErroField campo="data_solicitacao" />
                                            </Col>
                                            <Col sm={12} lg={2}>
                                                <InputFieldPadrao campo="data_acidente" tipo="date" texto="Data do acidente" disabled={edicao} />
                                                <ErroField campo="data_acidente" />
                                            </Col>

                                            <Col sm={12} lg={2}>
                                                <InputFieldPadrao campo="hora_acidente" tipo="time" texto="Hora do acidente" disabled={edicao} />
                                                <ErroField campo="hora_acidente" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12} lg={6}>
                                                <InputFieldPadrao campo="empregado" tipo="text" disabled texto={
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        Empregado

                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                if (!edicao) {
                                                                    setModalFuncionarios(true)
                                                                } else {
                                                                    alert('Modo Visualização')
                                                                }

                                                                // setCriarCargo(false)
                                                                // setIsOpenModalSalario(true)
                                                            }
                                                            }
                                                        >
                                                            < BsSearch style={{ marginLeft: 10, fontSize: 16, color: "#E30613" }} />

                                                        </div>

                                                    </div>
                                                } />
                                                <ErroField campo="id_empregado" />
                                            </Col>

                                            <Col lg={2}>
                                                <label htmlFor="tipo_acidente" className="form-label">Tipo do Acidente</label>
                                                <Field id="tipo_acidente" name="tipo_acidente" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden >Selecione ...</option>
                                                    <option>Típico</option>
                                                    <option>Doença</option>
                                                    <option>Trajeto</option>
                                                </Field>
                                                <ErroField campo="tipo_acidente" />
                                            </Col>

                                            <Col lg={4}>
                                                <label htmlFor="local" className="form-label">Local</label>
                                                <Field id="local" name="local" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden>Selecione ...</option>
                                                    <option>Na empregadora</option>
                                                    <option>No tomador de serviço (Cliente)</option>
                                                    <option>Em via pública</option>
                                                    <option>Em área rural</option>
                                                </Field>
                                                <ErroField campo="local" />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg={2}>
                                                <label htmlFor="registro_policial" className="form-label">Registro Policial</label>
                                                <Field id="registro_policial" name="registro_policial" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden>Selecione ...</option>
                                                    <option>Sim</option>
                                                    <option>Não</option>
                                                </Field>
                                                <ErroField campo="registro_policial" />
                                            </Col>

                                            <Col lg={10}>
                                                {!edicao &&
                                                    <FormReact.Group controlId="formFileSm" className="mb-2">
                                                        <FormReact.Label>Anexos</FormReact.Label>
                                                        <FormReact.Control type="file" multiple value={formRef?.values?.anexos} onChange={(e) => setFieldValue('anexos', e.target.files)} disabled={edicao} />
                                                        <ErroField campo="anexos" />
                                                    </FormReact.Group>
                                                }

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col>
                                                <InputFieldTextArea disabled={edicao} linhas={6} campo="det_local" tipo="text" texto="Especificações do local (Endereço completo no caso de trajeto ou setor da empresa no caso de acidente dentro da empresa): " />
                                                <ErroField campo="det_local" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <InputFieldPadrao disabled={edicao} campo="nome_medico" tipo="text" texto="Nome do médico" />
                                                <ErroField campo="nome_medico" />
                                            </Col>

                                            <Col lg={3}>
                                                <InputFieldPadrao disabled={edicao} campo="crm_medico" tipo="text" texto="CRM do médico" />
                                                <ErroField campo="crm_medico" />
                                            </Col>

                                            <Col lg={3}>
                                                <InputFieldPadrao disabled={edicao} campo="unidade_atendimento" tipo="text" texto="Unidade de atendimento" />
                                                <ErroField campo="unidade_atendimento" />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col lg={2}>
                                                <label htmlFor="houve_afastamento" className="form-label">Houve Afastamento?</label>
                                                <Field id="houve_afastamento" name="houve_afastamento" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden >Selecione ...</option>
                                                    <option>Sim</option>
                                                    <option>Não</option>
                                                </Field>
                                                <ErroField campo="houve_afastamento" />
                                            </Col>

                                            <Col lg={2}>
                                                <label htmlFor="houve_obito" className="form-label">Houve Óbito?</label>
                                                <Field id="houve_obito" name="houve_obito" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden >Selecione ...</option>
                                                    <option>Sim</option>
                                                    <option>Não</option>
                                                </Field>
                                                <ErroField campo="houve_obito" />
                                            </Col>

                                            <Col sm={12} lg={2}>
                                                <InputFieldPadrao campo="ultimo_dia_trabalhado" tipo="date" texto="Último dia Trabalhado" disabled={edicao} />
                                                <ErroField campo="ultimo_dia_trabalhado" />
                                            </Col>

                                            <Col lg={3}>
                                                <InputFieldPadrao disabled={edicao} campo="data_atestado" tipo="date" texto="Data do atestado" />
                                                <ErroField campo="data_atestado" />
                                            </Col>

                                            <Col lg={3}>
                                                <InputFieldPadrao disabled={edicao} campo="dias_licenca" tipo="text" texto="Dias de licença médica" />
                                                <ErroField campo="dias_licenca" />
                                            </Col>

                                        </Row>

                                        <Row>

                                            <Col>
                                                <InputFieldTextArea disabled={edicao} linhas={3} campo="causa_acidente" tipo="text" texto="O que causou o acidente?" />
                                                <ErroField campo="causa_acidente" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <InputFieldTextArea disabled={edicao} linhas={3} campo="parte_corpo_atingida" tipo="text" texto="Parte do corpo atingida" />
                                                <ErroField campo="parte_corpo_atingida" />
                                            </Col>

                                            <Col lg={2}>
                                                <label htmlFor="lateralidade" className="form-label">Lateralidade</label>
                                                <Field id="lateralidade" name="lateralidade" as="select" className="form-select" defaultValue="DEFAULT" disabled={edicao}>
                                                    <option value="DEFAULT" disabled hidden >Selecione ...</option>
                                                    <option>Não aplicável</option>
                                                    <option>Esquerda</option>
                                                    <option>Direita</option>
                                                    <option>Ambas</option>
                                                </Field>
                                                <ErroField campo="lateralidade" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <InputFieldTextArea disabled={edicao} linhas={6} campo="descricao_acidente" tipo="text" texto="Descrição do acidente" />
                                                <ErroField campo="descricao_acidente" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <InputFieldPadrao disabled={edicao} campo="testemunha_a" tipo="text" texto="Testemunha A" />
                                                <ErroField campo="testemunha_a" />
                                            </Col>

                                            <Col>
                                                <InputFieldPadrao disabled={edicao} campo="testemunha_b" tipo="text" texto="Testemunha B" />
                                                <ErroField campo="testemunha_b" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <InputFieldTextArea disabled={edicao} linhas={6} campo="observacoes" tipo="text" texto="Observação" />
                                                <ErroField campo="observacoes" />
                                            </Col>
                                        </Row>

                                        {edicao &&
                                            <div>
                                                <h6>Anexos</h6>
                                                {listaAnexos.map((item) => {
                                                    return (
                                                        <div>
                                                            <sub>{item?.nome}</sub><br />
                                                            <a style={{ textDecoration: 'none' }} href={item?.url} target="_blank"><sup>{item?.url}</sup></a><br />
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        }


                                    </Stack>

                                </Form>

                            )}
                        >
                        </Formik>
                    </Col>
                </Row >
            </Container >

            <Footer />



            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={'lazy'}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                            {msgLoading}
                        </div>


                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={modalConcluidoAberto} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Concluido
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    O cadastro da ocorrência foi realizado
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        window.location.reload();
                    }}>OK</Button>
                </Modal.Footer>
            </Modal>

            <ModalFuncionarios
                isOpen={modalFuncionarios}
                setIsOpen={setModalFuncionarios}
                rowClick={(row) => {
                    formRef.current.setFieldValue('empregado', row.func_nome);
                    formRef.current.setFieldValue('id_empregado', row.func_id_contrato);
                    // alert(JSON.stringify(row))
                }}
            />

        </>
    )
}