import React, { useState, createContext } from 'react'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import axios from 'axios';

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {


    const [loading, setLoading] = useState(false)

    //Dados Login
    const [usuario, setUsuario] = useState({})
    const [permissao, setPermissao] = useState({})

    const [tipoAcesso, setTipoAcesso] = useState('')


    //Dados Solicitações
    const statusSolicitacoes =
        [
            {
                "STATUS": "TODOS"
            },
            {
                "STATUS": "PENDENTE CLIENTE"
            },
            {
                "STATUS": "PENDENTE OPERADOR"
            },
            {
                "STATUS": "NÃO ATRIBUÍDA"
            },
            {
                "STATUS": "FINALIZADA"
            },
        ]


    //Dados Licenciamento
    const [licenca, setLicenca] = useState({})

    // const [conexao] = useState({
    //     endereco: process.env.REACT_APP_ENDERECO_WS,
    //     autenticacao: localStorage.getItem('Token')
    // })
    const [conexaoQuestor] = useState({
        endereco: process.env.REACT_APP_ENDERECO_WS_QUESTOR,
        autenticacao: 'Basic ' + base64_encode('user:password')
    })

    const [conexao] = useState({
        endereco: process.env.REACT_APP_ENDERECO_WS,
    })

    function SalvarLogin(email, identificacao, nome, tipo) {
        setUsuario({
            ...usuario, email: email, id: identificacao, nome: nome, tipo: tipo
        })
        if (identificacao) {
            localStorage.setItem('id_usuario', identificacao)
            localStorage.setItem('email', email)
            console.log('Login Salvo no ContextApi - ' + email + 'ID: ' + identificacao)
        } else {
            localStorage.removeItem('id_usuario')
        }

    }

    function SalvarPermissoes(dados) {
        setPermissao(dados)
    }

    function DefineTipoAcesso(tipo) {
        if (tipo === 'OPERADOR') {
            setTipoAcesso('OPERADOR')
            localStorage.removeItem('razao')
            localStorage.removeItem('empresa')
            localStorage.removeItem('estabelecimento')
            console.log('Tipo de Acesso Salvo no ContextApi - ' + tipo)
        } else {
            setTipoAcesso('USUARIO')
            console.log('Tipo de Acesso Salvo no ContextApi - ' + tipo)
        }
    }

    function DefineLicenca(dados) {
        setLicenca({
            ...licenca, empresas: dados.empresas
        })
    }

    const [empresas, setEmpresas] = useState([])
    const [msgLoading, setMsgLoading] = useState('')

    async function GetEmpresas() {
        console.log("Entrou no get")

        setMsgLoading('Obtendo Empresas')
        setLoading(true)

        await axios.post(conexao.endereco + '/empresas/listar',
            {}, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setEmpresas(response.data)
                console.log("Response: ", response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }

    return (
        <GlobalContext.Provider value={{
            usuario,
            SalvarLogin,
            DefineTipoAcesso,
            tipoAcesso,
            conexao,
            DefineLicenca,
            licenca,
            SalvarPermissoes,
            permissao,
            statusSolicitacoes,
            loading,
            setLoading,
            conexaoQuestor,
            setEmpresas,
            setMsgLoading,
            GetEmpresas,
            empresas
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;