import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { Card, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import Footer from "../../components/footer"
import Navegacao from "../../components/navbar"
import { GlobalContext } from '../../contexts/global'
import LogoArquivo from '../../images/logo.svg'
import DataTableTickets from "./DataTableTickets"

function HomeFolhaPagamento() {

    const { usuario, tipoAcesso, licenca, conexao, permissao } = useContext(GlobalContext)

    const STATUS =
        [
            { value: 'PENDENTE CLIENTE', label: 'Pendente para Você', color: 'bg-green-500' },
            { value: 'PENDENTE OPERADOR', label: 'Pendente para Analista', color: 'bg-yellow-500' },
            { value: 'FINALIZADA', label: 'Atendimento Finalizado', color: 'bg-blue-500' },
            { value: 'TODOS', label: 'Todos' },
        ]

    const PROCESSOS = [
        {
            label: 'Rescisões',
            singularLabel: 'Rescisão',
            value: 'RESCISAO'
        },
        {
            label: 'Férias',
            singularLabel: 'Férias',
            value: 'FERIAS'
        },
        {
            label: 'Acidentes',
            singularLabel: 'Acidente',
            value: 'ACIDENTE'
        },
        {
            label: 'Atestados',
            singularLabel: 'Atestado',
            value: 'ATESTADO'
        },
        {
            label: 'Admissões',
            singularLabel: 'Admissão',
            value: 'ADMISSAO'
        },
        {
            label: 'Folha Mensal',
            singularLabel: 'Folha Mensal',
            value: 'FOLHA_MENSAL'
        }
    ]


    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const Navigate = useNavigate()

    const [tickets, setTickets] = useState([])
    const [ticketsFiltrados, setTicketsFiltrados] = useState([])

    const [tipoFiltrado, setTipoFiltrado] = useState(null)

    const [statusSel, setStatusSel] = useState({
        value: 'PENDENTE CLIENTE', label: 'Pendente para Você'
    })



    function CardIndicador({ titulo, numero }) {
        return (
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {

                if (tipoFiltrado === titulo) {
                    setTipoFiltrado(null)
                    setTicketsFiltrados(tickets)
                } else {
                    setTipoFiltrado(titulo);
                    const tipoFiltradoTitulo = PROCESSOS.find((item) => {
                        return item.label === titulo;
                    });
                    setTicketsFiltrados(tickets.filter((item) => {
                        return tipoFiltradoTitulo.value === item.soli_tipo;
                    }));
                }
            }
            }>
                <Card
                    className="card-home-dp"
                    style={
                        tipoFiltrado == titulo ? {
                            transform: 'scale(1.15)',
                            backgroundColor: 'gainsboro',
                        } : {
                            transform: 'scale(1)'
                        }
                    }
                >
                    <Card.Body>
                        <Card.Title style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{titulo}</Card.Title>
                        <Card.Header style={{ height: '60px', backgroundColor: 'transparent', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card.Body style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 36,
                            }}>
                                {numero}
                            </Card.Body>
                        </Card.Header>
                    </Card.Body>

                </Card>
            </Col >
        );
    }


    async function HandleGetTickets() {

        if (!usuario.email) {
            return
        }

        setMsgLoading('Obtendo Atendimentos')
        setLoading(true)

        axios.post(conexao.endereco + '/solicitacoes/listar',
            {
                "email": usuario.email,
                status: statusSel.value ? statusSel.value : null,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setTickets(response.data)
                setTicketsFiltrados(response.data)
                setTipoFiltrado(null)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }

    useEffect(() => {
        HandleGetTickets()
    }, [statusSel, usuario.email])



    return (
        <>
            <Navegacao />
            {usuario.email &&
                <>

                    <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80 }}>

                        <Row sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Departamento Pessoal</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        style={{ backgroundColor: '#E30613', border: 0 }}
                                        size='sm'
                                        id="dropdown-basic"
                                    >
                                        {statusSel.label}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {STATUS.map((item) => {
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setStatusSel(item)
                                                    }}>
                                                    {item.label}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        style={{ backgroundColor: '#E30613', border: 0, marginLeft: 10 }}
                                        size='sm'
                                        id="dropdown-basic"
                                    >
                                        Solicitar
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {PROCESSOS.sort((a, b) => a.singularLabel.localeCompare(b.singularLabel)).map((item) => {
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        if (item.value === 'RESCISAO') {
                                                            Navigate('/departamento-pessoal/rescisoes')
                                                        }
                                                        if (item.value === 'ACIDENTE') {
                                                            Navigate('/departamento-pessoal/acidentes')
                                                        }
                                                        if (item.value === 'FERIAS') {
                                                            Navigate('/departamento-pessoal/ferias')
                                                        }
                                                        if (item.value === 'ATESTADO') {
                                                            Navigate('/departamento-pessoal/atestados')
                                                        }
                                                        if (item.value === 'ADMISSAO') {
                                                            Navigate('/departamento-pessoal/admissoes')
                                                        }
                                                        if (item.value === 'FOLHA_MENSAL') {
                                                            Navigate('/departamento-pessoal/folha_mensal')
                                                        }
                                                    }}>
                                                    {item.singularLabel}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size="sm"
                                    onClick={() => {
                                        alert(tipoAcesso)
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col> */}
                        </Row>
                        <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                            <hr />
                        </Row>
                        <div style={{ paddingLeft: 25, paddingRight: 25 }} > {/* DIV DOS CARDS */}
                            <Row style={{ marginBottom: 60 }}>
                                <Col lg={12} sm={12} style={{ padding: 15 }}>
                                    <Card style={{ width: '100%' }}>
                                        <Card.Body>
                                            <Row style={{ padding: 10 }}>
                                                <CardIndicador
                                                    titulo={'Acidentes'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'ACIDENTE';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'ACIDENTE';
                                                    }).length : 0
                                                    }
                                                />
                                                <CardIndicador
                                                    titulo={'Admissões'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'ADMISSAO';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'ADMISSAO';
                                                    }).length : 0
                                                    }
                                                />
                                                <CardIndicador
                                                    titulo={'Atestados'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'ATESTADO';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'ATESTADO';
                                                    }).length : 0
                                                    }
                                                />
                                                <CardIndicador
                                                    titulo={'Férias'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'FERIAS';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'FERIAS';
                                                    }).length : 0
                                                    }
                                                />
                                                <CardIndicador
                                                    titulo={'Rescisões'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'RESCISAO';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'RESCISAO';
                                                    }).length : 0
                                                    }
                                                />
                                                <CardIndicador
                                                    titulo={'Folha Mensal'}
                                                    numero={tickets.some((item) => {
                                                        return item.soli_tipo === 'FOLHA_MENSAL';
                                                    }) ? tickets.filter((item) => {
                                                        return item.soli_tipo === 'FOLHA_MENSAL';
                                                    }).length : 0
                                                    }
                                                />
                                            </Row>
                                            <Row style={{ padding: 10 }}>
                                                {ticketsFiltrados.length > 0 &&
                                                    <DataTableTickets
                                                        dados={ticketsFiltrados}
                                                        limite={4}
                                                        STATUS={STATUS}
                                                    />
                                                }
                                            </Row>


                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        </div>


                    </Container>
                    <Footer />
                </>

            }

            <Modal show={loading} centered >
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default HomeFolhaPagamento