import axios from "axios";
import { formatToCNPJ } from 'brazilian-values';
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/global";
import LogoArquivo from '../images/logo.svg';
import {
    Box
} from "../styles/FooterStyles";

function Footer() {

    const Navigate = useNavigate()

    const { usuario, SalvarLogin, tipoAcesso, DefineLicenca, conexao } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const razao = localStorage.getItem('razao')
    const empresa = localStorage.getItem('empresa')
    const estabelecimento = localStorage.getItem('estabelecimento')

    const [empresasUsuario, setEmpresasUsuario] = useState([])
    const [telaSelecaoEmpresas, setTelaSelecaoEmpresas] = useState(false)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function FormataCNPJ(CNPJ) {
        return formatToCNPJ(CNPJ);
    }

    useEffect(() => {
        if (tipoAcesso === 'USUARIO') {
            if (!razao) {
                ListarEmpresasPorEmail(usuario.email)
            }
        }
    }, [])


    async function ListarEmpresasPorEmail(email) {

        setMsgLoading('Obtendo Empresas')
        setLoading(true)

        axios.post(conexao.endereco + '/empresas/usuario',
            {
                "usuario": email
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                localStorage.setItem('empresas', JSON.stringify(response.data))
                if (!razao) {
                    const empresas = localStorage.getItem('empresas')
                    setEmpresasUsuario(JSON.parse(empresas))
                    setTelaSelecaoEmpresas(true)
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }

    function ExibeEmpresasUsuario() {

        const colunas = [
            {
                name: 'Nome Fantasia',
                selector: row => row.empr_nome,
                sortable: true,
                minWidth: '30%'
            },
            {
                name: 'ID Empresa',
                selector: row => row.empr_id,
                omit: true
            },
            {
                name: 'Razão Social',
                selector: row => row.empr_razao,
                sortable: true,
                minWidth: '40%'
            },
            {
                name: 'CNPJ',
                selector: row => row.empr_cnpj,
                format: row => formatToCNPJ(row.empr_cnpj),
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Empresa',
                selector: row => row.empr_questor_empresa,
                sortable: true,
                wrap: true,
                omit: true
            },
            {
                name: 'Questor Estabelecimento',
                selector: row => row.empr_questor_estabelecimento,
                sortable: true,
                wrap: true,
                omit: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        const tableDataConfig = {
            columns: colunas,
            data: empresasUsuario,
            filterPlaceholder: 'Filtrar',
            exportHeaders: true,
            print: true,
            export: true,
            dropdown: true
        };
        return (
            <DataTableExtensions {...tableDataConfig}>
                <DataTable
                    onRowClicked={(row, index) => {
                        setTelaSelecaoEmpresas(false)
                        localStorage.setItem('id_empresa', row.empr_id)
                        localStorage.setItem('razao', row.empr_razao)
                        localStorage.setItem('empresa', row.empr_questor_empresa)
                        localStorage.setItem('estabelecimento', row.empr_questor_estabelecimento)
                        Navigate('/dashboard')
                    }}
                    // expandOnRowClicked={true}
                    striped={true}
                    dense={empresasUsuario.length > 10}
                    highlightOnHover={true}
                    responsive={true}
                    pointerOnHover={true}
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </DataTableExtensions>
        )
    }


    return (
        <Box>
            {tipoAcesso !== 'OPERADOR' &&
                <Container style={{ paddingLeft: 50 }}>
                    <Row lg={12} sm={12} style={{ display: 'flex', alignItems: 'space-between' }}>
                        <Col lg={6} sm={12}>
                            <strong>Razão Social: &nbsp;</strong>{razao}
                        </Col>
                        <Col lg={2} sm={12}>
                            <strong>Empresa: &nbsp;</strong>{empresa}
                        </Col>
                        <Col lg={2} sm={12}>
                            <strong>Estab: &nbsp;</strong>{estabelecimento}
                        </Col>
                        <Col lg={2} sm={12} style={{ justifyContent: 'flex-end' }}>
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0 }}
                                size='sm'
                                onClick={() => {
                                    const empresas = localStorage.getItem('empresas')
                                    setEmpresasUsuario(JSON.parse(empresas))
                                    setTelaSelecaoEmpresas(true)
                                }}
                            >
                                Trocar Empresa
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
            {/* </Conteudo> */}

            <Modal show={telaSelecaoEmpresas} centered dialogClassName='modal-75'>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Selecione a Empresa
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                            <ExibeEmpresasUsuario />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {razao &&
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            setTelaSelecaoEmpresas(false)
                        }}>Sair</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Logomarca Arquivo Contabilidade'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>

            <Modal show={telaAlerta} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {telaTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {telaMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>

        </Box >

    )
}

export default Footer