import { encode as base64_encode } from 'base-64';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate } from 'react-router-dom';
const dateFormat = 'DD/MM/YYYY';

export default function DataTableTickets({ dados, statusSel, limite, STATUS }) {
  const navigate = useNavigate();

//   const STATUS = [
//     { value: 'PENDENTE OPERADOR', label: 'Pendente para Você', color: 'bg-yellow-500' },
//     { value: 'PENDENTE CLIENTE', label: 'Pendente para o Cliente', color: 'bg-green-500' },
//     { value: 'EM ESPERA', label: 'Em Espera', color: 'bg-orange-500' },
//     { value: 'FINALIZADA', label: 'Atendimento Finalizado', color: 'bg-blue-500' },
//     { value: 'TODOS', label: 'Todos' },
//   ];

  function ProcessaTituloTipoSolicitacao(tipo) {
    const titulos = {
      RESCISAO: 'Rescisão',
      FERIAS: 'Férias',
      ACIDENTE: 'Acidente',
      ATESTADO: 'Atestado',
      ADMISSAO: 'Admissão',
      FOLHA_MENSAL: 'Folha Mensal',
    };

    const soli_tipo = tipo;
    if (titulos.hasOwnProperty(soli_tipo)) {
      return titulos[soli_tipo];
    }
  }

  function ClickRow(row) {
    const idTicket = base64_encode(row.soli_id)
    navigate(`/departamento-pessoal/detalhe/${idTicket}`);
  }

  const colunasTickets = [
    {
      name: 'Empresa',
      selector: (row) =>
        `${row.empr_nome}-${row.soli_id_empresa}:${row.soli_id_estab} - ${row.empr_cnpj}`,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            height: '60px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => ClickRow(row)}
        >
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}>
            <div
              style={{
                gridColumn: 'span 12',
                width: '100%',
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'gray',
              }}
            >
              <span style={{ overflow: 'hidden' }}>{row.empr_nome}</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'Funcionário',
      selector: (row) => row.soli_funcionario,
      cellExport: (row) => row.soli_funcionario,
      format: (row) => (
        <div
          style={{
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onClick={() => ClickRow(row)}
        >
          <div
            style={{
              minHeight: 80,
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'gray',
              textTransform: 'uppercase',
            }}
          >
            <span>{row.soli_funcionario}</span>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Tipo',
      selector: (row) => ProcessaTituloTipoSolicitacao(row.soli_tipo),
      cellExport: (row) => ProcessaTituloTipoSolicitacao(row.soli_tipo),
      format: (row) => (
        <div
          style={{
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onClick={() => ClickRow(row)}
        >
          <div
            style={{
              minHeight: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'gray',
              textTransform: 'uppercase',
            }}
          >
            <span>{ProcessaTituloTipoSolicitacao(row.soli_tipo)}</span>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: '140px',
      maxWidth: '140px',
      center: true,
    },
    {
      name: 'Abertura',
      selector: (row) => row.soli_data_cad,
      format: (row) => moment(new Date(row.soli_data_cad)).format('DD/MM/YYYY'),
      cellExport: (row) => row.soli_data_cad,
      format: (row) => (
        <div
          style={{
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onClick={() => ClickRow(row)}
        >
          <div
            style={{
              minHeight: 80,
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'gray',
              textTransform: 'uppercase',
            }}
          >
            <span>{moment(new Date(row.soli_data_cad)).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: '140px',
      maxWidth: '140px',
      center: true,
    },
    {
      name: 'Prazo',
      selector: (row) => row.prazo,
      format: (row) => moment(new Date(row.prazo)).format('DD/MM/YYYY'),
      cellExport: (row) => row.prazo,
      format: (row) => (
        <div
          style={{
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onClick={() => ClickRow(row)}
        >
          <div
            style={{
              minHeight: 80,
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'gray',
              textTransform: 'uppercase',
            }}
          >
            <span>{moment(new Date(row.prazo)).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: '140px',
      maxWidth: '140px',
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row.soli_status,
      cellExport: (row) => row.soli_status,
      format: (row) => (
        <div
          style={{
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onClick={() => ClickRow(row)}
        >
          <div
            style={{
              minHeight: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'gray',
              textTransform: 'uppercase',
            }}
          >
            <span>{STATUS.find((s) => s.value === row.soli_status)?.label}</span>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
      center: true,
      minWidth: '220px',
      maxWidth: '220px',
    },
    {
      name: 'Espera',
      selector: (row) =>
        row.soli_espera_previsao
          ? moment(new Date(row.soli_espera_previsao)).format('DD/MM/YYYY')
          : '',
      cell: (row) => (
        <div
          style={{
            height: '60px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => ClickRow(row)}
        >
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}>
            <div
              style={{
                gridColumn: 'span 12',
                width: '100%',
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'gray',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span style={{ overflow: 'hidden' }}>
                {row.soli_espera_previsao
                  ? moment(new Date(row.soli_espera_previsao)).format('DD/MM/YYYY')
                  : ''}
              </span>
            </div>
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: '140px',
      maxWidth: '140px',
      center: true,
      omit: statusSel?.value === 'EM ESPERA' ? false : true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const tableDataConfig = {
    columns: colunasTickets,
    data: dados,
    filterPlaceholder: 'Filtrar',
    exportHeaders: true,
    print: false,
    export: false,
    dropdown: true,
  };

  return (
    <DataTableExtensions {...tableDataConfig}>
      <DataTable
        highlightOnHover={true}
        responsive={true}
        pointerOnHover={true}
        noDataComponent={
          <div>
            <p></p>
          </div>
        }
        pagination={true}
        paginationPerPage={limite ? limite : 10}
        onRowClicked={(row) => ClickRow(row)}
        paginationComponentOptions={paginationComponentOptions}
        striped
        customStyles={{
          headCells: {
            style: {
              // fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              backgroundColor: '#F5F5F5',
            },
          },
        }}
      />
    </DataTableExtensions>
  );
}
