import axios from "axios";
import { formatToCPF } from 'brazilian-values';
import ptbr from 'date-fns/locale/pt-BR';
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal } from 'react-bootstrap';
import 'react-data-table-component-extensions/dist/index.css';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../components/footer";
import Header from "../../components/header";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';
import { GoogleDrive } from "./google-drive";

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ArquivosDetalhe from "./detalhe";
registerLocale('pt-BR', ptbr)


function HomeArquivos() {

  const Navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [msgLoading, setMsgLoading] = useState('Aguarde...')
  const [telaAlerta, setTelaAlerta] = useState(false)
  const [telaMsg, setTelaMsg] = useState('')
  const [telaTitulo, setTelaTitulo] = useState('')
  // eslint-disable-next-line
  const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

  const [calOpen, setCalOpen] = useState(false)
  const [mesAtual, setMesAtual] = useState(new Date())

  const [config, setConfig] = useState([])

  const handleChange = (e) => {
    setCalOpen(!calOpen)
    setMesAtual(e)
  }
  const handleClick = (e) => {
    e.preventDefault()
    setCalOpen(!calOpen)
  }

  function ExibirAlerta(titulo, mensagem) {
    setTelaMsg(mensagem)
    setTelaTitulo(titulo)
    setTelaAlerta(true)
  }

  function FormataCPF(CPF) {
    return formatToCPF(CPF);
  }

  function GetConfirmaDescartar(funcionario, id) {
    setTelaMsg('Descartar Admissão do Colaborador ' + funcionario + '?')
    setTelaTitulo('Cancelar Admissão')
    // setIdAdmissao(id)
    setTelaAlerta(true)
  }

  async function GetConfiguracoes() {
    axios.post(conexao.endereco + '/configuracoes/drive/categorias',
      {
        empresa: localStorage.getItem('empresa')

      }, { headers: { token: localStorage.getItem('Token') } })

      .then(function (response) {
        setConfig(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    setMsgLoading('Carregando Arquivos de ' + mesAtual.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' }))
    // setLoading(true)
    // setTimeout(() => {
    //     setLoading(false)
    // }, 500)
  }, [mesAtual])

  const { detalhe } = useParams()

  useEffect(() => {
    GetConfiguracoes()
    if (detalhe === undefined) {
      Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`)
    }
  }, [])

  const tabs = [
    'Aquis. Mercadorias / Fretes',
    'Compr. de Pagamento / Rel. Financ.',
    'Extratos Bancários',
    'Energia / Telecomunicação',
    'Serviços Tomados / Aluguel Pago',
    'Arquivos SPED / XML',
    'Receita de Locação / Receita Incorporadora',
    'Honorários Sucumbênciais',
    'Processo de Importação',
    'Docs Não Contábeis'
  ]

  return (
    <>
      <Navegacao />

      <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
          <Header
            titulo={<h3>Gestão de Arquivos</h3>}
            botaoAjuda={() => {
              alert('Ajuda via Props')
            }}
          />
          {!loading &&
            <Tabs>
              <TabList>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Aquis. Mercadorias / Fretes
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/comprovantes_pagamento/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Compr. de Pagamento / Rel. Financeiro
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/extratos_bancarios/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Extratos Bancários
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/faturas/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Energia / Telecomunicação
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/servicos_tomados/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Serviços Tomados / Aluguel Pago
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/arquivos_sped/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Arquivos SPED / XML
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/livros_fiscais/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Receita de Locação / Receita Incorporadora
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/arquivos_xml/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Honorários Sucumbênciais
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/processo_importacao/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Processo de Importação
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate(`/arquivos/docs_nao_contabeis/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                >
                  Docs Não Contábeis
                </Tab>
                <Tab
                  style={{ color: "#E30613" }}
                  onClick={() => { Navigate('/arquivos/google_drive') }}
                >
                  Google Drive
                </Tab>
              </TabList>

              {tabs.map(() => (
                <TabPanel>
                  <ArquivosDetalhe />
                </TabPanel>
              ))}

              <TabPanel>
                <GoogleDrive />
              </TabPanel>
            </Tabs>
          }
        </Container>
        <Footer />
        <Modal show={loading} centered>
          <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
            <img
              src={LogoArquivo}
              width={'20%'}
              alt={'Imagem de Nuvem'}
              loading={'lazy'}
            ></img>
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
              <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                {msgLoading}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={telaAlerta} centered>
          <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
            {telaTitulo}
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {telaMsg}
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
          </Modal.Footer>
        </Modal>

      </div >
    </>
  )
}

export default HomeArquivos;