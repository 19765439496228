export const ESCOLARIDADE = [
    "Analfabeto",
    "Até o 5º ano do Ensino Fundamental Incompleto",
    "Até o 5º ano do Ensino Fundamental Completo",
    "Do 6º ao 9º ano do Ensino Fundamental Incompleto",
    "Ensino Fundamental Completo",
    "Ensino Médio Incompleto",
    "Ensino Médio Completo",
    "Educação Superior Incompleta",
    "Educação Superior Completa",
    "Especialização",
    "Mestrado Completo",
    "Doutorado Completo",
    "Ph. D."
   ]
