import axios from "axios";
import { decode as base64_decode } from 'base-64';
import parse from 'html-react-parser';
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { MdDelete, MdDownload } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global';
import LogoArquivo from '../../images/logo.svg';

function DetalheSolicitacao() {

    const Navigate = useNavigate()

    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAvaliacao, setTelaAvaliacao] = useState(false)
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaEstalonamento, setTelaEstalonamento] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [notaAvaliacao, setNotaAvaliacao] = useState(3)
    const [textoAvaliacao, setTextoAvaliacao] = useState('')

    const [progress, setProgresso] = useState(0)

    const { usuario, tipoAcesso, conexao } = useContext(GlobalContext)


    const [mensagens, setMensagens] = useState([])
    const [operadores, setOperadores] = useState([])
    const [detalhesSolicitacao, setDetalhesSolicitacao] = useState({})

    const [mensagemAtual, setMensagemAtual] = useState([])

    const [modalAnexos, setModalAnexos] = useState(false)
    const [anexos, setAnexos] = useState([])

    const [modalResposta, setModalResposta] = useState(false)
    const [resposta, setResposta] = useState('')
    const [anexosResposta, setAnexosResposta] = useState([])


    function transformarNomeArquivo(nomeArquivo) {
        return nomeArquivo.replace(/[^\w\s\.]/gi, '').replace(/\s+/g, '_').toLowerCase();
    }

    const handleAnexarArquivos = (event) => {

        const novosAnexos = [];

        for (let i = 0; i < event.target.files.length; i++) {
            novosAnexos.push(event.target.files[i]);
        }

        setAnexosResposta(novosAnexos);
    };

    function FinalizaSolicitacao(id) {
        setTelaTitulo('Pesquisa de Satisfação')
        setTelaMsg('Seu feedback é muito importante. Conte-nos como foi a sua experiência neste atendimento.')
        setTelaAvaliacao(true)
    }
    function ExibeTelaAviso(titulo, msg) {
        setTelaTitulo(titulo)
        setTelaMsg(msg)
        setTelaAlerta(true)
    }

    useEffect(() => {
        ListaSolicitacao(base64_decode(id))
    }, [])


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    async function ListaSolicitacao(IdInformado) {

        setMsgLoading('Listando Mensagens')
        setLoading(true)

        await axios.post(conexao.endereco + '/solicitacoes/detalhe',
            {
                id: IdInformado
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setMensagens(response.data.mensagens.filter(m => !m.mens_tipo))
                setDetalhesSolicitacao(response.data.ticket)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })

    }
    async function handleDownloadLote() {

        await axios.post(conexao.endereco + '/arquivos/lote',
            {
                urls: anexos.map(item => item.arquivo)
            }, {
            headers: { token: localStorage.getItem('Token') },
            responseType: 'blob',
        })

            .then(function (response) {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch(function (error) {
                console.error(error)

            })

    }




    async function AtualizaStatusSolicitacao(status) {

        setMsgLoading('Atualizando Status Solicitação')
        setLoading(true)

        const url = conexao.endereco + "/solicitacoes/atualizar"

        let requisicao = new XMLHttpRequest()

        let body = {
            "status": status,
            "solicitacao": detalhesSolicitacao.soli_id,
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status !== 200) {
                ExibirAlerta('Atenção', 'Falha ao Alterar Status da Solicitação')
                setMensagemAtual({ conteudo: '' })
            }

        }
    }

    async function FinalizarSolicitacao(observacao, nota) {

        setMsgLoading('Finalizando Solicitação')
        setLoading(true)

        axios.post(conexao.endereco + '/solicitacoes/atualizar',
            {
                "status": "FINALIZADA",
                "solicitacao": detalhesSolicitacao.soli_id,
                "avaliacao": Number(nota),
                "comentarios": observacao
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status !== 200) {
                    ExibirAlerta('Atenção', 'Falha ao Alterar Status da Solicitação')
                    setMensagemAtual({ conteudo: '' })
                } else {
                    Navigate('/departamento-pessoal')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }
    async function EnviarResposta() {

        setMsgLoading('Enviando Resposta')
        setLoading(true)

        let anexosEnviados = []

        if (anexosResposta.length > 0) {

            for (let i = 0; i < anexosResposta.length; i++) {

                let objAnexo = await UploadArquivo(anexosResposta[i])
                console.log('Objeto Recebido:  ', objAnexo)
                anexosEnviados.push(objAnexo)

            }

            console.log('Lista de Anexos', anexosEnviados)
        }

        axios.post(conexao.endereco + '/solicitacoes/mensagens/incluir',
            {
                solicitacao: base64_decode(id),
                usuario: localStorage.getItem('id_usuario'),
                operador: 0,
                conteudo: resposta,
                anexo: anexosEnviados ? JSON.stringify(anexosEnviados) : []
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status !== 201) {
                    ExibirAlerta('Atenção', 'Falha ao Enviar Resposta')
                } else {
                    AtualizaStatusSolicitacao('PENDENTE OPERADOR')
                    Navigate('/departamento-pessoal')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    async function UploadArquivo(values) {
        setMsgLoading(`Enviando Arquivo ${values.name}`)
        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values)

        try {
            const response = await axios.post(conexao.endereco + '/arquivos/enviar', formData, { headers: { token: localStorage.getItem('Token') } })

            setLoading(false)

            let obj = {
                nome: transformarNomeArquivo(values.name),
                arquivo: response.data.url
            }

            console.log(obj)
            return obj; // Retorna o objeto aqui
        } catch (error) {
            console.error(error)
            setLoading(false)
            return null; // Retorna null em caso de erro
        }
    }



    async function ListarOperadores() {

        setMsgLoading('Listando Operadores')
        setLoading(true)

        const url = conexao.endereco + '/Operadores/'

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                setOperadores(JSON.parse(this.response))
                setTelaEstalonamento(true)
            }
            if (requisicao.status === 204) {
                setOperadores([])
            }
            if (requisicao.status === 400) {
                ExibirAlerta('Falha ao Listar Operadores', JSON.stringify(this.response))
            }
        }
    }



    return (
        <>
            <Navegacao />

            <div style={{
                display: 'flex',
                backgroundColor: '#f5f5f5',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center', alignItems: 'center'
            }}>
                <Container style={{
                    display: 'flex', backgroundColor: '#f5f5f5',
                    width: '100%',
                    flexDirection: 'column',
                    padding: 0
                }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>{detalhesSolicitacao.soli_assunto}</h3>
                            </Col>
                            <Col sm={12} lg={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginRight: 5 }}
                                    size='sm'
                                    onClick={() => {
                                       Navigate('/departamento-pessoal')
                                    }}
                                >
                                    Voltar
                                </Button>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginRight: 5 }}
                                    size='sm'
                                    onClick={() => {
                                        if (detalhesSolicitacao.soli_tipo == 'FERIAS') {
                                            window.open(window.location.origin + '/departamento-pessoal/ferias?id=' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                        if (detalhesSolicitacao.soli_tipo == 'RESCISAO') {
                                            window.open(window.location.origin + '/departamento-pessoal/rescisoes?id=' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                        if (detalhesSolicitacao.soli_tipo == 'ATESTADO') {
                                            window.open(window.location.origin + '/departamento-pessoal/atestados?id=' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                        if (detalhesSolicitacao.soli_tipo == 'ACIDENTE') {
                                            window.open(window.location.origin + '/departamento-pessoal/acidentes?id=' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                        if (detalhesSolicitacao.soli_tipo == 'ADMISSAO') {
                                            window.open(window.location.origin + '/departamento-pessoal/admissoes/edicao/' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                        if (detalhesSolicitacao.soli_tipo == 'FOLHA_MENSAL') {
                                            window.open(window.location.origin + '/departamento-pessoal/folha_mensal?id=' + detalhesSolicitacao.soli_id, '_blank')
                                        }
                                    }}
                                >
                                    Ver Solicitação
                                </Button>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginRight: 5 }}
                                    size='sm'
                                    onClick={() => {
                                        setResposta('')
                                        setAnexosResposta([])
                                        setModalResposta(true)
                                    }}
                                >
                                    Responder
                                </Button>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        setTelaAvaliacao(true)
                                    }}
                                >
                                    Finalizar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>
                        <hr />
                    </Row>
                    <Row style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem", marginBottom: "5rem" }}>
                        {mensagens.length == 0 &&
                            <div style={{
                                textAlign: 'center',
                                display: 'flex',
                                height: '300px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <span>Nenhuma Mensagem</span>
                            </div>
                        }
                        {mensagens.map((item, index) => {

                            const cardStyle = {
                                padding: '40px',
                                marginTop: '20px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                marginBottom: '4px',
                            };

                            if (item.mens_origem === 'OPERADOR') {
                                // cardStyle.marginLeft = '20px';
                                cardStyle.marginRight = '60px';
                                cardStyle.backgroundColor = '#ffffff';
                            } else {
                                cardStyle.marginLeft = '20px';
                                // cardStyle.marginRight = '20px';
                                cardStyle.backgroundColor = '#e6fed7';
                            }

                            const flexContainer = {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            };

                            const textSpanStyle = {
                                fontSize: '12px',
                            };

                            const contentStyle = {
                                whiteSpace: 'pre-line',
                                wordWrap: 'break-word',
                            };


                            return (

                                <div style={cardStyle}>
                                    <div style={flexContainer}>
                                        <h6 style={{ fontWeight: 'bold' }}>
                                            {item.mens_origem === 'OPERADOR' ? item.oper_nome : item.usua_nome}
                                        </h6>
                                        <span style={textSpanStyle}>
                                            {item.mens_data ? moment(item.mens_data).format('DD/MM/YYYY [às] HH:mm') : ''}
                                        </span>
                                    </div>
                                    <hr style={{ marginTop: '8px' }} />
                                    <p style={contentStyle}>
                                        {parse(item.mens_conteudo)}
                                    </p>
                                    {item.mens_anexo !== '[]' && (
                                        <div style={{
                                            borderStyle: 'dashed',
                                            borderWidth: '1px',
                                            borderColor: 'gray',
                                            padding: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <strong style={{ marginRight: '4px' }}>
                                                Anexo:
                                            </strong>
                                            <span
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setAnexos(JSON.parse(item.mens_anexo));
                                                    setModalAnexos(true);
                                                }}
                                            >
                                                Visualizar / Baixar
                                            </span>

                                        </div>
                                    )}
                                </div>

                            )
                        })}
                    </Row>



                </Container>
                {/* <Footer /> */}
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>

                <Modal show={telaAvaliacao} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                        <Row style={{ marginBottom: 40 }}>
                            <p style={{ textAlign: 'center' }}>{telaMsg}</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form>

                                    <Form.Group className="mb-3" controlId="formCadCNPJ">
                                        <Form.Label><strong>Avalie o Atendimento</strong></Form.Label>

                                        <Form.Range min={1} max={4} value={notaAvaliacao} onChange={(e) => { setNotaAvaliacao(e.target.value) }} />

                                    </Form.Group>


                                </Form>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Col>
                                {notaAvaliacao == 1 &&
                                    <strong style={{ color: 'red' }}>Insatisfeito</strong>
                                }
                                {notaAvaliacao == 2 &&
                                    <strong style={{ color: '#FFC107' }}>Pouco Satisfeito</strong>
                                }
                                {notaAvaliacao == 3 &&
                                    <strong style={{ color: '#007BFE' }}>Satisfeito</strong>
                                }
                                {notaAvaliacao == 4 &&
                                    <strong style={{ color: '#28A745' }}>Muito Satisfeito</strong>
                                }
                            </Col>
                        </Row>
                        <Row lg={12} sm={12} style={{ display: 'flex', flex: 1 }}>
                            <Form>

                                <Form.Group className="mb-3" controlId="formCadCNPJ">
                                    <Form.Label><strong>Observações</strong></Form.Label>

                                    <Form.Control as="textarea" rows={6} placeholder="Apenas os gestores da área tem acesso a essa informação" value={textoAvaliacao} onChange={(e) => { setTextoAvaliacao(e.target.value) }} />

                                </Form.Group>


                            </Form>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaAvaliacao(false) }}>Fechar</Button>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            FinalizarSolicitacao(textoAvaliacao, notaAvaliacao)
                        }}>Confirmar</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={modalAnexos} centered dialogClassName='modal-50'>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        Anexos
                        <Button
                            variant="danger"
                            size="sm"
                            // loading={loadingDownload}
                            onClick={() => {
                                handleDownloadLote()
                            }}
                        >
                            Baixar Todos
                        </Button>
                    </Modal.Header>
                    <Modal.Body >

                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Arquivo</th>
                                    <th style={{ textAlign: 'center' }}>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {anexos.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{item.nome}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <MdDownload
                                                    style={{ cursor: 'pointer' }}
                                                    color="#E30613"
                                                    onClick={() => {
                                                        window.open(item.arquivo, '_blank');
                                                    }}
                                                /></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>


                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            setModalAnexos(false)
                        }}>Sair</Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={modalResposta} centered dialogClassName='modal-50'>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        Resposta
                    </Modal.Header>
                    <Modal.Body >

                        <Form.Group className="mb-3" controlId="solicitacao.resposta">
                            <Form.Control
                                as="textarea"
                                rows={6}
                                placeholder="Descreva o Máximo de Detalhes"
                                value={resposta}
                                onChange={(e) => { setResposta(e.target.value) }}
                            />

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            setModalResposta(false)
                        }}>
                            Sair
                        </Button>
                        <div>
                            <input
                                type="file"
                                multiple
                                style={{ display: 'none' }}
                                id="anexarInput"
                                onChange={handleAnexarArquivos}
                            />
                            <Button
                                style={{ backgroundColor: '#E30613', border: 0 }}
                                onClick={() => {
                                    document.getElementById('anexarInput').click();
                                }}
                            >
                                Anexar Arquivos {anexosResposta.length > 0 && `(${anexosResposta.length})`}
                            </Button>
                            {anexosResposta.length > 0 &&
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginLeft: '4px' }}
                                    onClick={() => {
                                        setAnexosResposta([])
                                    }}
                                >
                                    <MdDelete
                                        color="white"
                                    />
                                </Button>
                            }

                        </div>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            EnviarResposta()
                            setModalResposta(false)
                        }}>
                            Enviar Resposta
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default DetalheSolicitacao;