import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import './App.css';
import GlobalProvider from './contexts/global';

//Página Home, Igual para Cliente e Adm
import Home from './pages/Home';
import TelaLogin from './pages/Login';

//Páginas Acesso Cliente
import NaoEncontrado from './pages/NaoEncontrato';
import PaginaDetalhe from './pages/PaginaDetalhe';
import SemPermissao from './pages/SemPermissao';

//Páginas Acesso Adm

//Páginas Movimento Bancário
import ContasBancarias from './pages/MvtoBancario/ContasBancarias';
import ImportarExtrato from './pages/MvtoBancario/ImportarExtrato';

//Páginas Impostos
import ImpostosGuiasFiscais from './pages/adm/impostos/guias_fiscais';

//Páginas Solicitações
import DetalheSolicitacao from './pages/Solicitacoes/DetalheSolicitacao';

//Páginas Folha de Pagamento
import Acidente from './pages/FolhaPagamento/Acidente';
import Admissao from './pages/FolhaPagamento/Admissao';
import Admissoes from './pages/FolhaPagamento/Admissoes';
import Atestados from './pages/FolhaPagamento/Atestados';
import DetalhamentoAdmissao from './pages/FolhaPagamento/DetalhamentoAdmissao';
import Ferias from './pages/FolhaPagamento/Ferias';
import ListaFuncionarios from './pages/FolhaPagamento/ListaFuncionarios';
import Rescisao from './pages/FolhaPagamento/Rescisao';
import ConsultarFolhaPgto from './pages/FolhaPagamento/consultar_folha';

//Páginas Drive de Arquivos
import HomeArquivos from './pages/Arquivos';

//Auditor Fiscal


import ArquivosEnvioExterno from './pages/Arquivos/externo';
import Folha_mensal from './pages/FolhaPagamento/Folha_Mensal';
import HomeFolhaPagamento from './pages/FolhaPagamento/Home';
import ConsultasUsuario from './pages/Solicitacoes/ConsultasUsuario';
import DetalheConsulta from './pages/Solicitacoes/DetalheConsulta';
import NovaConsulta from './pages/Solicitacoes/NovaConsulta';


function App() {

  return (
    <GlobalProvider>
      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<TelaLogin />} />
          <Route exact path="/dashboard" element={<Home />} />
          <Route exact path="/notas-fiscais" element={<PaginaDetalhe props={{ titulo: 'Notas Fiscais' }} />} />
          <Route exact path="/impostos" element={<PaginaDetalhe props={{ titulo: 'Impostos' }} />} />

          {/* <Route exact path="/folha-pagamento" element={<PaginaDetalhe props={{ titulo: 'Folha de Pagamento' }} />} /> */}
          <Route exact path="/departamento-pessoal" element={<HomeFolhaPagamento />} />
          <Route exact path="/departamento-pessoal/detalhe/:id" element={<DetalheSolicitacao />} />
          <Route exact path="/departamento-pessoal/lista-funcionarios" element={<ListaFuncionarios />} />
          <Route exact path="/departamento-pessoal/rescisoes" element={<Rescisao />} />
          <Route exact path="/departamento-pessoal/acidentes" element={<Acidente />} />
          <Route exact path="/departamento-pessoal/ferias" element={<Ferias />} />
          <Route exact path="/departamento-pessoal/atestados" element={<Atestados />} />
          <Route exact path="/departamento-pessoal/folha_mensal" element={<Folha_mensal />} />

          <Route exact path="/departamento-pessoal/admissoes/edicao/:id" element={<Admissao />} />
          <Route exact path="/departamento-pessoal/admissoes/:id" element={<DetalhamentoAdmissao />} />

          <Route exact path="/departamento-pessoal/admissoes" element={<Admissoes />} />
          <Route exact path="/departamento-pessoal/consultar-folha/:mes/:ano" element={<ConsultarFolhaPgto />} />

          <Route exact path="/mais-opcoes" element={<PaginaDetalhe props={{ titulo: 'Mais Opções' }} />} />
          {/* <Route exact path="/mais-opcoes/solicitacoes" element={<SolicitacoesUsuario />} /> */}
          <Route exact path="/mais-opcoes/consultas" element={<ConsultasUsuario />} />
          {/* <Route exact path="/mais-opcoes/solicitacoes/nova" element={<NovaSolicitacao />} /> */}
          <Route exact path="/mais-opcoes/consultas/nova" element={<NovaConsulta />} />
          {/* <Route exact path="/mais-opcoes/solicitacoes/detalhe/:id" element={<DetalheSolicitacao />} /> */}
          <Route exact path="/mais-opcoes/consultas/detalhe/:id" element={<DetalheConsulta />} />


          <Route exact path="/movimento-bancario" element={<PaginaDetalhe props={{ titulo: 'Movimento Bancário' }} />} />
          <Route exact path="/movimento-bancario/importar-extrato-ofx" element={<ImportarExtrato />} />
          <Route exact path="/movimento-bancario/contas-bancarias" element={<ContasBancarias />} />

          <Route exact path="/impostos/guias-fiscais/:mes/:ano" element={<ImpostosGuiasFiscais />} />

          <Route exact path="/arquivos" element={<HomeArquivos />} />
          <Route exact path="/arquivos/:detalhe/:mes/:ano" element={<HomeArquivos />} />
          <Route exact path="/arquivos/google_drive" element={<HomeArquivos />} />

          <Route exact path="/externo/arquivos" element={<ArquivosEnvioExterno />} />

          {/* <Route exact path="adm/arquivos" element={<DashArquivosADM />} />

          <Route exact path="/mais-opcoes/auditor_fiscal" element={<PaginaDetalhe props={{ titulo: 'Auditor Fiscal' }} />} />

          <Route exact path="/mais-opcoes/auditor_fiscal/entradas_nao_lancadas" element={<AuditorFiscal_Entradas_Nao_Lancadas_Indice />} />
          <Route exact path="/mais-opcoes/auditor_fiscal/entradas_nao_lancadas/:identificacao" element={<AuditorFiscal_Entradas_Nao_Lancadas_Detalhe />} />

          <Route exact path="/mais-opcoes/auditor_fiscal/cfop_produtos/" element={<AuditorFiscal_Dash_CFOP_Produto />} />
          <Route exact path="/mais-opcoes/auditor_fiscal/cfop_produtos/:identificacao" element={<AuditorFiscal_CFOP_Produto />} />

          <Route exact path="/mais-opcoes/auditor_fiscal/diferencial_aliquota/" element={<PaginaDetalhe props={{ titulo: 'Diferencial de Alíquota' }} />} />
          <Route exact path="/mais-opcoes/auditor_fiscal/diferencial_aliquota/:identificacao" element={<AuditorFiscal_Diferencial_Aliquota />} />

          <Route exact path="/adm/solicitacoes" element={<PaginaDetalhe props={{ titulo: 'Gestão de Solicitações' }} />} />
          <Route exact path="/adm/solicitacoes/minhas-solicitacoes" element={<SolicitacoesOperador />} />
          <Route exact path="/adm/solicitacoes/minhas-solicitacoes/nova" element={<NovaSolicitacaoADM />} />

          <Route exact path="/adm/solicitacoes/detalhe/:id" element={<DetalheSolicitacao />} />
          <Route exact path="/adm/solicitacoes/categorias" element={<CategoriasSolicitacoes />} />
          <Route exact path="/adm/solicitacoes/prioridades" element={<PrioridadesSolicitacoes />} />
          <Route exact path="/adm/solicitacoes/regras" element={<RegrasSolicitacoes />} />

          <Route exact path="/adm/cadastros/empresas" element={<CadastroEmpresas />} />
          <Route exact path="/adm/cadastros/usuarios/" element={<CadastroUsuarios />} />
          <Route exact path="/adm/cadastros/operadores/" element={<CadastroOperadores />} /> */}

          <Route exact path="/permissao/:rota" element={<SemPermissao />} />
          <Route path="*" element={<NaoEncontrado />} />
        </Routes>

      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
