import { useEffect, useState } from "react"
import { Modal, Row, Button } from 'react-bootstrap'
import { FiArrowLeft } from "react-icons/fi";

import { api } from "../../services/api";
import LogoArquivo from '../../images/logo.svg';
import CardIcone from "../../components/card";

export function GoogleDrive() {
  const [folders, setFolders] = useState([])
  const [loading, setLoading] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const empresaId = localStorage.getItem('id_empresa');

  useEffect(() => {
    async function loadMainFolder() {
      setLoading(true)

      const response = await api.get(`/google-drive/${empresaId}/main`, {}, { headers: { token: localStorage.getItem('Token') } });

      const folder = [
        {
          ...response.data.files[0],
          vazio: response.data.files.length == 0 ? true : false,
          main: true
        }
      ]

      setFolders(folder)
      setLoading(false)
    }

    loadMainFolder()
  }, [empresaId])

  async function loadFolders(folder, isFolder) {
    setLoading(true)

    if (isFolder) {
      const { data } = await api.get(`/google-drive/${folder.id}`);

      setFolders(data.files)
      setLoading(false)

      setBreadcrumbs([...breadcrumbs, folder])
      return
    }

    const { data } = await api.get(`/google-drive/download/${folder.id}`);
    window.open(data.webViewLink, '_blank')

    return setLoading(false)
  }

  async function goBackFolder() {
    setLoading(true)

    if (breadcrumbs.length === 1 || breadcrumbs.length === 0) {
      const response = await api.get(`/google-drive/${empresaId}/main`);

      const folder = [
        {
          ...response.data.files[0],
          main: true
        }
      ]

      setFolders(folder)
      setLoading(false)

      setBreadcrumbs([])
      return
    }

    const folderId = breadcrumbs[breadcrumbs.length - 2].id

    const { data } = await api.get(`/google-drive/${folderId}`);

    setFolders(data.files)
    setLoading(false)

    const idToRemove = breadcrumbs[breadcrumbs.length - 1].id
    const newBreadcrumb = breadcrumbs.filter((item) => item.id !== idToRemove)
    setBreadcrumbs(newBreadcrumb)
  }

  const isFolder = (file) => file.mimeType === 'application/vnd.google-apps.folder';
  const folderName = (file) => file.main ? file.name.split('-')[1] : file.name;

  return (
    <>
      {breadcrumbs.length > 0 && (
        <Button
          style={{
            backgroundColor: '#E30613',
            border: 0,
            width: 100,
            height: 40,
            margin: '24px 0 0 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4
          }}
          size='sm'
          onClick={goBackFolder}
        >
          <FiArrowLeft color="#fff" size="16" />
          Voltar
        </Button>
      )}

      <Row style={{ marginTop: 10, marginLeft: 80, marginRight: 80 }}>

        {/* {JSON.stringify(folders)} */}

        {folders.map((folder) => (
          folder.vazio ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '24px' }}>
              <p style={{ fontSize: 16 }}>Nenhum arquivo encontrado no Google Drive</p>
            </div>
            : <CardIcone titulo={folderName(folder)} icone={isFolder(folder) ? 'MdOutlineFolder' : 'HiOutlineDocumentText'} click={() => loadFolders(folder, isFolder(folder))} />
        ))}

        {folders.length === 0 && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '24px' }}>
            <p style={{ fontSize: 16 }}>Nenhum arquivo encontrado</p>
          </div>
        )}
      </Row>

      <Modal show={loading} centered>
        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
          <img
            src={LogoArquivo}
            width={'20%'}
            alt={'Imagem de Nuvem'}
            loading={'lazy'}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
              <p>Carregando...</p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}